import { AddRounded } from "@mui/icons-material";
import { Fab, Dialog, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import styles from "./AddFAB.module.css";
import commonStyles from "../../styles.module.css";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import vibrator from "../../utils/vibrator";
import toast from "../../utils/toast";
import axios from "axios";
import logger from "../../utils/logger";

const AddFAB = () => {
	const fabRef = useRef(null);

	const [showDialog, setShowDialog] = useState(false);

	return (
		<>
			<Fab
				translate="no"
				ref={fabRef}
				sx={{
					position: "fixed",
					bottom: "20px",
					right: "20px",
					zIndex: "1000",
					backgroundColor: "#20140A",
					color: "#B29466",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "60px",
					height: "60px",
					"&:hover": {
						backgroundColor: "#20140A",
					},
				}}
				onClick={() => setShowDialog(true)}
			>
				<AddRounded fontSize="large" />
			</Fab>
			<WaitlistDialog isOpen={showDialog} setIsOpen={setShowDialog} />
		</>
	);
};

const WaitlistDialog = ({ isOpen, setIsOpen }) => {
	const [waitlist, setWaitlist] = useState({});

	return (
		<Dialog
			open={isOpen}
			onClose={() => setIsOpen(false)}
			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.dialog_wrapper}
			>
				<div>
					<Typography
						variant="body1"
						component="div"
						color="#9E5E28"
						sx={{
							marginBottom: "12px",
						}}
					>
						Add Waitlist
					</Typography>
					<div className={styles.dialog_input}>
						<Typography variant="body2" component="div" color="#9E5E28">
							Name
						</Typography>
						<GradientBorder
							gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
							width="1px"
							className={styles.input_wrapper}
						>
							<input
								type="text"
								value={waitlist.name}
								onChange={(e) => {
									setWaitlist({ ...waitlist, name: e.target.value });
								}}
							/>
						</GradientBorder>
					</div>
					<div className={styles.dialog_input}>
						<Typography variant="body2" component="div" color="#9E5E28">
							Phone
						</Typography>
						<GradientBorder
							gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
							width="1px"
							className={styles.input_wrapper}
						>
							<input
								type="number"
								maxLength={10}
								value={waitlist.phone}
								onChange={(e) => {
									setWaitlist({ ...waitlist, phone: e.target.value });
								}}
							/>
						</GradientBorder>
					</div>
					<div className={styles.dialog_input}>
						<Typography variant="body2" component="div" color="#9E5E28">
							Party Size
						</Typography>
						<GradientBorder
							gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
							width="1px"
							className={styles.input_wrapper}
						>
							<input
								type="number"
								maxLength={2}
								value={waitlist.personCount}
								onChange={(e) => {
									setWaitlist({
										...waitlist,
										personCount: e.target.value,
									});
								}}
							/>
						</GradientBorder>
					</div>
					<div className={styles.dialog_button_row}>
						<button
							className={commonStyles.secondary_button}
							onClick={() => {
								vibrator.tap();
								setIsOpen(false);
							}}
						>
							Cancel
						</button>
						<button
							className={commonStyles.primary_button}
							onClick={async () => {
								vibrator.tap();
								try {
									if (
										!waitlist.name ||
										!waitlist.phone ||
										!waitlist.personCount
									) {
										toast.error("Please fill all the fields");
										return;
									}

									if (waitlist.phone.length !== 10) {
										toast.error("Please enter a valid phone number");
										return;
									}

									if (!waitlist.name.match(/^[a-zA-Z ]*$/)) {
										toast.error("Please enter a valid name");
										return;
									}

									waitlist.time = new Date(waitlist.time).getTime();
									waitlist.blockTime = new Date(waitlist.blockTime).getTime();

									logger(waitlist);

									const res = await axios.post(
										`restaurants/${localStorage.getItem(
											"restaurant"
										)}/waitlist`,
										waitlist,
										{
											headers: {
												"x-auth-token": localStorage.getItem("token"),
											},
										}
									);

									logger(res.data);
									toast.success("Waitlist Added");
									setIsOpen(false);
								} catch (err) {
									logger(err);
									toast.error(
										err.response?.data?.message ?? "Error Adding Waitlist"
									);
								}
							}}
						>
							Confirm
						</button>
					</div>
				</div>
			</GradientBorder>
		</Dialog>
	);
};

export default AddFAB;
