import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast from "../../utils/toast";
import logger from "../../utils/logger";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
// import IosShare from ""
import {
	Dialog,
	IconButton,
	InputAdornment,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import styles from "./OrderSummary.module.css";
import {
	CancelRounded,
	PrintRounded,
	Search,
	VisibilityRounded,
	IosShare,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import Header from "../../components/Header/Header";
import commonStyles from "../../styles.module.css";
import OrderSummaryDetails from "./OrderSummaryDetails";
import { printer } from "../../providers/PrintProvider";
import { ClipLoader } from "react-spinners";
import { restaurant } from "../../providers/RestaurantProvider";
import EditIcon from "@mui/icons-material/Edit";
import OrderDetailsEdit from "./OrderDetailsEdit/OrderDetailsEdit";
import usePrinter from "../../hooks/usePrinter";

const OrderSummary = () => {
	const [orders, setOrders] = useState([]);
	const navigate = useNavigate();
	const restaurantContext = useContext(restaurant)
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [selectedOrder, setSelectedOrder] = useState({});
	const [orderDetailsDrawerOpen, setOrderDetailsDrawerOpen] = useState(false);
	const { setOrderToPrint, orderToPrint, printers } =
		useContext(printer);
	const [orderID, setOrderID] = useState("");

	let cancelReason = "";
	let password = "";
	let excelData = [];

	let myTotalAmount = 0, totalContainerCharge = 0, totalDeliveryCharge = 0, totalDiscount = 0, totalCustomerPaid = 0, totalTip = 0;
	for (let order of orders ?? []) {
		if (order.status !== "cancelled" && order.status !== "waivedOff") {

			totalContainerCharge += order?.containerCharge ?? 0;
			totalDeliveryCharge += order?.deliveryCharge ?? 0;
			totalDiscount += order?.discount?.amount ?? 0;
			totalCustomerPaid += order?.payment?.customerPaid ?? 0;
			totalTip += order?.payment?.tip ?? 0;
			for (let kot of order?.kots ?? []) {
				myTotalAmount += kot.total ?? 0
			}
		}

	}
	const totalTaxValue = restaurantContext.restaurant?.taxes?.reduce((acc, tax) => acc + tax.value, 0);
	const grandTotal = (((myTotalAmount - totalDiscount) * (1 + totalTaxValue / 100)) + totalContainerCharge + totalDeliveryCharge);
	(orders ?? []).forEach((order) => {
		const subTotal = order.kots.reduce((acc, kot) => {
			return acc + kot.total;
		}, 0);

		let jsonData = {
			order_ID: order._id,
			order_type: order.table?.name ?? "Temporary Table",
			cust_name: order.user?.name,
			cust_phone: order.user?.phone,
			payment: order.payment?.method || "Due",
			amount: subTotal.toFixed(2),
			tax: (subTotal * (totalTaxValue / 100)).toFixed(2),
			discount: order?.discount?.amount ?? 0,
			grand_total: (subTotal * (1 + (totalTaxValue / 100))).toFixed(2),
			created: new Date(order.createdAt).toLocaleString(),
		};
		excelData.push(jsonData);
		logger("jsonData", jsonData);
	})
	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";

	const exportToExcel = async () => {
		const ws = XLSX.utils.json_to_sheet(excelData);
		const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, "Order_Summary" + fileExtension);
	};

	const fetchOrders = async () => {
		const todayTimestamp = new Date().setHours(0, 0, 0, 0);
		logger(todayTimestamp);

		try {
			const res = await axios.get(
				`/restaurants/${localStorage.getItem(
					"restaurant"
				)}/orders/after/${todayTimestamp}`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);


			setOrders(res.data);
			return res;
		} catch (error) {
			logger(error);
			toast.error(error.response?.data?.message ?? "Error fetching orders", {
				autoClose: 2000,
			});
		}
	};

	const handleCancel = async () => {
		try {
			const res = await axios.put(
				`/restaurants/${localStorage.getItem("restaurant")}/orders/${orderID}`,
				{
					status: "cancelled",
					cancelReason,
				},
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			logger(res.data);
			fetchOrders();
			setConfirmDialogOpen(false);
			return res;
		} catch (error) {
			logger(error);
			toast.error(error.response?.data?.message ?? "Error cancelling order", {
				autoClose: 2000,
			});
		}
	};

	useEffect(() => {
		const callFunction = fetchOrders();
		toast.promise(callFunction, {
			pending: "Fetching Orders...",
			error: "Fetching Orders Failed",
			success: "Orders Fetched Successfully"
		});
	}, []);

	const {printBill} = usePrinter()

	const [isDrawerOpen, setIsDrawerOpen] = useState(false)

	const ConfirmDialog = () => {
		return (
			<Dialog
				open={confirmDialogOpen}
				onClose={() => setConfirmDialogOpen(false)}
				sx={{
					"& .MuiDialog-paper": {
						backgroundColor: "transparent",
						boxShadow: "none",
					},

					"& .MuiModal-backdrop": {
						backdropFilter: "blur(8px)",
					},
				}}
			>
				<GradientBorder
					gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
					width="1px"
					className={styles.dialog_wrapper}
				>
					<div>
						<Typography
							variant="body1"
							component="div"
							color="#9E5E28"
							sx={{
								marginBottom: "12px",
							}}
						>
							Confirm Cancellation
						</Typography>
						<div className={styles.dialog_input}>
							<Typography variant="body2" component="div" color="#9E5E28">
								Password
							</Typography>
							<GradientBorder
								gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
								width="1px"
								className={styles.input_wrapper}
							>
								<input
									type="password"
									onChange={(e) => {
										password = e.target.value;
									}}
								/>
							</GradientBorder>
						</div>
						<div className={styles.dialog_input}>
							<Typography variant="body2" component="div" color="#9E5E28">
								Reason
							</Typography>
							<GradientBorder
								gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
								width="1px"
								className={styles.input_wrapper}
							>
								<input
									type="text"
									onChange={(e) => {
										cancelReason = e.target.value;
									}}
								/>
							</GradientBorder>
						</div>
						<div className={styles.dialog_button_row}>
							<GradientBorder
								gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
								width="1px"
								className={styles.cta}
							>
								<button
									onClick={() => {
										setConfirmDialogOpen(false);
									}}
								>
									Cancel
								</button>
							</GradientBorder>
							<GradientBorder
								gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
								width="1px"
								className={styles.cta}
							>
								<button
									onClick={() => {
										if (password === "st13521") {
											if (cancelReason === "") {
												toast.error("Please enter a reason", {
													autoClose: 2000,
												});
												return;
											}
											logger(orderID);
											const callFunction=handleCancel();
											toast.promise(callFunction, {
												pending: "Cancelling...",
												error: "Failed",
												success: "Successfully Cancelled"
											});
										} else {
											toast.error("Incorrect Password", {
												autoClose: 2000,
											});
										}
									}}
								>
									Confirm
								</button>
							</GradientBorder>
						</div>
					</div>
				</GradientBorder>
			</Dialog>
		);
	};

	return (
		<div className={styles.body}>
			<Header title="Order Summary" />
			<div className={styles.top_bar}>
				<TextField
					placeholder="Search"
					className={styles.search}
					sx={{
						"& .MuiOutlinedInput-root": {
							"& fieldset": {
								outline: "none",
								border: "none",
							},
							"&:hover fieldset": {
								outline: "none",
								border: "none",
							},
							"&.Mui-focused fieldset": {
								outline: "none",
								border: "none",
							},
						},
					}}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search />
							</InputAdornment>
						),
					}}
					value={searchQuery}
					onChange={(e) => {
						if (e.target.value === "") {
							setIsSearching(false);
						} else {
							setIsSearching(true);
						}
						setSearchQuery(e.target.value);
					}}
				/>
				<div className={styles.legends}>
					<div className={styles.legend}>
						<span className={styles.settled}></span>
						Settled
					</div>
					<div className={styles.legend}>
						<span className={styles.completed}></span>
						Billed
					</div>
					<div className={styles.legend}>
						<span className={styles.cancelled}></span>
						Cancelled
					</div>
					<div className={styles.legend}>
						<span className={styles.running}></span>
						Running
					</div>
					<div className={styles.legend}>
						<span className={styles.billModified}></span>
						Bill Modified
					</div>
				</div>
				<div></div>
				<button
					className={commonStyles.primary_button}
					onClick={() => {
						navigate("past");
					}}
				>
					Get Past Orders
				</button>
				<IconButton className={styles.share} onClick={(e) => exportToExcel()}>
					<IosShare />
				</IconButton>
			</div>
			<TableContainer>
				<Table stickyHeader>
					<TableHead>
						<TableRow
							sx={{
								backgroundColor: "#FEF2E2",
								boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

								"& .MuiTableCell-root": {
									fontWeight: "bold",
									color: "#9E5E28",
									backgroundColor: "#FEF2E2",
								},
							}}
						>
							<TableCell>Order No</TableCell>
							<TableCell>Date</TableCell>
							<TableCell>Order Type</TableCell>
							<TableCell>Order Status</TableCell>

							<TableCell>Order Settled By</TableCell>
							<TableCell>Order Modified By</TableCell>
							<TableCell>Payment Type</TableCell>
							<TableCell>My Amount</TableCell>
							<TableCell>Discount</TableCell>
							<TableCell>Delivery charge</TableCell>
							<TableCell>Container charge</TableCell>
							<TableCell>Tax</TableCell>
							<TableCell>Grand Total</TableCell>
							<TableCell>Customer Paid</TableCell>
							<TableCell>Tip</TableCell>
							<TableCell>Tip+Total</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
						<TableRow
							sx={{
								backgroundColor: "#FEF2E2",
								boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

								"& .MuiTableCell-root": {
									fontWeight: "bold",
									color: "#9E5E28",
									backgroundColor: "#FEF2E2",
								},
							}}
						>
							<TableCell>--</TableCell>
							<TableCell>{new Date().toLocaleDateString()}</TableCell>
							<TableCell>--</TableCell>
							<TableCell>--</TableCell>
							<TableCell>--</TableCell>
							<TableCell>--</TableCell>
							<TableCell>--</TableCell>
							<TableCell>{
								myTotalAmount.toFixed(2)
							}</TableCell>
							<TableCell>{totalDiscount}</TableCell>
							<TableCell>{totalContainerCharge}</TableCell>
							<TableCell>{totalDeliveryCharge}</TableCell>
							<TableCell>{
								restaurantContext.restaurant.taxes?.map((tax) => {
									return <> <b>
										{tax?.name} : {(((tax?.value ?? 0) / 100) * myTotalAmount).toFixed(2)}
									</b> <br /></>
								})
							}</TableCell>
							<TableCell>{
								(grandTotal ?? 0).toFixed(2)
							}</TableCell>
							<TableCell>{totalCustomerPaid}</TableCell>
							<TableCell>{totalTip}</TableCell>
							<TableCell>{totalCustomerPaid}</TableCell>
							<TableCell>--</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(isSearching
							? orders.filter((order) => {
								return (
									order?.orderNumber?.includes(searchQuery.toLowerCase()) ||
									(order?.user?.name ? order.user?.name : "")
										.toLowerCase()
										.includes(searchQuery.toLowerCase()) ||
									(order?.user?.phone ? order.user?.phone : "")
										.toLowerCase()
										.includes(searchQuery.toLowerCase())
								);
							})
							: orders
						).map((order) => {
							const subTotal = order.kots.reduce((acc, kot) => {
								return acc + kot.total;
							}, 0);

							return (
								<TableRow className={(order?.billModified ? styles["billModified"] : styles[order.status])}>
									<TableCell>{order?.orderNumber} </TableCell>
									<TableCell>
										{new Date(order.createdAt).toLocaleString()}
									</TableCell>
									<TableCell>
										{order.table?.type?.toUpperCase() ?? "DINEIN"} ({order.table?.name ?? "Temporary Table"})
									</TableCell>
								<TableCell>{order.status + (order.status==="waivedOff" ? " ("+order.waivedOffReason+")" : "")}</TableCell>

									<TableCell>{order.billSettledBy?.name}</TableCell>
									<TableCell>{order.billModifiedBy?.name}</TableCell>
									<TableCell>{order.payment?.method  &&(order.payment?.method + (order.payment?.transactionNo ? (" (" + order.payment?.transactionNo + ")") : "") || "Due")}</TableCell>
									<TableCell>{subTotal?.toFixed(2)}</TableCell>
									<TableCell>{(order.discount?.amount ?? 0)?.toFixed(2)}</TableCell>
									<TableCell>{(order.deliveryCharge ?? 0)?.toFixed(2)}</TableCell>
									<TableCell>{(order.containerCharge ?? 0)?.toFixed(2)}</TableCell>
									<TableCell>{((subTotal - (order.discount?.amount ?? 0)) * totalTaxValue / 100)?.toFixed(2)}</TableCell>
									<TableCell>{(((subTotal - (order?.discount?.amount ?? 0)) * (1 + totalTaxValue / 100)) + (order.containerCharge ?? 0) + (order?.deliveryCharge ?? 0))?.toFixed(2)} </TableCell>
									<TableCell>{(order.payment?.customerPaid)?.toFixed(2)}</TableCell>
									<TableCell>{order.payment?.tip}</TableCell>
									<TableCell>{((order.payment?.tip ?? 0) + (subTotal + (order.containerCharge ?? 0) + (order?.deliveryCharge ?? 0) - (order?.discount?.amount ?? 0)) * (1 + totalTaxValue / 100))?.toFixed(2)}</TableCell>
									<TableCell>
										<div className={styles.actions}>
											{
												['settled', 'completed'].includes(order.status) &&
												<EditIcon
													onClick={() => {
														setSelectedOrder({ ...order })
														setIsDrawerOpen(true)
													}}
												></EditIcon>
											}
											<VisibilityRounded
												onClick={() => {
													setSelectedOrder(order);
													setOrderDetailsDrawerOpen(true);
												}}
											/>
											{
												orderToPrint._id === order._id ?
													<ClipLoader
														color={"black"}
														loading={true}
														size={14}
													/> :
													<PrintRounded onClick={() => {
														toast.promise(printBill(order), {
															pending: "Reprinting Order...",
															error:"No printer found",
															success: "Added to print Queue"
														})
													}
													} />
											}
											{order.status !== "cancelled" && (
												<CancelRounded
													onClick={() => {
														setOrderID(order._id);
														setConfirmDialogOpen(true);
													}}
												/>
											)}
										</div>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<ConfirmDialog />
			<OrderSummaryDetails
				open={orderDetailsDrawerOpen}
				setOpen={setOrderDetailsDrawerOpen}
				order={selectedOrder}
			/>
			<OrderDetailsEdit
				refreshOrder={fetchOrders}
				order={selectedOrder}
				isOpen={isDrawerOpen}
				setIsOpen={setIsDrawerOpen}
			/>
		</div>
	);
};

export default OrderSummary;
