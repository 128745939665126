import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import NotFound from "./pages/NotFound/NotFound";
import SplashScreen from "./pages/SplashScreen/SplashScreen";
import axios from "axios";
import Dashboard from "./pages/Dashboard/Dashboard";
import { createTheme, ThemeProvider } from "@mui/material";
import { RestaurantProvider } from "./providers/RestaurantProvider";
import { OrderProvider } from "./providers/OrderProvider";
import OrderBuilding from "./pages/OrderBuilding/OrderBuilding";
import { CartProvider } from "./providers/CartProvider";
import OrderView from "./pages/OrderView/OrderView";
import { SettingsProvider } from "./providers/SettingsProvider";
import OrderDetails from "./pages/OrderDetails/OrderDetails";
import SocketWrapper from "./components/SocketWrapper/SocketWrapper";
import OrderReport from "./pages/OrderReport/OrderReport";
import OrderSummary from "./pages/OrderSummary/OrderSummary";
import OrderSummaryDetails from "./pages/OrderSummary/OrderSummaryDetails";
import PastOrderSummary from "./pages/OrderSummary/PastOrderSummary";
import StaffListing from "./pages/StaffListing/StaffListing";
import TaxListing from "./pages/TaxListing/TaxListing";
import CustomerListing from "./pages/CustomerListing/CustomerListing";
import ItemListing from "./pages/MenuConfiguration/ItemListing/ItemListing";
import { NotificationProvider } from "./providers/NotificationProvider";
import LiveView from "./pages/LiveView/LiveView";
import Waitlist from "./pages/Waitlist/Waitlist";
import { WaitlistProvider } from "./providers/WaitlistProvider";
import { ReservationProvider } from "./providers/ReservationProvider";
import { PrintProvider } from "./providers/PrintProvider";
import PrinterList from "./pages/PrinterConfiguration/PrinterList/PrinterList";
import DepartmentListing from "./pages/DepartmentListing/DepartmentListing";
import { KotProvider } from "./providers/KotProvider";
import ProtectedComponent from "./components/ProtectedComponent/ProtectedComponent";
import "./index.css";


const router = createBrowserRouter([
  {
    path: "/",
    element: <SplashScreen />,
  },
  {
    path: "/auth/login",
    element: <Login />,
  },
  {
    path: "/auth/register",
    element: <Register />,
  },
  {
    path: "/dashboard",
    element: <ProtectedComponent Component={Dashboard} />,
  },
  {
    path: "/order-building",
    element: <ProtectedComponent Component={OrderBuilding} />,
  },
  {
    path: "/order-view",
    element: <ProtectedComponent Component={OrderView} />,
  },
  {
    path: "/order-details/:id",
    element: <ProtectedComponent Component={OrderDetails} />,
  },
  {
    path: "/department",
    element: <ProtectedComponent Component={DepartmentListing} />,
  },
  {
    path: "/order-report",
    element: <ProtectedComponent Component={OrderReport} />,
  },
  {
    path: "/order-summary",
    element: <ProtectedComponent Component={OrderSummary} />,
  },
  {
    path: "/order-summary/:id",
    element: <ProtectedComponent Component={OrderSummaryDetails} />,
  },
  {
    path: "/order-summary/past",
    element: <ProtectedComponent Component={PastOrderSummary} />,
  },
  {
    path: "/staff-listing",
    element: <ProtectedComponent Component={StaffListing} />,
  },
  {
    path: "/tax-listing",
    element: <ProtectedComponent Component={TaxListing} />,
  },
  {
    path: "customer-listing",
    element: <ProtectedComponent Component={CustomerListing} />,
  },
  {
    path: "/waitlist",
    element: <ProtectedComponent Component={Waitlist} />,
  },
  {
    path: "/live-view",
    element: <ProtectedComponent Component={LiveView} />,
  },
  {
    path: "/menu-config/item-listing",
    element: <ProtectedComponent Component={ItemListing} />,
  },
  {
    path: "/printer-config/printer-listing",
    element: <ProtectedComponent Component={PrinterList} />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <NotificationProvider>
      <SettingsProvider>
        <PrintProvider>
          <KotProvider>
            <RestaurantProvider>
              <WaitlistProvider>
                <ReservationProvider>
                  <OrderProvider>
                    <CartProvider>
                      <SocketWrapper>
                        <ThemeProvider
                          theme={createTheme({
                            typography: {
                              fontFamily: "Poppins, sans-serif",
                            },
                          })}
                        >
                          <RouterProvider router={router} />
                        </ThemeProvider>
                      </SocketWrapper>
                    </CartProvider>
                  </OrderProvider>
                </ReservationProvider>
              </WaitlistProvider>
            </RestaurantProvider>
          </KotProvider>
        </PrintProvider>
      </SettingsProvider>
    </NotificationProvider>
    <ToastContainer theme="colored" />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
