import {
	Dialog,
	DialogContent,
	DialogTitle,
	List,
	ListItem,
	ListItemText,
} from "@mui/material";
import React, { useContext } from "react";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import styles from "./UserListDialog.module.css";
import { kotContext } from "../../providers/KotProvider";

const UserListDialog = ({ isOpen, setOpen, order }) => {
	const {kots} = useContext(kotContext)
	const getUniqueUsersList = () => {
		const users = {};
		if (order.user && order.user.name && order.user.phone) {
			let key = order.user.name + "-" + order.user.phone;
			users[key] = order.user;
		}

		order.kots?.forEach((kotId) => {
			const kot = kots[kotId]
			if (kot.userData && kot.userData.name && kot.userData.phone) {
				let key = kot.userData.name + "-" + kot.userData.phone;
				users[key] = kot.userData;
			}
		});

		const userValues = Object.values(users);

		return (
			<List>
				{Array(order.personCount)
					.fill()
					.map((_, index) => {
						return index < userValues.length ? (
							<ListItem key={index}>
								<ListItemText
									primary={
										userValues[index].name + " - " + userValues[index].phone
									}
								/>
							</ListItem>
						) : (
							<ListItem key={index}>
								<ListItemText primary="No Data Found" />
							</ListItem>
						);
					})}
			</List>
		);
	};

	return (
		<Dialog
			open={isOpen}
			onClose={() => setOpen(false)}
			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
					maxWidth: "none",
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
				width="3px"
				className={styles.dialog_wrapper}
			>
				<div>
					<DialogTitle>User List</DialogTitle>
					<DialogContent>{getUniqueUsersList()}</DialogContent>
				</div>
			</GradientBorder>
		</Dialog>
	);
};

export default UserListDialog;
