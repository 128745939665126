import { AddRounded } from "@mui/icons-material";
import { Fab, Dialog, Typography } from "@mui/material";
import React, { useRef, useState, useContext } from "react";
import styles from "./AddFAB.module.css";
import commonStyles from "../../../styles.module.css";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import vibrator from "../../../utils/vibrator";
import toast from "../../../utils/toast";
import axios from "axios";
import logger from "../../../utils/logger";
import { printer as printerCtx } from "../../../providers/PrintProvider";

const AddFAB = () => {
	const fabRef = useRef(null);

	const [showDialog, setShowDialog] = useState(false);

	return (
		<>
			<Fab
				translate="no"
				ref={fabRef}
				sx={{
					position: "fixed",
					bottom: "20px",
					right: "20px",
					zIndex: "1000",
					backgroundColor: "#20140A",
					color: "#B29466",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "60px",
					height: "60px",
					"&:hover": {
						backgroundColor: "#20140A",
					},
				}}
				onClick={() => setShowDialog(true)}
			>
				<AddRounded fontSize="large" />
			</Fab>
			<PrinterDialog isOpen={showDialog} setIsOpen={setShowDialog} />
		</>
	);
};

const PrinterDialog = ({ isOpen, setIsOpen }) => {
	const [printer, setPrinter] = useState({});
	const printerContext = useContext(printerCtx);

	return (
		<Dialog
			open={isOpen}
			onClose={() => setIsOpen(false)}
			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.dialog_wrapper}
			>
				<div>
					<Typography
						variant="body1"
						component="div"
						color="#9E5E28"
						sx={{
							marginBottom: "12px",
						}}
					>
						Add Printer
					</Typography>
					<div className={styles.dialog_input}>
						<Typography variant="body2" component="div" color="#9E5E28">
							Name
						</Typography>
						<GradientBorder
							gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
							width="1px"
							className={styles.input_wrapper}
						>
							<input
								type="text"
								value={printer.name}
								onChange={(e) => {
									setPrinter({ ...printer, name: e.target.value });
								}}
							/>
						</GradientBorder>
					</div>
					<div className={styles.dialog_input}>
						<Typography variant="body2" component="div" color="#9E5E28">
							Type
						</Typography>
						<GradientBorder
							gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
							width="1px"
							className={styles.input_wrapper}
						>
							<select
								value={printer.type ?? ""}
								onChange={(e) => {
									setPrinter({ ...printer, type: e.target.value });
								}}
							>
								<option value="" disabled>
									{" "}
									-- Select --{" "}
								</option>
								<option value="usb">USB</option>
								<option value="network">Network</option>
							</select>
						</GradientBorder>
					</div>
					{printer.type === "usb" && (
						<div className={styles.dialog_input}>
							<Typography variant="body2" component="div" color="#9E5E28">
								Device Name
							</Typography>
							<GradientBorder
								gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
								width="1px"
								className={styles.input_wrapper}
							>
								<input
									type="text"
									value={printer.deviceName}
									onChange={(e) => {
										setPrinter({
											...printer,
											deviceName: e.target.value,
										});
									}}
								/>
							</GradientBorder>
						</div>
					)}
					{printer.type === "network" && (
						<div className={styles.dialog_input}>
							<Typography variant="body2" component="div" color="#9E5E28">
								Printer IP
							</Typography>
							<GradientBorder
								gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
								width="1px"
								className={styles.input_wrapper}
							>
								<input
									type="text"
									maxLength={15}
									value={printer.ip}
									onChange={(e) => {
										setPrinter({
											...printer,
											ip: e.target.value,
										});
									}}
								/>
							</GradientBorder>
						</div>
					)}
					{printer.type === "network" && (
						<div className={styles.dialog_input}>
							<Typography variant="body2" component="div" color="#9E5E28">
								Printer Port
							</Typography>
							<GradientBorder
								gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
								width="1px"
								className={styles.input_wrapper}
							>
								<input
									type="number"
									value={printer.port}
									onChange={(e) => {
										setPrinter({
											...printer,
											port: e.target.value,
										});
									}}
								/>
							</GradientBorder>
						</div>
					)}
					<div className={styles.dialog_button_row}>
						<button
							className={commonStyles.secondary_button}
							onClick={() => {
								vibrator.tap();
								setIsOpen(false);
							}}
						>
							Cancel
						</button>
						<button
							className={commonStyles.primary_button}
							onClick={async () => {
								vibrator.tap();
								try {
									if (!printer.name || !printer.type) {
										toast.error("Please fill all the fields");
										return;
									}

									if (printer.type === "network") {
										if (!printer.ip || !printer.port) {
											toast.error("Please fill all the fields");
											return;
										}
									} else {
										if (!printer.deviceName) {
											toast.error("Please fill all the fields");
											return;
										}
									}
									logger(printer);

									const res = await axios.post(
										`restaurants/${localStorage.getItem(
											"restaurant"
										)}/printers`,
										printer,
										{
											headers: {
												"x-auth-token": localStorage.getItem("token"),
											},
										}
									);

									logger(res.data);
									printerContext.addPrinter({ ...res.data, inUse: false });

									toast.success("printer Added");
									setIsOpen(false);
								} catch (err) {
									logger(err);
									toast.error(
										err.response?.data?.message ?? "Error Adding printer"
									);
								}
							}}
						>
							Confirm
						</button>
					</div>
				</div>
			</GradientBorder>
		</Dialog>
	);
};

export default AddFAB;
