import React, { useEffect, useState } from 'react'
import styles from './OrderDetails.module.css'
import inputStyles from "./KOTitemCard.module.css";
import commonStyles from '../../../styles.module.css'
import GradientBorder from '../../../components/GradientBorder/GradientBorder';
import { Dialog, Typography } from '@mui/material';
import KOTitemCardEdit from './KOTitemCardEdit';
import axios from 'axios';
import isObjectsEqual from '../../../utils/isObjectEquals';
import logger from '../../../utils/logger';
import toast from '../../../utils/toast'
import ConfirmDialog from './ConfirmDialog';

export default function KOTCardEdit({ order, kot, index, setIsOpen, refreshOrder }) {
    const [items, setItems] = useState([])
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    useEffect(() => {
        const kotItems= JSON.stringify([...(kot?.items ?? [])])
        setItems([...(JSON.parse(kotItems))])
    }, [kot]);

    const handleUpdate = async (modificationReason) => {
        try {
            const newItems = items.map((i) => {
                return {
                    ...i,
                    item: i.item._id,
                };
            });
            const res = await axios.put(
                `/restaurants/${localStorage.getItem('restaurant')}/kots/${kot._id}/bill`,
                {
                    items: newItems,
                    billModificationReason: modificationReason
                },
                {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                    },
                }
            );
            console.log(res.data)
            refreshOrder();
            // setIsOpen(false)
            return res;
        } catch (err) {
            logger(err)
            toast.error(err?.response?.data?.message ?? "Bill Updation Failed")
        }
    }

    const handleDelete = async (index) => {
        const newItems = items.filter((i, ind) => ind != index)
        setItems([...newItems])
    };
    const handleInc = async (index) => {
        const newItems = [...items]
        newItems[index].quantity++;
        setItems([...newItems])
    };
    const handleDec = async (index) => {
        const newItems = [...items]
        newItems[index].quantity = newItems[index].quantity<=1 ? 1 : (newItems[index].quantity-1);
        setItems([...newItems])
    };
    console.log(items)

    return (<>
        <GradientBorder
            className={styles.kot_wrapper}
            width="2px"
        >
            <div>
                <div className={styles.kot_heading}>
                    <div>
                        <Typography
                            variant="h5"
                            component="span"
                            color="#824500"
                            fontWeight={400}
                            sx={{
                                marginLeft: "8px",
                            }}
                        >
                            KOT #{index + 1}
                        </Typography>
                        {/* <button className={commonStyles.primary_button}>
                            Add Item
                        </button> */}
                    </div>
                    <div>{
                        !isObjectsEqual(kot.items, items) ?
                            <button className={commonStyles.primary_button} onClick={() => setConfirmDialogOpen(true)}>Update KOT</button> : null
                    }
                    </div>
                </div>
                <div className={styles.kot_item_list}>
                    {items.map((item, index) => {
                        return <KOTitemCardEdit item={item} handleDec={()=>handleDec(index)} handleInc={()=>handleInc(index)}  handleDelete={() => handleDelete(index)} />;
                    })}
                </div>
            </div>
        </GradientBorder>
        <ConfirmDialog
            open={confirmDialogOpen}
            handleSave={handleUpdate}
            setOpen={setConfirmDialogOpen}
        />
    </>
    )
}