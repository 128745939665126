import { Backdrop, CircularProgress, Dialog, DialogTitle, Tab, Tabs, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Dashboard from "../../pages/Dashboard/Dashboard";
import styles from "./MoveTableDialog.module.css";
import GradientBorder from "../GradientBorder/GradientBorder";
import toast from "../../utils/toast";
import axios from "axios";
import logger from "../../utils/logger";
import { kotContext } from "../../providers/KotProvider";
import isObjectsEqual from "../../utils/isObjectEquals";

const MoveTableDialog = ({ order, open, setOpen, setMoveTableSelected }) => {
	const { kots } = useContext(kotContext)
	const [selectedTab, setSelectedTab] = useState(0);
	const [loading, setLoading] = useState(false)
	const [selectedKOTs, setSelectedKOTs] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
	useEffect(()=>{
		setSelectedItems(
			Object.fromEntries(
				(order.kots || []).map((kotId) => {
					const kot = kots[kotId]
					return [
						kot?._id,
						kot?.items.map((i) => ({ ...i, max: i.quantity, quantity: 0 })),
					]
				})
			))
	},[kots])

	const KOTItemCard = ({ item, kot }) => {
		return (
			<>
				<GradientBorder
					gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
					width="1px"
					className={styles.item_card_wrapper}
				>
					<div>
						<div className={styles.item}>
							<div className={styles.item_details}>
								<Typography
									variant="body2"
									component="div"
									color="#824500"
									fontWeight={600}
								>
									{item.item.name}
								</Typography>
								<ul className={styles.property_list}>
									{(item.properties || []).map((prop) => {
										return (
											<Typography
												variant="caption"
												component="div"
												color="#824500"
												fontWeight={400}
											>
												<li key={prop.name}>
													{prop.name} : {prop.option ? prop.option : prop.options?.join(", ") ?? ""}
												</li>
											</Typography>
										);
									})}
								</ul>
							</div>
							<Typography
								variant="body2"
								component="div"
								color="#824500"
								fontWeight={600}
							>
								&times; {item.quantity}
							</Typography>
						</div>
						{item.note && (
							<Typography
								variant="body2"
								component="div"
								color="#824500"
								fontWeight={400}
								textAlign="left"
							>
								<strong>Note: </strong> {item.note}
							</Typography>
						)}
					</div>
				</GradientBorder>
			</>

		);
	};

	const OrderItemCard = ({ item, kot }) => {
		const itemSelected = item.quantity > 0
		const handleItemSelected = (e) => {
			if (e.target.checked) {
				setSelectedItems({
					...selectedItems,
					[kot]: selectedItems[kot].map((i) => {
						if (isObjectsEqual(
							{ _id: i.item._id, properties: i.properties }, 
							{ _id: item.item._id, properties: item.properties })){
							return {
								...i,
								quantity: i.max,
							};
						}
						return i;
					}),
				});
			} else {
				setSelectedItems({
					...selectedItems,
					[kot]: selectedItems[kot].map((i) => {
						if (isObjectsEqual(
							{ _id: i.item._id, properties: i.properties }, 
							{ _id: item.item._id, properties: item.properties })) {
							return {
								...i,
								quantity: 0
							};
						}
						return i;
					}),
				});
			}
		}
		return (
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.item_card_wrapper}
			>
				<div>
					<div className={styles.item}>
						<div className={styles.item_details}>
							<Typography
								variant="body2"
								component="div"
								color="#824500"
								fontWeight={600}
							>
								<input type="checkbox" checked={itemSelected} onChange={handleItemSelected} name="" id="" />
								{item.item.name}
							</Typography>
							<ul className={styles.property_list}>
								{(item.properties || []).map((prop) => {
									return (
										<Typography
											variant="caption"
											component="div"
											color="#824500"
											fontWeight={400} >
											<li key={prop.name}>
												{prop.name} : {prop.option ? prop.option : prop.options?.join(", ") ?? ""}
											</li>
										</Typography>
									);
								})}
							</ul>
						</div>
						<div className={styles.counter}>
							<div
								className={styles.counter_button}
								onClick={
									item.quantity === 0
										? undefined
										: () => {
											setSelectedItems({
												...selectedItems,
												[kot]: selectedItems[kot].map((i) => {
													if (isObjectsEqual(
														{ _id: i.item._id, properties: i.properties }, 
														{ _id: item.item._id, properties: item.properties })){
														return {
															...i,
															quantity: Math.max(0, i.quantity - 1),
														};
													}
													return i;
												}),
											});
										}
								}
							>
								-
							</div>
							<div className={styles.counter_value}>{item.quantity}</div>
							<div
								className={styles.counter_button}
								onClick={() => {
									setSelectedItems({
										...selectedItems,
										[kot]: selectedItems[kot].map((i) => {
											if (isObjectsEqual(
												{ _id: i.item._id, properties: i.properties }, 
												{ _id: item.item._id, properties: item.properties }))  {
												return {
													...i,
													quantity: Math.min(i.quantity + 1, i.max),
												};
											}
											return i;
										}),
									});
								}}
							>
								+
							</div>
						</div>
					</div>
					{item.note && (
						<Typography
							variant="body2"
							component="div"
							color="#824500"
							fontWeight={400}
							textAlign="left"
						>
							<strong>Note: </strong> {item.note}
						</Typography>
					)}
				</div>
			</GradientBorder>
		);
	};

	const KOTView = () => {
		return (
			<div className={styles.side_view}>
				{(order.kots || []).map((kotId, index) => {
					const kot = kots[kotId]
					return <div>
						<div className={styles.kot_header}>
							<input
								type="checkbox"
								onChange={(e) => {
									if (e.target.checked) {
										setSelectedKOTs([...selectedKOTs, kot._id]);
									} else {
										setSelectedKOTs(selectedKOTs.filter((k) => k !== kot._id));
									}
								}}
								checked={selectedKOTs.includes(kot._id)}
							/>
							<Typography
								variant="h5"
								component="div"
								color="#824500"
								fontWeight={400}
								sx={{
									paddingTop: "12px",
									marginBottom: "10px",
									marginLeft: "8px",
								}}
							>
								KOT #{index + 1}
							</Typography>
						</div>
						<div className={styles.kot_item_list}>
							{kot.items.map((item) => {
								return <KOTItemCard item={item} kot={kot} />;
							})}
						</div>
					</div>
				})}
			</div>
		);
	};

	const ItemView = () => {
		return (
			<div className={styles.side_view}>
				{Object.entries(selectedItems ?? {}).map(([kot, items]) => {
					return items.map((item) => {
						return <OrderItemCard item={item} kot={kot} />;
					});
				})}
			</div>
		);
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
				sx={{
					"& .MuiDialog-paper": {
						width: "80%",
						maxWidth: "80%",
						height: "80%",
						maxHeight: "80%",
						margin: 0,
						borderRadius: 0,
						padding: 0,
					},

					"& .MuiModal-backdrop": {
						backdropFilter: "blur(8px)",
					},
				}}
			>
				<DialogTitle>Move Table - {order.table?.name || 1}</DialogTitle>
				<Tabs
					value={selectedTab}
					onChange={(e, newValue) => setSelectedTab(newValue)}
				>
					<Tab label="By Table" onClick={() => setSelectedTab(0)} />
					<Tab label="By KOT" />
					<Tab label="By Item" />
				</Tabs>
				<div className={styles.content}>
					{selectedTab === 1 ? (
						<KOTView />
					) : selectedTab === 2 ? (
						<ItemView />
					) : null}
					<div>
						<Dashboard
							justForDisplay={true}
							callback={async (otherOrder, table) => {
								setLoading(true)
								if (selectedTab === 0) {
									if (otherOrder._id) {
										toast.info(`Table is already running!`);
										setOpen(false);
										setLoading(false)
										return;
									}
									try {
										const res = await axios.put(
											`/restaurants/${order.restaurant}/orders/${order._id}`,
											{
												table: table._id,
											},
											{
												headers: {
													"x-auth-token": localStorage.getItem("token"),
												},
											}
										);
									} catch (error) {
										logger(error);
										toast.error(
											error.response?.data?.message ?? "Error moving table"
										);
									} finally {
										setOpen(false);
										setLoading(false)
										setMoveTableSelected(false);
									}
								} else if (selectedTab === 1) {
									try {
										const res = await axios.post(
											`/restaurants/${order.restaurant}/orders/${order._id}/move/KOT`,
											{
												kots: selectedKOTs,
												table: table._id,
											},
											{
												headers: {
													"x-auth-token": localStorage.getItem("token"),
												},
											}
										);

										logger(res.data);
									} catch (error) {
										logger(error);
										toast.error(
											error.response?.data?.message ??
											`KOT Moving failed`
										);
									} finally {
										setOpen(false);
										setLoading(false);
										setMoveTableSelected(false);
									}
								} else if (selectedTab === 2) {

									try {
										const res = await axios.post(
											`/restaurants/${order.restaurant}/orders/${order._id}/move/items`,
											{
												items: Object.fromEntries(
													Object.entries(selectedItems).map(([kot, items]) => [
														kot,
														items.map((item) => ({
															...item,
															item: item.item._id,
														})),
													])
												),
												table: table._id,
											},
											{
												headers: {
													"x-auth-token": localStorage.getItem("token"),
												},
											}
										);

										logger(res.data);
									} catch (error) {
										logger(error);
										toast.error(
											error.response?.data?.message ??
											`Error while moving items!`
										);
									} finally {
										setOpen(false);
										setLoading(false);
										setMoveTableSelected(false);
									}
								}
							}}
						/>
					</div>
				</div>
			</Dialog>
			<Backdrop
				open={loading}
				sx={{ zIndex: 99999, color: "white" }}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
};

export default MoveTableDialog;
