import React from "react";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import { Typography } from "@mui/material";
import styles from "./KOTItemCard.module.css";
import logger from "../../../utils/logger";

const KOTItemViewCard = ({ item }) => {

	let itemTotal = item.item.price;
	for (let property of item.properties || []) {
		const prop = item.item.properties.find((prop) => {
			return prop.name === property.name;
		});
		let optPrice = 0;
		if (property?.options?.[0]) {
			prop?.options?.forEach((option) => {
				if (property.options.includes(option.name)) {
					optPrice += option?.price ?? 0
				}
			})
		} else {
			const opt = prop?.options.find((opt) => opt.name === property.option);
			optPrice = opt?.price ?? 0
		}

		itemTotal += optPrice;
	}

		return (
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.item_card_wrapper}
			>
				<div>
					<div className={styles.item}>
						<div className={styles.item_details}>
							<Typography
								variant="body2"
								component="div"
								color="#824500"
								fontWeight={600}
							>
								{item.item.name}
							</Typography>
							<ul className={styles.property_list}>
								{(item.properties || []).map((prop) => {
									return (
										<Typography
											variant="caption"
											component="div"
											color="#824500"
											fontWeight={400}
										>
											<li key={prop.name}>
												{prop.name} : {prop.option ? prop.option : prop.options?.join(", ") ?? ""}
											</li>
										</Typography>
									);
								})}
							</ul>
						</div>
						<div className={styles.price}>
							<Typography
								variant="body2"
								component="div"
								color="#824500"
								fontWeight={600}
								align="center"
							>
								x {item.quantity}
							</Typography>
						</div>
						<div className={styles.price}>
							<Typography
								variant="body2"
								component="div"
								color="#824500"
								fontWeight={600}
								align="center"
							>
								{item.quantity * itemTotal}
							</Typography>
						</div>
					</div>
					{item.note && (
						<Typography
							variant="body2"
							component="div"
							color="#824500"
							fontWeight={400}
							textAlign="left"
						>
							<strong>Note: </strong> {item.note}
						</Typography>
					)}
				</div>
			</GradientBorder>
		);
	};

	export default KOTItemViewCard;
