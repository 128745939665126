import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast from "../../utils/toast";
import logger from "../../utils/logger";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	InputAdornment,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from "@mui/material";
import styles from "./OrderReport.module.css";
import Header from "../../components/Header/Header";
import { kotContext } from "../../providers/KotProvider";
import { restaurant } from "../../providers/RestaurantProvider";
import { printer } from "../../providers/PrintProvider";
import { DatePicker } from "antd";
import { Search } from "@mui/icons-material";
import regexSearch from "../../utils/regexSearch";

const OrderReport = () => {
	const restaurantContext = useContext(restaurant)
	const [todayOrders, setTodayOrders] = useState([])
	const [categorySearch, setCategorySearch] = useState("")
	const [itemSearch, setItemSearch] = useState("")
	const { departments, refreshDepartments } = useContext(printer)
	const [startDate, setStartDate] = useState(
		new Date(new Date().setHours(0, 0, 0, 0))
	);
	const [endDate, setEndDate] = useState(
		new Date(new Date().setHours(23, 59, 59, 999))
	);
	const categoryMapItem = {}
	const departmentMapItem = {}
	const itemWiseReport = {}
	const checkMenuRecursively = (menuItem, menuName) => {
		(menuItem.items ?? []).forEach((item) => {
			if(menuName==menuItem.name){
				categoryMapItem[item] = menuName
			}else{
				categoryMapItem[item] = menuName + " > "+menuItem.name
			}
		})
		if (menuItem.subCategories) {
			(menuItem.subCategories ?? []).forEach((subC) => {
				console.log(menuItem.name)
				checkMenuRecursively(subC, menuName)
			})
		}
	}
	const totalTaxValue = restaurantContext.restaurant.taxes.reduce(
		(acc, item) => {
			return acc + item.value;
		},
		0
	);
	let myTotalAmount = 0, totalOrders = 0, totalContainerCharge = 0, totalDeliveryCharge = 0, totalDiscount = 0, totalCustomerPaid = 0, totalTip = 0;
	let categoryWiseReport = {}, departmentWiseReport = {}, paymentWiseReport = {}, orderTotalAmount = 0, groupWiseReport = {
		Food: 0,
		Drink: 0
	};

	restaurantContext.restaurant.menu.forEach(menuItem => {
		console.log(menuItem, "menuItem")
		checkMenuRecursively(menuItem, menuItem.name)
	})

	Object.values(departments ?? {}).forEach((department) => {
		department.items.forEach(item => {
			if (!departmentMapItem[item]) {
				departmentMapItem[item] = new Set([department.name])
			}
			departmentMapItem[item].add(department.name)
		})
	})
	let paymentTotal = 0;

	for (let order of todayOrders ?? []) {
		if (order.status === "settled") {
			totalContainerCharge += order?.containerCharge ?? 0;
			totalOrders++;
			totalDeliveryCharge += order?.deliveryCharge ?? 0;
			totalDiscount += order?.discount?.amount ?? 0;
			totalCustomerPaid += order?.payment?.customerPaid ?? 0;
			paymentTotal += order?.payment?.customerPaid ?? 0;
			totalTip += order?.payment?.tip ?? 0;

			if (order.payment?.method === "Cash") {
				paymentWiseReport['Cash'] = (paymentWiseReport['Cash'] ?? 0) + order.payment.customerPaid;
			} else if (order.payment?.method === "Card") {
				paymentWiseReport['Card'] = (paymentWiseReport['Card'] ?? 0) + order.payment.customerPaid;
			} else if (order.payment?.method === "Part") {
				order.payment.parts.forEach((payment) => {
					if (payment?.method === "Cash") {
						paymentWiseReport['Cash'] = (paymentWiseReport['Cash'] ?? 0) + payment.amount ?? 0;
					} else if (payment?.method === "Card") {
						paymentWiseReport['Card'] = (paymentWiseReport['Card'] ?? 0) + payment.amount ?? 0;
					} else if (payment?.method === "Other") {
						const keyName = 'Other (' + (payment.otherMethod ?? "UPI") + ")"
						paymentWiseReport[keyName] = (paymentWiseReport[keyName] ?? 0) + payment.amount ?? 0;
					}
				})
			} else if (order.payment?.method === "Other") {
				const keyName = 'Other (' + (order.payment.otherMethod ?? "UPI") + ")"
				paymentWiseReport[keyName] = (paymentWiseReport[keyName] ?? 0) + order.payment.customerPaid;
			}
			// }
			// if (order.status !== "cancelled") {
			for (let kot of order?.kots ?? []) {
				myTotalAmount += kot.total ?? 0
				orderTotalAmount += (kot.total ?? 0) * (1 + totalTaxValue / 100)
				kot?.items?.forEach((item) => {
					let additionalPrice = 0;
					if (item.properties) {
						item.properties.forEach((property) => {
							let selectedProperty = item.item.properties.find(
								(p) => p.name === property.name
							);
							let optPrice = 0;
							if (selectedProperty?.hasMultipleSelection) {
								selectedProperty.options.forEach((option) => {
									if (option.name === property.option) {
										optPrice += option.price ?? 0
									}
								})
							} else {
								const opt = selectedProperty?.options.find((opt) => opt.name === property.option);
								optPrice = opt?.price ?? 0
							}
							additionalPrice += optPrice ?? 0;
						});
					}
					let itemTotalPrice = item.item.price + additionalPrice;
					itemTotalPrice = (itemTotalPrice * item.quantity) * (1 + totalTaxValue / 100);
					console.log(categoryMapItem[item.item._id], item.item)
					if (item?.item?.isBar) {
						groupWiseReport.Drink += itemTotalPrice;
					} else {
						groupWiseReport.Food += itemTotalPrice;
					}
					if (categoryMapItem[item.item._id]) {
						categoryWiseReport[categoryMapItem[item.item._id]] = {
							total: (categoryWiseReport[categoryMapItem[item.item._id]]?.total ?? 0) + itemTotalPrice,
							quantity: (categoryWiseReport[categoryMapItem[item.item._id]]?.quantity ?? 0) + item.quantity
						};
					} else {
						categoryWiseReport["Others"] = {
							total: (categoryWiseReport["Others"]?.total ?? 0) + itemTotalPrice,
							quantity: (categoryWiseReport["Others"]?.quantity ?? 0) + item.quantity
						};

					}
					if (departmentMapItem?.[item?.item?._id]) {
						(departmentMapItem?.[item?.item?._id] ?? []).forEach(departmentName => {
							departmentWiseReport[departmentName] = {
								total: (departmentWiseReport[departmentName]?.total ?? 0) + itemTotalPrice,
								quantity: (departmentWiseReport[departmentName]?.quantity ?? 0) + item.quantity
							}
						})
					} else {
						departmentWiseReport["Others"] = {
							total: (departmentWiseReport["Others"]?.total ?? 0) + itemTotalPrice,
							quantity: (departmentWiseReport["Others"]?.quantity ?? 0) + item.quantity
						}
					}
					itemWiseReport[item?.item?._id] = {
						total: (itemWiseReport[item?.item?._id]?.total ?? 0) + itemTotalPrice,
						quantity: (itemWiseReport[item?.item?._id]?.quantity ?? 0) + item.quantity,
						name: item?.item?.name ?? "--"
					}
				});
			}
		}
	}
	const grandTotal = (((myTotalAmount - totalDiscount) * (1 + totalTaxValue / 100)) + totalContainerCharge + totalDeliveryCharge)



	const fetchOrders = async () => {
		const todayTimestamp = new Date().setHours(0, 0, 0, 0);
		logger(todayTimestamp);

		try {
			await refreshDepartments(false)
			const res = await axios.get(
				`/restaurants/${localStorage.getItem(
					"restaurant"
				)}/orders/timeRange?from=${startDate.getTime()}&to=${endDate.getTime()}`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			logger("resData", res.data);

			const orders = res.data;
			setTodayOrders([...orders ?? []])

			return res
		} catch (error) {
			console.log(error);
			toast.error(error.response?.data?.message ?? "Error fetching orders", {
				autoClose: 2000,
			});
		}
	};
	const { RangePicker } = DatePicker;

	useEffect(() => {
		
		const callFunction = fetchOrders();
		toast.promise(callFunction, {
			pending: "Fetching Order Details",
			error: "Order Detailes Fetching Failed",
			success: "Order Detailes Fetched Successfully"
		});
	}, [startDate, endDate]);

	const handleRangeChange = (dateArr) => {
		const start = new Date(new Date(dateArr[0]))
		const end = new Date(new Date(dateArr[1]))
		setStartDate(start);
		setEndDate(end);
	};
	
	
	return (
		<div className={styles.body}>
			<Header title="Order Report" />
			<div className={styles.mainReport}>

				<div className={styles.inputHead}>
					<div className={styles.inputItem}>
						<b>Date</b>
						<RangePicker
							className={styles.myRangePicker}
							onChange={handleRangeChange}
							style={{ border: "none", outline: "none" }}
							size="large"
							showTime
							/>
					</div>
				</div>
				<Accordion>
					<AccordionSummary>Sales Wise Report</AccordionSummary>
					<AccordionDetails>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow
										sx={{
											backgroundColor: "#FEF2E2",
											boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
											
											"& .MuiTableCell-root": {
												fontWeight: "bold",
												color: "#9E5E28",
												backgroundColor: "#FEF2E2",
											},
										}}
									>
										<TableCell>Date</TableCell>
										<TableCell>Orders</TableCell>
										<TableCell>My Amount</TableCell>
										<TableCell>Discount</TableCell>
										<TableCell>Delivery charge</TableCell>
										<TableCell>Container charge</TableCell>
										<TableCell>Tax</TableCell>
										<TableCell>Grand Total</TableCell>
										<TableCell>Customer Paid</TableCell>
										<TableCell>Tip</TableCell>
										<TableCell>Tip+Total</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableCell>{new Date(startDate).toLocaleString() + " - " + new Date(endDate).toLocaleString()}</TableCell>

									<TableCell>{totalOrders}</TableCell>
									<TableCell>{
										myTotalAmount.toFixed(2)
									}</TableCell>
									<TableCell>{totalDiscount}</TableCell>
									<TableCell>{totalContainerCharge}</TableCell>
									<TableCell>{totalDeliveryCharge}</TableCell>
									<TableCell>{
										restaurantContext.restaurant.taxes?.map((tax) => {
											return <p> <b>
												{tax?.name} : {(((tax?.value ?? 0) / 100) * myTotalAmount).toFixed(2)}
											</b> <br /></p>
										})
									}
										<b>Total: {(myTotalAmount * (totalTaxValue / 100)).toFixed(2)}</b>
									</TableCell>
									<TableCell>{
										grandTotal.toFixed(2)
									}</TableCell>
									<TableCell>{totalCustomerPaid?.toFixed(2)}</TableCell>
									<TableCell>{totalTip}</TableCell>
									<TableCell>{(grandTotal + totalTip)?.toFixed(2)}</TableCell>
								</TableBody>
							</Table>
						</TableContainer>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary>Payment Wise Report</AccordionSummary>
					<AccordionDetails>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow
										sx={{
											backgroundColor: "#FEF2E2",
											boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
											
											"& .MuiTableCell-root": {
												fontWeight: "bold",
												color: "#9E5E28",
												backgroundColor: "#FEF2E2",
											},
										}}
										>
										<TableCell>Category Name</TableCell>
										<TableCell>Total</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{Object.keys(paymentWiseReport ?? {}).map(
										(payment) => {
											return (
												<TableRow>
													<TableCell
														sx={{
															textTransform: "capitalize",
														}}
													>
														{payment}
													</TableCell>
													<TableCell>{paymentWiseReport[payment]?.toFixed(2)}</TableCell>
												</TableRow>
											);
										}
									)}
									<TableRow>
										<TableCell
											sx={{
												textTransform: "capitalize",
											}}
											>
											<b>Total</b>
										</TableCell>
										<TableCell>
											<b>
												{paymentTotal?.toFixed(2)}
											</b>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary>Category Wise Report
					</AccordionSummary>
					<AccordionDetails>
					<TextField
					placeholder="Search"
					sx={{
						"& .MuiOutlinedInput-root": {
							"& fieldset": {
								outline: "none",
								border: "none",
							},
							"&:hover fieldset": {
								outline: "none",
								border: "none",
							},
							"&.Mui-focused fieldset": {
								outline: "none",
								border: "none",
							},
						},
						width:"100%",
						backgroundColor:"#8080800f"
					}}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search />
							</InputAdornment>
						),
					}}
					value={categorySearch}
					onChange={(e) => {
						setCategorySearch(e.target.value)
					}}
					/>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow
										sx={{
											backgroundColor: "#FEF2E2",
											boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
											
											"& .MuiTableCell-root": {
												fontWeight: "bold",
												color: "#9E5E28",
												backgroundColor: "#FEF2E2",
											},
										}}
										>
										<TableCell>Category Name</TableCell>
										<TableCell>Quantity</TableCell>
										<TableCell>Total</TableCell>
									</TableRow>
								
								</TableHead>
								<TableBody>
									{Object.keys(categoryWiseReport ?? {}).filter((name)=>{
										return regexSearch(name, categorySearch)
									}).map(
										(categoryName) => {
											return (
												<TableRow>
													<TableCell
														sx={{
															textTransform: "capitalize",
														}}
														>
														{categoryName}
													</TableCell>
													<TableCell>{categoryWiseReport[categoryName]?.quantity}</TableCell>
													<TableCell>{categoryWiseReport[categoryName]?.total?.toFixed(2)}</TableCell>
												</TableRow>
											);
										}
										)}
								</TableBody>
							</Table>
						</TableContainer>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary>Department Wise Report</AccordionSummary>
					<AccordionDetails>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow
										sx={{
											backgroundColor: "#FEF2E2",
											boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
											
											"& .MuiTableCell-root": {
												fontWeight: "bold",
												color: "#9E5E28",
												backgroundColor: "#FEF2E2",
											},
										}}
										>
										<TableCell>Department Name</TableCell>
										<TableCell>Quantity</TableCell>
										<TableCell>Total</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{Object.keys(departmentWiseReport ?? {}).map(
										(departmentName) => {
											return (
												<TableRow>
													<TableCell
														sx={{
															textTransform: "capitalize",
														}}
													>
														{departmentName}
													</TableCell>
													<TableCell>{departmentWiseReport[departmentName]?.quantity}</TableCell>
													<TableCell>{departmentWiseReport[departmentName]?.total?.toFixed(2)}</TableCell>
												</TableRow>
											);
										}
										)}
									{/* <TableRow>
									<TableCell
									sx={{
										textTransform: "capitalize",
									}}
									>
										<b>Total</b>
										</TableCell>
										<TableCell>
										<b>
										{Object.values(departmentWiseReport ?? {}).reduce((acc, item) => acc + item, 0).toFixed(2)}
										</b>
										</TableCell>
									</TableRow> */}
								</TableBody>
							</Table>
						</TableContainer>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary>Group Wise Report</AccordionSummary>
					<AccordionDetails>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow
										sx={{
											backgroundColor: "#FEF2E2",
											boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
											
											"& .MuiTableCell-root": {
												fontWeight: "bold",
												color: "#9E5E28",
												backgroundColor: "#FEF2E2",
											},
										}}
										>
										<TableCell>Group Name</TableCell>
										<TableCell>Total</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{Object.keys(groupWiseReport ?? {}).map(
										(groupName) => {
											return (
												<TableRow>
													<TableCell
														sx={{
															textTransform: "capitalize",
														}}
														>
														{groupName} Sales
													</TableCell>
													<TableCell>{groupWiseReport[groupName]}</TableCell>
												</TableRow>
											);
										}
										)}
									{/* <TableRow>
									<TableCell
									sx={{
										textTransform: "capitalize",
									}}
									>
										<b>Total</b>
										</TableCell>
										<TableCell>
										<b>
										{Object.values(groupWiseReport ?? {}).reduce((acc, item) => acc + item, 0).toFixed(2)}
										</b>
										</TableCell>
									</TableRow> */}
								</TableBody>
							</Table>
						</TableContainer>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary>Item Wise Report</AccordionSummary>
					<AccordionDetails>
					<TextField
					placeholder="Search"
					sx={{
						"& .MuiOutlinedInput-root": {
							"& fieldset": {
								outline: "none",
								border: "none",
							},
							"&:hover fieldset": {
								outline: "none",
								border: "none",
							},
							"&.Mui-focused fieldset": {
								outline: "none",
								border: "none",
							},
						},
						width:"100%",
						backgroundColor:"#8080800f"
					}}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search />
							</InputAdornment>
						),
					}}
					value={itemSearch}
					onChange={(e) => {
						setItemSearch(e.target.value)
					}}
				/>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow
										sx={{
											backgroundColor: "#FEF2E2",
											boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

											"& .MuiTableCell-root": {
												fontWeight: "bold",
												color: "#9E5E28",
												backgroundColor: "#FEF2E2",
											},
										}}
									>
										<TableCell>Item Name</TableCell>
										<TableCell>Quantity</TableCell>
										<TableCell>Total</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{Object.values(itemWiseReport ?? {}).filter((item)=>{
										return regexSearch(item.name, itemSearch)
									}).map(
										(item) => {
											return (
												<TableRow>
													<TableCell
														sx={{
															textTransform: "capitalize",
														}}
													>
														{item.name}
													</TableCell>
													<TableCell>{item?.quantity}</TableCell>
													<TableCell>{item?.total?.toFixed(2)}</TableCell>
												</TableRow>
											);
										}
									)}
									{/* <TableRow>
									<TableCell
										sx={{
											textTransform: "capitalize",
										}}
									>
										<b>Total</b>
									</TableCell>
									<TableCell>
										<b>
											{Object.values(groupWiseReport ?? {}).reduce((acc, item) => acc + item, 0).toFixed(2)}
										</b>
									</TableCell>
								</TableRow> */}
								</TableBody>
							</Table>
						</TableContainer>
					</AccordionDetails>
				</Accordion>
			</div>
		</div>
	);
};

export default OrderReport;
