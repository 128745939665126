import React, { useContext, useEffect, useState } from "react";
import styles from './SettlementEdit.module.css'
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogTitle,
    Fab,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import toast from "../../../../utils/toast";
import logger from "../../../../utils/logger";
import axios from "axios";
import commonStyles from '../../../../styles.module.css'
import { Add, Delete } from "@mui/icons-material";
import GradientBorder from "../../../../components/GradientBorder/GradientBorder";
import { ClipLoader } from "react-spinners";
export default function SettlementEdit({ order, refreshOrder, setIsOpen }) {
    let total = 0
    const [method, setMethod] = useState(null);
    const [waivedOff, setWaivedOff] = useState(false)
    const [otherMethod, setOtherMethod] = useState("");
    const [transactionNo, setTransactionNo] = useState("")
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [password, setPassword] = useState("")
    const [modificationReason, setModificationReason] = useState("")
    const [customerPaid, setCustomerPaid] = useState(total ?? 0);
    const [tip, setTip] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [isSettling, setIsSettling] = useState(false)
    const [count, setCount] = useState(0)
    const [partPayments, setPartPayments] = useState([
        {
            method: null,
            amount: 0,
            otherMethod: "",
        },
    ]);

    const handleUpdate = async () => {
        setIsSettling(true)
        try {
            if (!method) {
                return toast.error("Please select a payment method", {
                    autoClose: 2000,
                });
            }

            if ((method === "Card" || method === "Other" ||
                partPayments.find((pp) => pp.method === "Card" || pp.method === "Other")) && !transactionNo) {
                setIsSettling(false)

                return toast.error("Please enter trancsaction no", {
                    autoClose: 2000,
                });

            }

            if (!modificationReason) {
                return toast.error("Please Enter reason", {
                    autoClose: 2000,
                });
            }
            if (method === "Other" && !otherMethod) {
                return toast.error("Please enter a payment method", {
                    autoClose: 2000,
                });
            }
            const res = await axios.put(
                `/restaurants/${localStorage.getItem('restaurant')}/orders/${order?._id}/settlement`,
                {
                    payment: {
                        method: method,
                        otherMethod: otherMethod,
                        transactionNo,
                        customerPaid:
                            method === "Due"
                                ? 0
                                : method === "Part"
                                    ? partPayments.reduce((a, b) => a + parseInt(b.amount), 0)
                                    : customerPaid,
                        tip,
                        parts: method === "Part" ? partPayments : [],
                    },
                    paymentModificationReason: modificationReason,
                    discount
                },
                {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                    },
                }
            );
            toast.success("Settlemet Updated successfully", {
                autoClose: 2000,
            });
            refreshOrder();
            setIsOpen(false)
        } catch (err) {
            logger(err)
            setIsSettling(false)
            toast.error(err?.response?.data?.message ?? "Bill Updation Failed")
        }
    }
    useEffect(() => {
        if (order) {
            setMethod(order?.payment?.method ?? "");
            setTransactionNo(order?.payment?.transactionNo ?? "");
            setOtherMethod(order?.payment?.otherMethod ?? "")
            setCustomerPaid(order?.payment?.customerPaid ?? 0)
            setTip(order?.payment?.tip ?? 0)
            setPartPayments(order?.payment?.parts ?? [])
            setDiscount(order?.discount?.amount ?? 0)
        }

    }, [order])
    return (
        <div className={styles.main}>
            <div className={styles.container}>
                {!waivedOff && <>
                    <Typography
                        variant="body1"
                        component="div"
                        color="#824500"
                        fontWeight={500}
                    >
                        Payment Type
                    </Typography>
                    <RadioGroup
                        row
                        name="payment-method"
                        sx={{
                            padding: "8px",
                        }}
                        value={method}
                        onChange={(e) => {
                            setMethod(e.target.value);
                        }}
                    >
                        <FormControlLabel value="Cash" control={<Radio />} label="Cash" />
                        <FormControlLabel value="Card" control={<Radio />} label="Card" />
                        <FormControlLabel value="Due" control={<Radio />} label="Due" />
                        <FormControlLabel
                            value="Other"
                            control={<Radio />}
                            label="Other"
                        />
                        <FormControlLabel value="Part" control={<Radio />} label="Part" />
                    </RadioGroup>
                    <div className={styles.inputs}>
                        {method === "Other" && (
                            <div className={styles.input}>
                                <label htmlFor="other_method">Other</label>
                                <select
                                    name="other_method"
                                    id="other_method"
                                    value={otherMethod}
                                    onChange={(e) => {
                                        setOtherMethod(e.target.value);
                                    }}
                                >
                                    <option value="UPI">UPI</option>
                                    <option value="Paytm">Paytm</option>
                                    <option value="GPay">GPay</option>
                                    <option value="PhonePe">PhonePe</option>
                                    <option value="forestfoods">forestfoods</option>
                                    <option value="Zomato Pay">Zomato Pay</option>
                                    <option value="Swiggy Pay">Swiggy Pay</option>
                                </select>
                            </div>
                        )}
                        {method === "Part" && (
                            <>
                                <Typography
                                    variant="body1"
                                    component="div"
                                    color="#824500"
                                    fontWeight={500}
                                    sx={{
                                        marginLeft: "8px",
                                    }}
                                >
                                    Part Payments
                                </Typography>
                                {partPayments.map((partPayment, index) => {
                                    return (
                                        <div
                                            style={{
                                                margin: "0 12px",
                                                width: "calc(100% - 24px)",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    padding: "8px 0",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <label
                                                    htmlFor={"payment-method-" + index.toString()}
                                                    style={{
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    Method
                                                </label>

                                                <Delete
                                                    color="error"
                                                    fontSize="small"
                                                    onClick={() => {
                                                        const newPartPayments = [...partPayments];
                                                        newPartPayments.splice(index, 1);
                                                        setPartPayments(newPartPayments);
                                                    }}
                                                />
                                            </div>
                                            <RadioGroup
                                                row
                                                name={"payment-method-" + index.toString()}
                                                sx={{
                                                    padding: "8px",
                                                }}
                                                value={partPayment.method}
                                                onChange={(e) => {
                                                    const newPartPayments = [...partPayments];
                                                    newPartPayments[index].method = e.target.value;
                                                    setPartPayments(newPartPayments);
                                                }}
                                            >
                                                <FormControlLabel
                                                    value="Cash"
                                                    control={<Radio />}
                                                    label="Cash"
                                                />
                                                <FormControlLabel
                                                    value="Card"
                                                    control={<Radio />}
                                                    label="Card"
                                                />
                                                <FormControlLabel
                                                    value="Other"
                                                    control={<Radio />}
                                                    label="Other"
                                                />
                                            </RadioGroup>
                                            {partPayment.method === "Other" && (
                                                <div className={styles.input}>
                                                    <label htmlFor={"other_method_" + index.toString()}>
                                                        Other
                                                    </label>
                                                    <select
                                                        name={"other_method_" + index.toString()}
                                                        id={"other_method_" + index.toString()}
                                                        value={partPayment.otherMethod}
                                                        onChange={(e) => {
                                                            const newPartPayments = [...partPayments];
                                                            newPartPayments[index].otherMethod =
                                                                e.target.value;
                                                            setPartPayments(newPartPayments);
                                                        }}
                                                    >
                                                        <option value="UPI">UPI</option>
                                                        <option value="Paytm">Paytm</option>
                                                        <option value="GPay">GPay</option>
                                                        <option value="PhonePe">PhonePe</option>
                                                        <option value="forestfoods">forestfoods</option>
                                                        <option value="Zomato Pay">Zomato Pay</option>
                                                        <option value="Swiggy Pay">Swiggy Pay</option>
                                                    </select>
                                                </div>
                                            )}
                                            <div key={index} className={styles.input}>
                                                <label
                                                    htmlFor={"part_payment_amount_" + index.toString()}
                                                >
                                                    Amount
                                                </label>
                                                <input
                                                    type="number"
                                                    name={"part_payment_amount_" + index.toString()}
                                                    id={"part_payment_amount_" + index.toString()}
                                                    value={partPayment.amount}
                                                    onChange={(e) => {
                                                        const newPartPayments = [...partPayments];
                                                        newPartPayments[index].amount = parseInt(
                                                            e.target.value
                                                        ) < 0 ? 0 : parseInt(e.target.value);
                                                        setPartPayments(newPartPayments);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                                <Fab
                                    variant="extended"
                                    size="small"
                                    color="primary"
                                    onClick={() => {
                                        const newPartPayments = [...partPayments];
                                        newPartPayments.push({
                                            method: "Cash",
                                            amount: 0,
                                        });
                                        setPartPayments(newPartPayments);
                                    }}
                                >
                                    <Add />
                                    Add Part Payment
                                </Fab>
                            </>
                        )}
                        <div className={styles.input}>
                            <label htmlFor="customer_paid">Customer Paid</label>
                            <input
                                type="number"
                                name="customer_paid"
                                id="customer_paid"
                                value={
                                    method === "Due"
                                        ? 0
                                        : method === "Part"
                                            ? partPayments.reduce((a, b) => a + parseInt(b.amount), 0)
                                            : customerPaid
                                }
                                // disabled={method === "Due" || method === "Part"}
                                disabled
                                onChange={(e) => {
                                    setCustomerPaid(e.target.value < 0 ? 0 : e.target.value);
                                }}
                            />
                        </div>
                        {
                            (method === "Card" || method === "Other" ||
                                partPayments.find((pp) => pp.method === "Card" || pp.method === "Other")) &&

                            <div className={styles.input}>
                                <label htmlFor="customer_paid">Transaction No</label>
                                <input
                                    type="text"
                                    name="transactionNo"
                                    id="transactionNo"
                                    value={transactionNo}
                                    // disabled={method === "Due" || method === "Part"}
                                    // disabled
                                    onChange={(e) => {
                                        setTransactionNo(e.target.value);
                                    }}
                                />
                            </div>
                        }
                        <div className={styles.input}>
                            <label htmlFor="tip">Tip</label>
                            <input
                                type="number"
                                disabled
                                name="tip"
                                id="tip"
                                value={tip}
                                onChange={(e) => {
                                    setTip(e.target.value);
                                }}
                            />
                        </div>
                        <div className={styles.input}>
                            <label htmlFor="tip">Discount</label>
                            <input
                                type="number"
                                name="tip"
                                id="tip"
                                value={discount}
                                disabled
                                onChange={(e) => {
                                    setDiscount(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </>}
                {/* <div className={styles.input}>
                <div
                    style={{
                        fontWeight: 500,
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    Waived Off
                    <Checkbox
                        checked={waivedOff}
                        onChange={(e) => setWaivedOff(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </div>
            </div> */}
                <div >
                    <button disabled={isSettling} onClick={() => setConfirmDialogOpen(true)} className={commonStyles.primary_button}>Update
                        <ClipLoader color="white" size={10} marginLeft={"10px"} loading={isSettling}></ClipLoader>
                    </button>
                </div>
            </div>
            <ConfirmDialog
                password={password}
                setPassword={setPassword}
                confirmDialogOpen={confirmDialogOpen}
                modificationReason={modificationReason}
                setModificationReason={setModificationReason}
                handleUpdate={handleUpdate}
                setConfirmDialogOpen={setConfirmDialogOpen}
            />
        </div>
    )
}
const ConfirmDialog = ({ password, setPassword, modificationReason, setModificationReason, confirmDialogOpen, setConfirmDialogOpen, handleUpdate }) => {
    return (
        <Dialog
            open={confirmDialogOpen}
            onClose={() => setConfirmDialogOpen(false)}
            sx={{
                "& .MuiDialog-paper": {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                },

                "& .MuiModal-backdrop": {
                    backdropFilter: "blur(8px)",
                },
            }}
        >
            <GradientBorder
                gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
                width="1px"
                className={styles.dialog_wrapper}
            >
                <div>
                    <Typography
                        variant="body1"
                        component="div"
                        color="#9E5E28"
                        sx={{
                            marginBottom: "12px",
                        }}
                    >
                        Confirm Modification
                    </Typography>
                    <div className={styles.dialog_input}>
                        <Typography variant="body2" component="div" color="#9E5E28">
                            Password
                        </Typography>
                        <GradientBorder
                            gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
                            width="1px"
                            className={styles.input_wrapper}
                        >
                            <input
                                type="password"
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }}
                            />
                        </GradientBorder>
                    </div>
                    <div className={styles.dialog_input}>
                        <Typography variant="body2" component="div" color="#9E5E28">
                            Reason
                        </Typography>
                        <GradientBorder
                            gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
                            width="1px"
                            className={styles.input_wrapper}
                        >
                            <input
                                type="text"
                                onChange={(e) => {
                                    setModificationReason(e.target.value)
                                }}
                            />
                        </GradientBorder>
                    </div>
                    <div className={styles.dialog_button_row}>
                        <button
                            className={commonStyles.secondary_button}
                            onClick={() => {
                                setConfirmDialogOpen(false);
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className={commonStyles.primary_button}
                            onClick={() => {
                                if (password === "st13521") {
                                    if (modificationReason === "") {
                                        logger("Please enter a reason")
                                        toast.error("Please enter a reason", {
                                            autoClose: 2000,
                                        });
                                        return;
                                    }
                                    handleUpdate()
                                    setConfirmDialogOpen(false)


                                } else {
                                    logger("incorrect password")
                                    toast.error("Incorrect Password", {
                                        autoClose: 2000,
                                    });
                                }
                            }}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </GradientBorder>
        </Dialog>
    );
};