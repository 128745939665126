import React, { useContext, useEffect, useState } from "react";
import styles from "./QRDialog.module.css";
import { restaurant } from "../../providers/RestaurantProvider";
import { Dialog, Typography } from "@mui/material";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import qrious from "qrious";
import commonStyles from "../../styles.module.css";
import axios from "axios";
import logger from "../../utils/logger";
import toast from "../../utils/toast";

const QRDialog = ({ isOpen, setIsOpen }) => {
	const restaurantContext = useContext(restaurant);
	const [qr, setQR] = useState(null);

	useEffect(() => {
		if (restaurantContext.restaurant?._id) {
			const qr = new qrious({
				value: `https://dinein.shakespeare.vision/waitlist/${restaurantContext.restaurant._id}/${restaurantContext.restaurant.waitlistCode}`,
				// value: `http://localhost:5001/waitlist/${restaurantContext.restaurant._id}/${restaurantContext.restaurant.waitlistCode}`,
				size: 350,
				padding: 24,
			});
			setQR(qr);
		}
	}, [
		restaurantContext.restaurant?.waitlistCode,
		restaurantContext.restaurant?._id,
	]);

	return (
		<Dialog
			open={isOpen}
			onClose={() => setIsOpen(false)}
			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
					maxWidth: "none",
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
				width="1px"
				className={styles.qr_dialog}
			>
				<div>
					<Typography
						variant="h6"
						component="div"
						color="#B29466"
						fontWeight={400}
					>
						Waitlist QR Code
					</Typography>
					{qr && <img src={qr.toDataURL()} alt="QR Code" />}
					<div>
						<Typography
							variant="body1"
							component="div"
							color="#B29466"
							fontWeight={400}
						>
							Last Regenerated:{" "}
							{new Date(
								restaurantContext.restaurant.lastCodeRefreshAt
							)?.toLocaleString() ?? "Never"}
						</Typography>
					</div>
					<div className={styles.cta_row}>
						<button
							className={commonStyles.secondary_button}
							onClick={async () => {
								const blob = await (await fetch(qr.toDataURL())).blob();
								navigator.clipboard.write([
									new ClipboardItem({
										[blob.type]: blob,
									}),
								]);
								toast.info("Copied QR Code to clipboard");
							}}
						>
							Copy
						</button>
						<button
							className={commonStyles.primary_button}
							onClick={async () => {
								try {
									const res = await axios.get(
										`/restaurants/${restaurantContext.restaurant._id}/waitlist/regenerate`,
										{
											headers: {
												"x-auth-token": localStorage.getItem("token"),
											},
										}
									);

									logger(res.data);
									restaurantContext.updateRestaurant({
										waitlistCode: res.data.code,
									});
								} catch (err) {
									logger(err);
									toast.error(
										err.response?.data?.message ??
											"Failed to regenerate waitlist code"
									);
								}
							}}
						>
							Regenerate
						</button>
					</div>
				</div>
			</GradientBorder>
		</Dialog>
	);
};

export default QRDialog;
