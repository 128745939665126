import React, { useRef } from "react";
import styles from "./PrintScreen.module.css";
import qrious from "qrious";

const OrderPrint = ({ order, kots, restaurant }) => {
	// const { kots } = useContext(kotContext);
	const printerRef = useRef(null);



	let orderBillData =
		JSON.parse(localStorage.getItem("orderBillData"))?.[order?.table?.name] ??
		{};

	let subTotal = 0;
	let itemList = {};

	if (order && order._id) {
		for (let kotId of order.kots || []) {
			let kot = {}
			console.log(kotId, kots)
			if (kotId?._id) {
				kot = kotId
			} else {
				kot = kots[kotId];
			}
			if (!kot) continue;
			subTotal += kot.total ?? 0;
			for (let item of kot.items || []) {
				const key = item.item._id + (item?.properties ?? []).reduce((acc, prop) => {
					return acc + JSON.stringify({ name: prop.name, options: prop.options, option: prop.option })
				}, "")
				if (itemList[key]) {
					itemList[key].quantity += item.quantity;
				} else {
					itemList[key] = { ...item }
				}
			}
		}
	}


	let grandTotal = (Number(subTotal) -
		Number(orderBillData?.amount ?? 0) +
		Number(orderBillData?.deliveryCharge ?? 0) +
		Number(orderBillData?.containerCharge ?? 0));
	const taxes = restaurant?.taxes || [];
	for (let tax of taxes) {
		grandTotal += (tax.value / 100) * (subTotal - (orderBillData?.amount ?? 0));
	}
	let qr = {}, reviewQr = {};
	if (restaurant?.upiUrl) {
		const urlObj = new URL(restaurant?.upiUrl ?? "");
		urlObj.searchParams.set("am", grandTotal.toFixed(2));
		qr = new qrious({
			value: urlObj.toString(),
			size: 200,
		});
	}
	// if (restaurant?.reviewUrl) {
	// 	reviewQr = new qrious({
	// 		value: restaurant?.reviewUrl?.toString(),
	// 		size: 200,
	// 	});
	// }
	if (order?._id) {
		reviewQr = new qrious({
			value: `https://dinein.shakespeare.vision/rating/restaurant/${order.restaurant}/order/${order._id}`,
			size: 200,
		});
		console.log(reviewQr)
	}



	return (
		<div className={styles.prints}>
			<article
				style={{
					paddingRight: "10px",
					paddingLeft: "10px",
					fontSize: "26px",
				}}
				ref={printerRef}
			>
				<table
					className={styles.info_table}
					style={{
						width: "100%",
					}}
				>
					<tr>
						<th style={{ fontWeight: "800" }}>
							{restaurant?.name}
						</th>
					</tr>
					{order?.billPrinted >= 1 && (
						<tr style={{ textAlign: "center" }}>
							<td>(Bill reprinted)</td>
						</tr>
					)}
				</table>
				<hr />
				<table>
					<tr style={{ fontWeight: "500" }}>
						Name: <b>{order.user?.name || "--"}</b>
					</tr>
					<tr>
						<td style={{ fontWeight: "500" }}>
							Date : <b>{new Date(order?.createdAt ?? new Date())?.toLocaleDateString()}</b>
						</td>
					</tr>
					<tr>
						<td style={{ fontWeight: "500" }}>
							{order?.table?.type?.toUpperCase() ?? "DINEIN"} : <b>{order?.table?.name ?? "--"}</b>
						</td>
					</tr>
					<tr>
						<td style={{ fontWeight: "500" }}>
							GST No: <b>{restaurant?.gstNo || "--"}</b>
						</td>
					</tr>
					<tr>
						<td style={{ fontWeight: "500" }}>
							Captain: <b>{order?.captain?.name || "--"}</b>
						</td>
					</tr>
					<tr>
						<td style={{ fontWeight: "500" }}>
							Bill No: <b>{order?.orderNumber || "--"}</b>
						</td>
					</tr>
				</table>
				
				<table className={styles.item_table}>
					<thead>
						<tr>
							<th>
								<span style={{ fontWeight: "bolder" }}>Item</span>
							</th>
							<th>
								<span style={{ fontWeight: "bolder" }}>Qty.</span>
							</th>
							<th>
								<span style={{ fontWeight: "bolder" }}>Price</span>
							</th>
							<th>
								<span style={{ fontWeight: "bolder" }}>Amount</span>
							</th>
						</tr>
					</thead>
					<tbody>
						{Object.values(itemList || {}).map((item) => {
							let itemTotal = item.item.price;

							for (let property of item.properties || []) {
								const prop = item.item.properties.find((prop) => {
									return prop.name === property.name;
								});

								if (!prop) {
									continue;
								}
								let optPrice = 0;
								if (property?.options?.[0]) {
									prop.options?.forEach((option) => {
										if (property.options.includes(option.name)) {
											optPrice += option?.price ?? 0
										}
									})
								} else {
									const opt = prop?.options.find((opt) => opt.name === property.option);
									optPrice = opt?.price ?? 0
								}
								itemTotal += optPrice;
							}
							return (
								<tr key={item._id} className={styles.lightBorderBottom}>
									<td>
										<span style={{ fontWeight: "bolder" }}>
											{item.item.name}
										</span>
										{(item.properties || []).map((property) => {
											return (
												<div
													style={{
														marginLeft: "10px",
														fontStyle: "italic",
														fontWeight: "500",
													}}
												>
													{property.name} : {property.option ? property.option : property.options?.join(", ") ?? ""}
												</div>
											);
										})}
									</td>
									<td>
										<span style={{ fontWeight: "500" }}>{item.quantity}</span>
									</td>
									<td>
										<span style={{ fontWeight: "500" }}>
											{itemTotal.toFixed(2)}
										</span>
									</td>
									<td>
										<span style={{ fontWeight: "500" }}>
											{(itemTotal * item.quantity).toFixed(2)}
										</span>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				<hr />
				<table className={styles.total_table}>
					<tr>
						<td style={{ fontWeight: "500" }}>
							Total Qty:{" "}
							{Object.values(itemList ?? {}).reduce((acc, item) => acc + item.quantity, 0)}
						</td>
					</tr>
					<tr>
						<td style={{ fontWeight: "500" }}>
							Subtotal: {subTotal.toFixed(2)}
						</td>
					</tr>
					<tr>
						<td style={{ fontWeight: "500" }}>
							Discount: {(orderBillData?.amount ?? 0).toFixed(2)}
						</td>
					</tr>
					{
						orderBillData?.deliveryCharge &&
						<tr>
							<td style={{ fontWeight: "500" }}>
								Delivery Charge: {orderBillData?.deliveryCharge ?? 0}
							</td>
						</tr>
					}
					{
						orderBillData?.containerCharge &&
						<tr>
							<td style={{ fontWeight: "500" }}>
								Container Charge: {orderBillData?.containerCharge ?? 0}
							</td>
						</tr>
					}

					{(restaurant?.taxes || []).map((tax) => {
						return (
							<tr>
								<td style={{ fontWeight: "500" }}>{tax.name}</td>
								<td style={{ fontWeight: "500" }}>{tax.value}%</td>
								<td style={{ fontWeight: "500" }}>
									{((subTotal - (orderBillData?.amount ?? 0)) * (tax.value / 100)).toFixed(2)}
								</td>
							</tr>
						);
					})}
					<tr style={{ fontWeight: "800", fontSize:"2rem" }}>
						<td colSpan={3}>
								Grand Total :{" "}
								{grandTotal.toFixed(2)}
						</td>
					</tr>
				</table>

				{
					reviewQr ? (
						<>
							<hr />
							<u>
								<h4>Add Review</h4>
							</u>
							<div className={styles.qr_container}>

								<img src={reviewQr?.toDataURL()} alt="" />

							</div>
						</>) : null
				}
{/* 
				{
					restaurant?.reviewUrl ? (
						<>
							<hr />
							<u>
								<h4>Add Review</h4>
							</u>
							<div className={styles.qr_container}>

								<img src={reviewQr?.toDataURL()} alt="" />

							</div>
						</>
					) : null
				} */}
				<hr />
				<center style={{ fontWeight: "bolder" }}>Thanks</center>
				<hr />
			</article>
		</div>
	);
};

export default OrderPrint;
