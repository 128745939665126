import { Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import React, { useState } from "react";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import commonStyles from "../../../styles.module.css";
import styles from "./ConfirmBill.module.css";
import logger from "../../../utils/logger";
import toast from "../../../utils/toast";
// import { logger } from "workbox-core/_private";

const ConfirmBill = ({ open, setOpen, handleFulfill, email, setEmail }) => {
	const handleCancel = () => {
		setOpen(false);
	};

	const [serviceTax, setServiceTax] = useState(
		JSON.parse(localStorage.getItem("serviceTax")) ?? false
	);
	return (
		<Dialog
			open={open}
			onClose={handleCancel}
			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
				},

				"& .MuiModal-backdrop": {
					// backgroundColor: "transparent",
					// backdropFilter: "blur(8px)",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.dialog_wrapper}
			>
				<div className={styles.dialog}>
					<DialogTitle>Confirm Bill?</DialogTitle>
					<DialogContent>
						<div className={styles.dialog_content}>
							This will mark the order as biled. Do you want to proceed with the
							it and send an e-bill?
						</div>
						<div>
							<div>
								<div>
									<div className={styles.dialog_content}>
										<input
											type="checkbox"
											checked={serviceTax}
											onChange={
												() => {
													setServiceTax(!serviceTax);
													localStorage.setItem("serviceTax", !serviceTax);
												}
												// logger()
											}
										/>
										Levy Service Tax?
									</div>
								</div>
								<TextField
									// label="Email"
									placeholder="Email"
									// inputProps={{ readOnly: true }}
									onClick={() => {
										logger("clicked");
										// setIsCaptainSelectOpen(true);
									}}
									value={email}
									onChange={(e) => {
										setEmail(e.target.value);
										localStorage.setItem("email", JSON.stringify(email));
									}}
								/>
							</div>
						</div>
						<div className={styles.dialog_buttons}>
							<button
								className={commonStyles.secondary_button}
								onClick={handleCancel}
							>
								Cancel
							</button>
							<button
								className={commonStyles.primary_button}
								onClick={() => {
									if (email !== "") {
										logger("email", email);
										handleFulfill();
									} else
										toast.error("Email must be added to send the e-receipt");
								}}
							>
								Yes
							</button>
						</div>
					</DialogContent>
				</div>
			</GradientBorder>
		</Dialog>
	);
};

export default ConfirmBill;
