import axios from "axios";
import React, { createContext, useRef, useState } from "react";
import toast from "../utils/toast";
import logger from "../utils/logger";

const printer = createContext();

const { Provider } = printer;

const PrintProvider = ({ children }) => {
	const [printers, setStatePrinters] = useState(
		JSON.parse(localStorage.getItem("printers")) || []
	);
	const printRef= useRef();
	const [recipeData, setRecipeData] = useState({})

	const [departments, setStateDepartments] = useState(
		JSON.parse(localStorage.getItem("departments")) || []
	);
	const [KOTBillToPrint, setKOTBillToPrint] = useState({ print: false });
	const [kotToPrint, setKotToPrint] = useState({});
	const [onlineKOTToPrint, setOnlineKOTToPrint] = useState({});
	const [orderToPrint, setOrderToPrint] = useState({});

	const setPrinters = (printers) => {
		localStorage.setItem("printers", JSON.stringify(printers));
		setStatePrinters(printers);
	};

	const setDepartments = (departments) => {
		localStorage.setItem("departments", JSON.stringify(departments));
		setStateDepartments(departments);
	};

	const addPrinter = (printer) => {
		setPrinters([...printers, printer]);
	};

	const refreshPrinters = async ({ showToast = false }) => {
		if (localStorage.getItem("token") === null) return;
		try {
			const res = await axios.get(
				`restaurants/${localStorage.getItem("restaurant")}/printers`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			setStatePrinters((prevPrinters) => {
				const newPrinters = [];
				for (let printer of res.data) {
					const printerIndex = prevPrinters.findIndex(
						(p) => p._id === printer._id
					);
					if (printerIndex === -1) {
						newPrinters.push({
							...printer,
							inUse: false,
							shouldPrintKOT: false,
							shouldPrintOrder: false,
							shouldPrintRecipe: false,
						});
					} else {
						newPrinters.push({
							...printer,
							inUse: prevPrinters[printerIndex].inUse,
							shouldPrintKOT: prevPrinters[printerIndex].shouldPrintKOT,
							shouldPrintOrder: prevPrinters[printerIndex].shouldPrintOrder,
							shouldPrintRecipe: prevPrinters[printerIndex].shouldPrintRecipe,
						});
					}
				}

				localStorage.setItem("printers", JSON.stringify(newPrinters));
				return newPrinters;
			});

			if (showToast) {
				toast.success("Printers refreshed", {
					autoClose: 2000,
				});
			}
			return res;
		} catch (err) {
			logger(err);
		}
	};

	const refreshDepartments = async ({ showToast = false }) => {
		if (localStorage.getItem("token") === null) return;
		try {
			const res = await axios.get(
				`restaurants/${localStorage.getItem("restaurant")}/departments`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);
			setDepartments([...res.data]);

			if (showToast) {
				toast.success("Departments refreshed", {
					autoClose: 2000,
				});
			}
			return res;
		} catch (err) {
			logger(err);
		}
	};

	const toggleInUse = (printerId) => {
		setStatePrinters((prevPrinters) => {
			const newPrinters = [...prevPrinters];
			const index = newPrinters.findIndex((p) => p._id === printerId);
			newPrinters[index].inUse = !newPrinters[index].inUse;
			localStorage.setItem("printers", JSON.stringify(newPrinters));
			return newPrinters;
		});
	};
	const toggleShouldPrintKOT = (printerId) => {
		setStatePrinters((prevPrinters) => {
			const newPrinters = [...prevPrinters];
			const index = newPrinters.findIndex((p) => p._id === printerId);
			newPrinters[index].shouldPrintKOT = !newPrinters[index].shouldPrintKOT;
			localStorage.setItem("printers", JSON.stringify(newPrinters));
			return newPrinters;
		});
	};

	const toggleShouldPrintOrder = (printerId) => {
		setStatePrinters((prevPrinters) => {
			const newPrinters = [...prevPrinters];
			const index = newPrinters.findIndex((p) => p._id === printerId);
			newPrinters[index].shouldPrintOrder =
				!newPrinters[index].shouldPrintOrder;
			localStorage.setItem("printers", JSON.stringify(newPrinters));
			return newPrinters;
		});
	};
	const toggleShouldPrintRecipe = (printerId) => {
		setStatePrinters((prevPrinters) => {
			const newPrinters = [...prevPrinters];
			const index = newPrinters.findIndex((p) => p._id === printerId);
			newPrinters[index].shouldPrintRecipe = !(newPrinters[index].shouldPrintRecipe ?? false);

			localStorage.setItem("printers", JSON.stringify(newPrinters));
			return newPrinters;
		});
	};


	return (
		<Provider
			value={{
				printers,
				setPrinters,
				addPrinter,
				refreshPrinters,
				toggleInUse,
				departments,
				refreshDepartments,
				setDepartments,
				toggleShouldPrintOrder,
				toggleShouldPrintKOT,
				toggleShouldPrintRecipe,
				KOTBillToPrint,
				setKOTBillToPrint,
				kotToPrint,
				setKotToPrint,
				orderToPrint,
				setOrderToPrint,
				recipeData,
				setRecipeData,
				printRef,
				onlineKOTToPrint,
				setOnlineKOTToPrint
			}}
		>
			{children}
			<div style={{
				position: "fixed",
				left: "0px",
				top: "0px",
				zIndex: -9999,
				opacity:'0%'
			}} ref={printRef}>

			</div>
		</Provider>
	);
};

export { printer, PrintProvider };
