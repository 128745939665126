import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast from "../../utils/toast";
import logger from "../../utils/logger";
import {
	InputAdornment,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from "@mui/material";
import styles from "./DepartmentListing.module.css";
import { Search } from "@mui/icons-material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Header from "../../components/Header/Header";
import commonStyles from "../../styles.module.css";
import AddDepartmentDialog from "./AddDepartmentDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import DepartmentDetails from "./DepartmentDetails";
import EditDepartmentDialog from "./EditDepartmentDialog";
import { printer } from "../../providers/PrintProvider";

const DepartmentListing = () => {
	// const [departments, setDepartments] = useState([]);
	const { departments, setDepartments, refreshDepartments } =
		useContext(printer);

	const [refreshPage, setRefreshPage] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
	const [isDeleteConfirmDialog, setIsDeleteConfirmDialog] = useState({
		open: false,
		id: "",
	});
	const [departmentDetailsDrawer, setDepartmentDetailsDrawer] = useState({
		open: false,
		departmentDetails: {},
	});
	const [isEditDepartmentDialog, setIsEditDepartmentDialog] = useState({
		open: false,
		departmentDetails: {},
	});

	const openAddDialog = () => {
		setIsAddDialogOpen(true);
	};
	const closeAddDialog = () => {
		setIsAddDialogOpen(false);
	};
	const reloadPage = () => {
		setRefreshPage(!refreshPage);
	};

	const handleAddDepartment = async (departmentName) => {
		logger(departmentName);
		closeAddDialog();
		try {
			const res = await axios.post(
				`/restaurants/${localStorage.getItem("restaurant")}/departments`,
				{
					name: departmentName,
				},
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
				);
				logger(res.data);
				setDepartments([...departments, res.data]);
				return res;
			} catch (error) {
				logger(error);
				toast.error(error.response?.data?.message ?? "Error Adding Data", {
					autoClose: 2000,
				});
			}
	};

	const deleteDepartment = async (id) => {
		try {
			const res= await axios.delete(
				`/restaurants/${localStorage.getItem("restaurant")}/departments/${id}`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);
			reloadPage();
			return res;
		} catch (error) {
			logger(error);
			toast.error(error.response?.data?.message ?? "Error Deleting Data", {
				autoClose: 2000,
			});
		}
	};

	useEffect(() => {
		
		const callFunction = refreshDepartments(false);
		toast.promise(callFunction, {
			pending: "Fetching Departments...",
			error: "Fetching Departments Failed",
			success: "Departments Fetched Successfully"
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshPage]);

	return (
		<div className={styles.body}>
			<Header title="Departments" />
			<div className={styles.top_bar}>
				<TextField
					placeholder="Search"
					className={styles.search}
					sx={{
						"& .MuiOutlinedInput-root": {
							"& fieldset": {
								outline: "none",
								border: "none",
							},
							"&:hover fieldset": {
								outline: "none",
								border: "none",
							},
							"&.Mui-focused fieldset": {
								outline: "none",
								border: "none",
							},
						},
					}}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search />
							</InputAdornment>
						),
					}}
					value={searchQuery}
					onChange={(e) => {
						setSearchQuery(e.target.value);
					}}
				/>

				<button className={commonStyles.primary_button} onClick={openAddDialog}>
					Add Department
				</button>
			</div>
			<TableContainer>
				<Table stickyHeader>
					<TableHead>
						<TableRow
							sx={{
								backgroundColor: "#FEF2E2",
								boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

								"& .MuiTableCell-root": {
									fontWeight: "bold",
									color: "#9E5E28",
									backgroundColor: "#FEF2E2",
								},
							}}
						>
							<TableCell>Department ID</TableCell>
							<TableCell>Department Name</TableCell>
							<TableCell>Total Staffs</TableCell>
							<TableCell>Total Menu Items</TableCell>
							<TableCell>Action</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{departments[0] &&
							departments
								.filter(
									(department) =>
										department.name.match(new RegExp(searchQuery, "i")) ||
										department._id.match(new RegExp(searchQuery, "i"))
								)
								.map((department, index) => {
									return (
										<TableRow key={index}>
											<TableCell>{department._id}</TableCell>
											<TableCell>{department.name}</TableCell>
											<TableCell>{department.staff.length}</TableCell>
											<TableCell>{department.items.length}</TableCell>
											<TableCell sx={{ gap: "10px", display: "flex" }}>
												<EditIcon
													onClick={() =>
														setIsEditDepartmentDialog({
															open: true,
															departmentDetails: department,
														})
													}
													sx={{ cursor: "pointer" }}
												/>
												<RemoveRedEyeIcon
													onClick={() =>
														setDepartmentDetailsDrawer({
															open: true,
															departmentDetails: department,
														})
													}
													sx={{ cursor: "pointer" }}
												/>
												<DeleteIcon
													onClick={() =>
														setIsDeleteConfirmDialog({
															open: true,
															id: department._id,
														})
													}
													sx={{ cursor: "pointer" }}
												/>
											</TableCell>
										</TableRow>
									);
								})}
					</TableBody>
				</Table>
			</TableContainer>
			<AddDepartmentDialog
				open={isAddDialogOpen}
				setOpen={setIsAddDialogOpen}
				callback={(departmentName) => {
					const callFunction=handleAddDepartment(departmentName);
					toast.promise(callFunction, {
						pending: "Adding Department...",
						error: "Failed",
						success: "Successfully Added Department"
					});
				}}
			/>
			<EditDepartmentDialog
				reloadPage={reloadPage}
				open={isEditDepartmentDialog.open}
				departmentDetails={isEditDepartmentDialog.departmentDetails}
				closeDialog={() =>
					setIsEditDepartmentDialog({ open: false, departmentDetails: {} })
				}
			/>
			<ConfirmDialog
				open={isDeleteConfirmDialog.open}
				heading="Delete this"
				text={"Are you sure delete this Department"}
				closeDialog={() => setIsDeleteConfirmDialog({ open: false })}
				callback={(isConfirmed) => {
					if (isConfirmed) {
						const callFunction= deleteDepartment(isDeleteConfirmDialog.id);
						toast.promise(callFunction, {
							pending: "Deleting Departments...",
							error: "Failed",
							success: "Deleted"
						});
					}
				}}
			/>
			<DepartmentDetails
				open={departmentDetailsDrawer.open}
				closeDrawer={() =>
					setDepartmentDetailsDrawer({ open: false, departmentDetails: {} })
				}
				department={departmentDetailsDrawer.departmentDetails}
			/>
		</div>
	);
};

export default DepartmentListing;
