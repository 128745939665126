import React, { useContext, useEffect, useState } from "react";
import styles from "./SettleDialog.module.css";
import {
	Checkbox,
	Dialog,
	DialogActions,
	DialogTitle,
	Fab,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";
import GradientBorder from "../GradientBorder/GradientBorder";
import toast from "../../utils/toast";
import logger from "../../utils/logger";
import axios from "axios";
import { Add, Delete } from "@mui/icons-material";
import GradientText from "../GradientText/GradientText";
import commonStyles from "../../styles.module.css";
import { kotContext } from "../../providers/KotProvider";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import ClipLoader from "react-spinners/ClipLoader";
import { restaurant } from "../../providers/RestaurantProvider";

const SettleDialog = ({ open, setOpen, order, orderBillData }) => {

	const restaurantContext = useContext(restaurant)

	const { kots } = useContext(kotContext)
	let subTotal = (order.kots || []).reduce((acc, kotId) => {
		const kot = kots[kotId]
		if (!kot) return acc;
		return acc + kot.total;
	}, 0)
	let total = subTotal - (Number(orderBillData?.amount ?? 0)) +
		(Number(orderBillData?.deliveryCharge ?? 0)) +
		(Number(orderBillData?.containerCharge ?? 0));
	const taxes = restaurantContext.restaurant.taxes || [];
	for (let tax of taxes) {
		total += (tax.value / 100) * (subTotal - (orderBillData?.amount ?? 0));
	}

	const navigate = useNavigate()

	const [method, setMethod] = useState(null);
	const [waivedOff, setWaivedOff] = useState(false)
	const [waivedOffReason, setWaivedOffReason] = useState("")
	const [otherMethod, setOtherMethod] = useState("");
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
	const [customerPaid, setCustomerPaid] = useState(total ?? 0);
	const [tip, setTip] = useState(0);
	const [isSettling, setIsSettling] = useState(false)
	const [count, setCount] = useState(0)
	const [transactionNo, setTransactionNo] = useState("")
	const [partPayments, setPartPayments] = useState([
		{
			method: null,
			amount: 0,
			otherMethod: "",
		},
	]);

	useEffect(() => {
		if (count <= 1) {
			const totalAmount = (order.kots || []).reduce((acc, kotId) => {
				const kot = kots[kotId]
				if (!kot) return acc;
				return acc + kot.total;
			}, 0)
			let total = totalAmount - (Number(orderBillData?.amount ?? 0)) +
				(Number(orderBillData?.deliveryCharge ?? 0)) +
				(Number(orderBillData?.containerCharge ?? 0));
			const taxes = restaurantContext.restaurant.taxes || [];
			for (let tax of taxes) {
				total += (tax.value / 100) * (subTotal - (orderBillData?.amount ?? 0));
			}

			setCustomerPaid(Math.round(total) ?? 0)
			setCount(prev => prev + 1)
		}
	}, [orderBillData])
	useEffect(() => {
		const totalAmount = (order.kots || []).reduce((acc, kotId) => {
			const kot = kots[kotId]
			if (!kot) return acc;
			return acc + kot.total;
		}, 0)
		let total = totalAmount - (Number(orderBillData?.amount ?? 0)) +
			(Number(orderBillData?.deliveryCharge ?? 0)) +
			(Number(orderBillData?.containerCharge ?? 0));
		const taxes = restaurantContext.restaurant.taxes || [];
		for (let tax of taxes) {
			total += (tax.value / 100) * (subTotal - (orderBillData?.amount ?? 0));
		}

		setCustomerPaid(Math.floor(total) ?? 0)
	}, [order])

	const handleSave = async () => {
		setIsSettling(true)
		if (waivedOff) {
			try {
				const res = await axios.put(
					`/restaurants/${localStorage.getItem("restaurant")}/orders/${order._id
					}`,
					{
						status: "waivedOff",
						waivedOffReason,
						payment: {
							method: "None",
							customerPaid: 0
						},
					},
					{
						headers: {
							"x-auth-token": localStorage.getItem("token"),
						},
					}
				);

				logger(res.data);
				let orderBillDatas = JSON.parse(localStorage.getItem('orderBillData'));
				delete orderBillDatas?.[order.table.name];
				localStorage.setItem('orderBillData', JSON.stringify(orderBillDatas));
				navigate("/dashboard")
				setIsSettling(false)
				setOpen(false);
				return res;
			} catch (error) {
				setIsSettling(false)
				logger(error);
				toast.error(error.response?.data?.message ?? "Error settling order");
			}
		} else {

			if (!method) {
				setIsSettling(false)

				toast.error("Please select a payment method", {
					autoClose: 2000,
				});
				throw new Error()
			}

			if (method === "Other" && !otherMethod) {
				setIsSettling(false)

				toast.error("Please enter a payment method", {
					autoClose: 2000,
				});
				throw new Error()

			}
			try {
				const res = await axios.put(
					`/restaurants/${localStorage.getItem("restaurant")}/orders/${order._id
					}`,
					{
						status: "settled",
						discount: {
							amount: orderBillData?.amount ?? 0,
							discountRate: orderBillData?.discountRate ?? 0,
							discountRateType: orderBillData?.discountRateType,
							reason: orderBillData?.reason
						},
						deliveryCharge: orderBillData?.deliveryCharge,
						containerCharge: orderBillData?.containerCharge,
						payment: {
							method: method,
							otherMethod: otherMethod,
							transactionNo,
							customerPaid:
								method === "Due"
									? 0
									: method === "Part"
										? partPayments.reduce((a, b) => a + parseInt(b.amount), 0)
										: customerPaid,
							tip,
							parts: method === "Part" ? partPayments : [],
						},
					},
					{
						headers: {
							"x-auth-token": localStorage.getItem("token"),
						},
					}
				);

				logger(res.data);
				toast.success("Order settled successfully", {
					autoClose: 2000,
				});
				let orderBillDatas = JSON.parse(localStorage.getItem('orderBillData'));
				delete orderBillDatas?.[order.table.name];
				localStorage.setItem('orderBillData', JSON.stringify(orderBillDatas));
				navigate("/dashboard")
				setIsSettling(false)

				setOpen(false);
				return res
			} catch (error) {
				setIsSettling(false)

				logger(error);
				toast.error(error.response?.data?.message ?? "Error settling order");
			}
		}
	};

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			sx={{
				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.dialog_wrapper}
			>
				<div>
					<DialogTitle>
						Settle & Save for - {order.table?.name || 1}{" "}
						<GradientText
							gradient="linear-gradient(269.51deg, #008B59 -4.52%, #023C49 100%)"
							text={`₹${total?.toFixed(2)}`}
							style={{
								marginLeft: "8px",
							}}
						/>
					</DialogTitle>
					<div>
						{!waivedOff && <>
							<Typography
								variant="body1"
								component="div"
								color="#824500"
								fontWeight={500}
							>
								Payment Type
							</Typography>
							<RadioGroup
								row
								name="payment-method"
								sx={{
									padding: "8px",
								}}
								value={method}
								onChange={(e) => {
									setMethod(e.target.value);
								}}
							>
								<FormControlLabel value="Cash" control={<Radio />} label="Cash" />
								<FormControlLabel value="Card" control={<Radio />} label="Card" />
								<FormControlLabel value="Due" control={<Radio />} label="Due" />
								<FormControlLabel
									value="Other"
									control={<Radio />}
									label="Other"
								/>
								<FormControlLabel value="Part" control={<Radio />} label="Part" />
							</RadioGroup>
							<div className={styles.inputs}>
								{method === "Other" && (
									<div className={styles.input}>
										<label htmlFor="other_method">Other</label>
										<select
											name="other_method"
											id="other_method"
											value={otherMethod}
											onChange={(e) => {
												setOtherMethod(e.target.value);
											}}
										>
											<option value="UPI">UPI</option>
											<option value="Paytm">Paytm</option>
											<option value="GPay">GPay</option>
											<option value="PhonePe">PhonePe</option>
											<option value="forestfoods">forestfoods</option>
											<option value="Zomato Pay">Zomato Pay</option>
											<option value="Swiggy Pay">Swiggy Pay</option>
										</select>
									</div>
								)}
								{
									(method === "Card" || method === "Other" ||
										partPayments.find((pp) => pp.method === "Card" || pp.method === "Other")) &&
									<div className={styles.input}>
										<label htmlFor="tip">Transaction No</label>
										<input
											type="text"
											name="transactionNo"
											id="transactionNo"
											value={transactionNo}
											onChange={(e) => {
												setTransactionNo(e.target.value);
											}}
										/>
									</div>
								}
								{method === "Part" && (
									<>
										<Typography
											variant="body1"
											component="div"
											color="#824500"
											fontWeight={500}
											sx={{
												marginLeft: "8px",
											}}
										>
											Part Payments
										</Typography>
										{partPayments.map((partPayment, index) => {
											return (
												<div
													style={{
														margin: "0 12px",
														width: "calc(100% - 24px)",
													}}
												>
													<div
														style={{
															padding: "8px 0",
															display: "flex",
															flexDirection: "row",
															justifyContent: "space-between",
														}}
													>
														<label
															htmlFor={"payment-method-" + index.toString()}
															style={{
																fontWeight: 500,
															}}
														>
															Method
														</label>

														<Delete
															color="error"
															fontSize="small"
															onClick={() => {
																const newPartPayments = [...partPayments];
																newPartPayments.splice(index, 1);
																setPartPayments(newPartPayments);
															}}
														/>
													</div>
													<RadioGroup
														row
														name={"payment-method-" + index.toString()}
														sx={{
															padding: "8px",
														}}
														value={partPayment.method}
														onChange={(e) => {
															const newPartPayments = [...partPayments];
															newPartPayments[index].method = e.target.value;
															setPartPayments(newPartPayments);
														}}
													>
														<FormControlLabel
															value="Cash"
															control={<Radio />}
															label="Cash"
														/>
														<FormControlLabel
															value="Card"
															control={<Radio />}
															label="Card"
														/>
														<FormControlLabel
															value="Other"
															control={<Radio />}
															label="Other"
														/>
													</RadioGroup>
													{partPayment.method === "Other" && (
														<div className={styles.input}>
															<label htmlFor={"other_method_" + index.toString()}>
																Other
															</label>
															<select
																name={"other_method_" + index.toString()}
																id={"other_method_" + index.toString()}
																value={partPayment.otherMethod}
																onChange={(e) => {
																	const newPartPayments = [...partPayments];
																	newPartPayments[index].otherMethod =
																		e.target.value;
																	setPartPayments(newPartPayments);
																}}
															>
																<option value="UPI">UPI</option>
																<option value="Paytm">Paytm</option>
																<option value="GPay">GPay</option>
																<option value="PhonePe">PhonePe</option>
																<option value="forestfoods">forestfoods</option>
																<option value="Zomato Pay">Zomato Pay</option>
																<option value="Swiggy Pay">Swiggy Pay</option>
															</select>
														</div>
													)}
													<div key={index} className={styles.input}>
														<label
															htmlFor={"part_payment_amount_" + index.toString()}
														>
															Amount
														</label>
														<input
															type="number"
															name={"part_payment_amount_" + index.toString()}
															id={"part_payment_amount_" + index.toString()}
															value={partPayment.amount}
															onChange={(e) => {
																const newPartPayments = [...partPayments];
																newPartPayments[index].amount = parseInt(
																	e.target.value
																) < 0 ? 0 : parseInt(e.target.value);
																setPartPayments(newPartPayments);
															}}
														/>
													</div>
												</div>
											);
										})}
										<Fab
											variant="extended"
											color="primary"
											onClick={() => {
												const newPartPayments = [...partPayments];
												newPartPayments.push({
													method: "Cash",
													amount: 0,
												});
												setPartPayments(newPartPayments);
											}}
										>
											<Add />
											Add Part Payment
										</Fab>
									</>
								)}
								<div className={styles.input}>
									<label htmlFor="customer_paid">Customer Paid</label>
									<input
										type="number"
										name="customer_paid"
										id="customer_paid"
										value={
											method === "Due"
												? 0
												: method === "Part"
													? partPayments.reduce((a, b) => a + parseInt(b.amount), 0)
													: customerPaid
										}
										disabled={method === "Due" || method === "Part"}
										onChange={(e) => {
											setCustomerPaid(e.target.value < 0 ? 0 : e.target.value);
										}}
									/>
								</div>

								<div className={styles.input}>
									<div
										style={{
											fontWeight: 500,
										}}
									>
										Return to Customer
									</div>
									<Typography
										variant="body1"
										component="div"
										color="#824500"
										fontWeight={500}
									>
										Rs{" "}
										{(Math.max(
											0,
											(method === "Due"
												? 0
												: method === "Part"
													? partPayments.reduce((a, b) => a + parseInt(b.amount), 0)
													: customerPaid) -
											tip -
											total
										))?.toFixed(1)}
									</Typography>
								</div>
								<div className={styles.input}>
									<label htmlFor="tip">Tip</label>
									<input
										type="number"
										name="tip"
										id="tip"
										value={tip}
										onChange={(e) => {
											setTip(e.target.value);
										}}
									/>
								</div>

								<div className={styles.input}>
									<div
										style={{
											fontWeight: 500,
										}}
									>
										Settlement Amount
									</div>
									<div>{total?.toFixed(2)}</div>
								</div>
							</div>
						</>}
					</div>
					<div className={styles.input}>
						<div
							style={{
								fontWeight: 500,
								display: "flex",
								alignItems: "center"
							}}
						>
							Waived Off
							<Checkbox
								checked={waivedOff}
								onChange={(e) => setWaivedOff(e.target.checked)}
								inputProps={{ 'aria-label': 'controlled' }}
							/>
						</div>
					</div>
					{
						waivedOff &&
						<div className={styles.input}>
							<label htmlFor="tip">Reason</label>
							<input
								type="text"
								name="waivedOffReason"
								id="waivedOffReason"
								value={waivedOffReason}
								onChange={(e) => {
									setWaivedOffReason(e.target.value);
								}}
							/>
						</div>
					}
					<DialogActions>
						<button
							className={commonStyles.secondary_button}
							onClick={() => {
								setOpen(false);
							}}
						>
							Cancel
						</button>
						<button
							onClick={() => {
								const amountPaying = (method === "Due"
									? 0
									: method === "Part"
										? partPayments.reduce((a, b) => a + parseInt(b.amount), 0)
										: customerPaid)

								if (amountPaying >= total || waivedOff) {
									handleSave()
								} else {
									setConfirmDialogOpen(true)
								}
							}}
							disabled={isSettling}
							className={commonStyles.primary_button}
						>
							<span style={{ marginRight: "10px" }}>
								Settle & Save
							</span>
							<ClipLoader
								color={"white"}
								loading={isSettling}
								size={10}
							/>
						</button>
					</DialogActions>
				</div>
			</GradientBorder>
			<ConfirmDialog
				open={confirmDialogOpen}
				heading="Are you sure proceed ?"
				text={"Are you certain you want to move on with less money? It will result in revenue leakage."}
				closeDialog={() => setConfirmDialogOpen(false)}
				callback={(isConfirmed) => {
					if (isConfirmed) {
						const callFunction = handleSave();
						toast.promise(callFunction, {
							pending: "Settling...",
							error: "Failed",
							success: "Successfully Settled"
						});
					}
				}}
			/>
		</Dialog>
	);
};

export default SettleDialog;
