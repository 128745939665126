function regexSearch(item, searchTerm) {
    if(!item){
        return false
    }
    if(!searchTerm){
        return true
    }
  var escapedSearchTerm = searchTerm.toString().replace(/[.*+?^${}()|[\]\\]/g, "\\$&").toLowerCase()
  var regex = new RegExp(escapedSearchTerm, "i");
  return item.match(regex)
}

module.exports=regexSearch
