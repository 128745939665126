import React, { useContext, useState } from "react";
import styles from "./Drawer.module.css";
import MuiDrawer from "@mui/material/Drawer";
import logo from "../../assets/images/shakespeare_logo.png";
import GradientText from "../GradientText/GradientText";
import { List, ListItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { orders } from "../../providers/OrderProvider";
import { restaurant } from "../../providers/RestaurantProvider";
import { settings } from "../../providers/SettingsProvider";
import { cart } from "../../providers/CartProvider";
import Settings from "../../pages/Settings/Settings";
import vibrator from "../../utils/vibrator";
import commonStyles from "../../styles.module.css";
import whatsapp from "../../assets/icons/whatsapp.png";
import GradientBorder from "../GradientBorder/GradientBorder";
import { MailRounded } from "@mui/icons-material";
import { kotContext } from "../../providers/KotProvider";
import toast from "../../utils/toast";

const Drawer = ({ open, setIsDrawerOpen }) => {
	const navigate = useNavigate();
	const ordersContext = useContext(orders);
	const { refreshKots } = useContext(kotContext);
	const restaurantContext = useContext(restaurant);
	const settingsContext = useContext(settings);
	const cartContext = useContext(cart);
	const [isSettingsOpen, setIsSettingsOpen] = useState(false);
	return (
		<React.Fragment>
			<MuiDrawer
				variant="temporary"
				anchor="left"
				open={open}
				onClose={() => setIsDrawerOpen(false)}
				sx={{
					"& .MuiModal-backdrop": {
						backdropFilter: "blur(5px)",
					},
				}}
			>
				<div className={styles.drawer_content}>
					<img src={logo} alt="Shakespeare Logo" />
					<GradientText
						gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
						text="MMV"
						style={{ fontSize: "2rem" }}
					/>
					<hr />
					<List className={styles.drawer_list}>
						<ListItem
							onClick={() => {
								vibrator.tap();
								navigate("/dashboard", { replace: true });
								setIsDrawerOpen(false);
							}}
						>
							Dashboard
						</ListItem>
						<ListItem
							onClick={() => {
								vibrator.tap();
								navigate("/live-view");
								setIsDrawerOpen(false);
							}}
						>
							Live View
						</ListItem>
						<ListItem
							onClick={() => {
								vibrator.tap();
								navigate("/waitlist");
								setIsDrawerOpen(false);
							}}
						>
							Waitlist
						</ListItem>
						<ListItem
							onClick={() => {
								vibrator.tap();
								navigate("/order-report");
								setIsDrawerOpen(false);
							}}
						>
							Order Report
						</ListItem>
						<ListItem
							onClick={() => {
								vibrator.tap();
								navigate("/order-summary");
								setIsDrawerOpen(false);
							}}
						>
							Order Summary
						</ListItem>
						<ListItem
							onClick={() => {
								vibrator.tap();
								const callfunction1 = ordersContext.refreshOrders();
								const callfunction2 = refreshKots()
								const callFunction = Promise.all([callfunction1, callfunction2])
								toast.promise(callFunction, {
									pending: "Refreshing Orders...",
									error: "refreshing Orders Failed",
									success: "Orders Refershed Successfully"
								});
								setIsDrawerOpen(false);
							}}
						>
							Refresh Orders
						</ListItem>
						<ListItem
							onClick={() => {
								vibrator.tap();
								localStorage.setItem('orderBillData', JSON.stringify({}))
								setIsDrawerOpen(false);
								toast.success("Discounts cleared")
							}}
						>
							Clear All Discounts
						</ListItem>
						<ListItem
							onClick={() => {
								vibrator.tap();
								setIsSettingsOpen(!isSettingsOpen);
							}}
						>
							Settings
						</ListItem>
						<ListItem
							onClick={() => {
								vibrator.tap();
								navigate("/menu-config/item-listing");
							}}
						>
							Menu Configuration
						</ListItem>
						<ListItem
							onClick={() => {
								vibrator.tap();
								navigate("/printer-config/printer-listing");
							}}
						>
							Printer Configuration
						</ListItem>
						<ListItem
							onClick={() => {
								vibrator.tap();
								navigate("/customer-listing");
							}}
						>
							Customer Listing
						</ListItem>
						<ListItem
							onClick={() => {
								vibrator.tap();
								navigate("/department");
							}}
						>
							Department Listing
						</ListItem>
						<ListItem
							onClick={() => {
								vibrator.tap();
								navigate("/tax-listing");
							}}
						>
							Tax Listing
						</ListItem>
					</List>
					<button
						className={[commonStyles.secondary_button, styles.logout].join(" ")}
						onClick={() => {
							vibrator.tap();
							localStorage.clear();
							sessionStorage.clear();
							restaurantContext.clearRestaurant();
							settingsContext.clearSettings();
							cartContext.clearCart();
							ordersContext.clearOrders();
							navigate("/auth/login", { replace: true });
						}}
					>
						Log Out
					</button>
					<GradientBorder
						gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
						width="1px"
						className={styles.contact_block_wrapper}
					>
						<div className={styles.contact_block}>
							<div
								style={{
									fontSize: "18px",
									color: "#9E5E28",
									marginBottom: "8px",
									textAlign: "center",
								}}
							>
								<u>Help Line</u>
							</div>
							<div className={styles.contact_item}>
								<img src={whatsapp} alt="Whatsapp" />
								<a
									href={`https://wa.me/919849518781?text=Restaurant ID: ${localStorage.getItem(
										"restaurant"
									)}%0ARole: CASHIER%0AUser Agent: ${navigator.userAgent
										}%0A------------%0AI have a query regarding:%0A`}
									target="_blank"
									rel="noreferrer"
								>
									(91) 98495 18781
								</a>
							</div>
							<div className={styles.contact_item}>
								<MailRounded />
								<a
									href={`mailto:support@shakespeare.vision?subject=CASHIER Query&body=Restaurant ID: ${localStorage.getItem(
										"restaurant"
									)}%0ARole: CASHIER%0AUser Agent: ${navigator.userAgent
										}%0A------------%0AI have a query regarding:%0A`}
									rel="noreferrer"
								>
									support@shakespeare.vision
								</a>
							</div>
						</div>
					</GradientBorder>
				</div>
				<footer className={styles.drawer_footer}>
					<a href="https://shakespeare.vision" target="_blank" rel="noreferrer">
						www.shakespeare.vision
					</a>
				</footer>
				<footer className={styles.drawer_footer}>
					<span>
						v 0.1.41
					</span>
				</footer>
			</MuiDrawer>
			<Settings
				isOpen={isSettingsOpen}
				setIsOpen={(val) => {
					setIsSettingsOpen(val);
					setIsDrawerOpen(false);
				}}
			/>
		</React.Fragment>
	);
};

export default Drawer;