import React, { useContext } from "react";
import {
    Drawer,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import styles from "./DepartmentDetails.module.css";
import Header from "../../components/Header/Header";
import { ChevronLeftRounded } from "@mui/icons-material";
import { restaurant } from "../../providers/RestaurantProvider";

const DepartmentDetails = ({ department = {}, open, closeDrawer }) => {
    const {items:menuItems} = useContext(restaurant)

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={() => closeDrawer()}
            sx={{
                "& .MuiDrawer-paper": {
                    width: "75%",
                    maxWidth: "100%",
                },
            }}
        >
            <div className={styles.body}>
                <Header
                    title="Department Details"
                    leading={
                        <ChevronLeftRounded
                            onClick={() => closeDrawer()}
                            fontSize="large"
                            htmlColor="white"
                        />
                    }
                />
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow
                                sx={{
                                    backgroundColor: "#FEF2E2",
                                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

                                    "& .MuiTableCell-root": {
                                        fontWeight: "bold",
                                        color: "#9E5E28",
                                        backgroundColor: "#FEF2E2",
                                    },
                                }}
                            >
                                <TableCell>Department ID</TableCell>
                                <TableCell>Department Name</TableCell>
                                <TableCell>Total Staffs</TableCell>
                                <TableCell>Total Menu Items</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow className={styles[department.status]}>
                                <TableCell>{department._id}</TableCell>
                                <TableCell>{department.name}</TableCell>
                                <TableCell>{department?.staff?.length}</TableCell>
                                <TableCell>{department?.items?.length}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography
                    variant="h6"
                    sx={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        marginLeft: "10px",
                    }}
                    color="#814500"
                >
                    Staffs
                </Typography>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow
                                sx={{
                                    backgroundColor: "#FEF2E2",
                                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

                                    "& .MuiTableCell-root": {
                                        fontWeight: "bold",
                                        color: "#9E5E28",
                                        backgroundColor: "#FEF2E2",
                                    },
                                }}
                            >
                                <TableCell>Staff Name</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {department?.staffs?.map((id) =>(
                                    <TableRow>
                                        <TableCell>
                                           {""}
                                        </TableCell>
                                    </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography
                    variant="h6"
                    sx={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        marginLeft: "10px",
                    }}
                    color="#814500"
                >
                    Menu Items
                </Typography>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow
                                sx={{
                                    backgroundColor: "#FEF2E2",
                                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

                                    "& .MuiTableCell-root": {
                                        fontWeight: "bold",
                                        color: "#9E5E28",
                                        backgroundColor: "#FEF2E2",
                                    },
                                }}
                            >
                                <TableCell>Item Name</TableCell>
                                <TableCell>Veg</TableCell>
                                <TableCell>Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {department?.items?.map((id) =>(
                                    <TableRow>
                                        <TableCell>
                                            {menuItems[id].name}
                                        </TableCell>
                                        <TableCell>
                                            {menuItems[id].isVeg ? "Yes" : "No"}
                                        </TableCell>
                                        <TableCell>
                                            {menuItems[id].price}
                                        </TableCell>
                                    </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Drawer>
    );
};

export default DepartmentDetails;
