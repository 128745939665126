import { CircularProgress, Drawer, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../../components/Header/Header";
import styles from "./OrderDetails.module.css";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import { orders } from "../../../providers/OrderProvider";
import vibrator from "../../../utils/vibrator";
import { Alarm, ArrowBackIosNewRounded } from "@mui/icons-material";
import commonStyles from "../../../styles.module.css";
import KOTCardEdit from "./KOTCardEdit";
import SettlementEdit from "./SettlementEdit/SettlementEdit";

const OrderDetailsEdit = ({ isOpen, setIsOpen, order: myOrder, refreshOrder }) => {
	const params = useParams();
	const [loading, setLoading] = useState(false);


	const [isCompleting, setIsCompleting] = useState(false);
	const [isSettleDialogOpen, setIsSettleDialogOpen] = useState(false);

	const navigate = useNavigate();
	const [order, setOrder] = useState({})
	useEffect(() => {
		setOrder({ ...myOrder })
	}, [myOrder])

	return (
		<Drawer
			anchor="bottom"
			open={isOpen}
			onClose={() => setIsOpen(false)}
			sx={{
				"& .MuiDrawer-paper": {
					backgroundColor: "#FEFAF3",
					height: "100%",
					width: "calc(100% - 20px)",
					margin: "0 10px",
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<Header title="KOT(s)"
				leading={
					<IconButton
						size="large"
						edge="start"
						color="inherit"
						aria-label="menu"
						sx={{ mr: 2 }}
						onClick={() => {
							setIsOpen(false);
						}}
					>
						<ArrowBackIosNewRounded />
					</IconButton>
				}
			/>
			<div className={styles.body}>
				<Typography
					variant="h4"
					component="div"
					color="#824500"
					fontWeight={400}
					sx={{
						paddingTop: "20px",
						marginBottom: "10px",
						marginLeft: "16px",
					}}
				>
					Table No : {order?.table?.name || 1}
				</Typography>

				<div className={styles.legend}>
					<div className={styles.legend_item}>
						<span
							style={{
								background: "linear-gradient(#06DCCF, #389E7A)",
							}}
						/>
						<Typography
							variant="caption"
							component="div"
							sx={{
								marginLeft: "4px",
								color: "#C2A186",
							}}
						>
							User
						</Typography>
					</div>
					<div className={styles.legend_item}>
						<span
							style={{
								background: "linear-gradient(#7E06DC, #63B4FF)",
							}}
						/>
						<Typography
							variant="caption"
							component="div"
							sx={{
								marginLeft: "4px",
								color: "#C2A186",
							}}
						>
							Captain
						</Typography>
					</div>
					<div className={styles.legend_item}>
						<span
							style={{
								background:
									"linear-gradient(270.69deg, rgba(255, 168, 168, 0.77) -6.58%, #ED0000 153.04%)",
							}}
						/>
						<Typography
							variant="caption"
							component="div"
							sx={{
								marginLeft: "4px",
								color: "#C2A186",
							}}
						>
							Overdue
						</Typography>
					</div>
				</div>

				{(order?.kots ?? []).map((kot, index) => {
					return <KOTCardEdit
						refreshOrder={refreshOrder}
						setIsOpen={setIsOpen}
						index={index}
						kot={kot}
					></KOTCardEdit>
				})}
				<div className={styles.cta_row}>
					<div
						className={commonStyles.secondary_button}
						onClick={() => {
							vibrator.tap();
							setIsOpen(false)
						}}
					>
						Back
					</div>


				</div>
				{
					order?.billSettledBy &&
					<SettlementEdit setIsOpen={setIsOpen} order={myOrder} refreshOrder={refreshOrder} />
				}

			</div>
		</Drawer>
	);
};

export default OrderDetailsEdit;
