import axios from "axios";
import React, { useState, useContext } from "react";
import logger from "../../utils/logger";
import toast from "../../utils/toast";
import Header from "../../components/Header/Header";
import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { CheckRounded, Delete, QrCode2Rounded } from "@mui/icons-material";
import QRDialog from "./QRDialog";
import { waitlist } from "../../providers/WaitlistProvider";
import AddFAB from "./AddFAB";

const Waitlist = () => {
	const [showQRDialog, setShowQRDialog] = useState(false);
	const waitlistContext = useContext(waitlist);

	const getDuration = (time) => {
		const now = new Date();
		time = new Date(time);
		const diff = now - time;
		const minutes = Math.floor(diff / 60000);
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);
		if (days > 0) {
			return `${days} day${days > 1 ? "s" : ""}`;
		} else if (hours > 0) {
			return `${hours} hour${hours > 1 ? "s" : ""}`;
		} else {
			return `${minutes} minute${minutes > 1 ? "s" : ""}`;
		}
	};

	const handleStatusChange = async (id, status) => {
		try {
			const res = await axios.put(
				`/restaurants/${localStorage.getItem("restaurant")}/waitlist/${id}`,
				{
					status: status,
				},
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			logger(res.data);

			return res;
		} catch (err) {
			logger(err);
			toast.error(
				err.response?.data?.message ?? "Failed to update the waitlist"
			);
		}
	};

	return (
		<div>
			<Header
				title="Current Waitlist"
				trailing={
					<IconButton
						onClick={() => {
							setShowQRDialog(true);
						}}
					>
						<QrCode2Rounded htmlColor="white" />
					</IconButton>
				}
			/>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow
							sx={{
								backgroundColor: "#FEF2E2",
								boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

								"& .MuiTableCell-root": {
									fontWeight: "bold",
									color: "#9E5E28",
								},
							}}
						>
							<TableCell>Name</TableCell>
							<TableCell>Phone</TableCell>
							<TableCell>Party Size</TableCell>
							<TableCell>Waiting Since</TableCell>
							<TableCell>Status</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(waitlistContext.waitlist || [])
							.sort((a, b) => {
								if (a.status === "waiting" && b.status !== "waiting") {
									return -1;
								} else if (a.status !== "waiting" && b.status === "waiting") {
									return 1;
								} else {
									return a.createdAt - b.createdAt;
								}
							})
							.map((waitlist, index) => (
								<TableRow
									sx={{
										backgroundColor:
											waitlist.status === "cancelled"
												? "lightgrey"
												: waitlist.status === "seated"
												? "#4cd964"
												: "",
									}}
								>
									<TableCell>{waitlist.name}</TableCell>
									<TableCell>{waitlist.phone}</TableCell>
									<TableCell>{waitlist.personCount}</TableCell>
									<TableCell>{getDuration(waitlist.createdAt)}</TableCell>
									<TableCell>{waitlist.status}</TableCell>
									<TableCell>
										{waitlist.status === "waiting" && (
											<Delete
												onClick={() => {
													const callFunction=handleStatusChange(waitlist._id, "cancelled");
													toast.promise(callFunction, {
														pending: "Cancelling...",
														error: "Failed",
														success: "Successfully Cancelled"
													});
												}}
											/>
										)}
										{waitlist.status === "waiting" && (
											<CheckRounded
												onClick={() => {
													const callFunction=handleStatusChange(waitlist._id, "seated");
													toast.promise(callFunction, {
														pending: "Seating...",
														error: "Failed",
														success: "Successfully Seated"
													});
												}}
											/>
										)}
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			<QRDialog isOpen={showQRDialog} setIsOpen={setShowQRDialog} />
			<AddFAB />
		</div>
	);
};

export default Waitlist;
