import {
	Alarm,
	PrintRounded,
	SaveRounded,
	Circle,
	VisibilityRounded,
	RecordVoiceOverRounded,
} from "@mui/icons-material";
import { Badge, CircularProgress, Typography } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import styles from "./TableCell.module.css";
import commonStyles from '../../styles.module.css'
import SettleDialog from "../../components/SettleDialog/SettleDialog";
import { settings } from "../../providers/SettingsProvider";
import { cart } from "../../providers/CartProvider";
import toast from "../../utils/toast";
import vibrator from "../../utils/vibrator";
import MoveTableDialog from "../../components/MoveTableDialog/MoveTableDialog";
import logger from "../../utils/logger";
import { reservation } from "../../providers/ReservationProvider";
import { kotContext } from "../../providers/KotProvider";
import { printer } from "../../providers/PrintProvider";
import { ClipLoader } from "react-spinners";
import { restaurant } from "../../providers/RestaurantProvider";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import usePrinter from "../../hooks/usePrinter";
import { flushSync } from 'react-dom';


const TableCell = ({
	order,
	table,
	justForDisplay,
	callback,
	moveTableSelected,
	setMoveTableSelected,
	onPrint,
}) => {
	const navigate = useNavigate();
	const settingsContext = useContext(settings);
	const cartContext = useContext(cart);
	const reservationContext = useContext(reservation);
	const { kots } = useContext(kotContext)
	const { orderToPrint, printers, setOrderToPrint } = useContext(printer)
	const [billConfirmDialogOpen, setBillConfirmDialogOpen] = useState(false)


	const restaurantContext = useContext(restaurant)

	const totalTax = restaurantContext.restaurant.taxes.reduce(
		(acc, item) => {
			return acc + item.value;
		},
		0
	);
	const orderTotal = (order.kots?.reduce((acc, kotId) => {
		const kot = kots[kotId];
		if (!kot) return acc;
		return kot.total + acc;
	}, 0) ?? 0);

	const totalTaxValue = (totalTax / 100)
	const orderTotalWithTax = orderTotal * (1 + totalTaxValue)



	const showRunning =
		(settingsContext.settings.tableFilters || {}).running === undefined ||
		(settingsContext.settings.tableFilters || {}).running;
	const showEmpty =
		(settingsContext.settings.tableFilters || {}).empty === undefined ||
		(settingsContext.settings.tableFilters || {}).empty;
	let orderBillData = JSON.parse(localStorage.getItem("orderBillData"));
	let overdueAlerted =
		Object.keys(order).length > 0 &&
		order.kots.find((kotId) => {
			const kot = kots[kotId]
			if (!kot) return false;
			if (kot.completed) {
				return false;
			}

			const now = Date.now();
			const kotTime = Date.parse(kot.createdAt);

			const diff = now - kotTime;

			const minutes = Math.max(0, Math.floor(diff / 1000 / 60));

			if (minutes >= 2) {
				return true;
			} else {
				return false;
			}
		});

	const getDuration = () => {
		const utcNow = new Date().getTime();
		const utcOrder = new Date(order.createdAt).getTime();
		const diff = utcNow - utcOrder;
		const minutes = Math.max(0, Math.floor(diff / 1000 / 60));
		const hours = Math.floor(minutes / 60);

		if (!minutes) {
			return "Just Updated";
		}

		if (minutes === 0) {
			return "Just Started";
		}
		if (hours > 0) {
			return `${hours}h:${minutes % 60}m`;
		} else {
			return `${minutes}m`;
		}
	};

	const checkIfOverdue = () => {
		if (
			!order.kots ||
			order.kots.length === 0 ||
			order.status === "completed"
		) {
			return false;
		}

		for (let kotId of order.kots) {
			const kot = kots[kotId]
			if (!kot) continue;
			if (kot.completed) {
				continue;
			}

			const now = Date.now();
			const kotTime = Date.parse(kot.createdAt);

			const diff = now - kotTime;

			const minutes = Math.max(0, Math.floor(diff / 1000 / 60));

			if (minutes >= 25) {
				if (!overdueAlerted) {
					overdueAlerted = true;
					toast.warning(`A Kot at Table ${table.name} is overdue!`, {
						onClick: () => {
							navigate(`/order-details/${order._id}`);
						},
					});
					vibrator.longTap();
				}

				return true;
			}
		}

		return false;
	};

	const checkIfReserved = () => {
		if (
			!reservationContext.reservations ||
			!reservationContext.reservations[table._id]
		) {
			return false;
		}

		const now = Date.now();

		for (let reservation of reservationContext.reservations[table._id]) {
			const reservationTime = Date.parse(reservation.time);
			const blockTime = Date.parse(reservation.blockTime);

			logger(
				"Reservation",
				reservationTime,
				blockTime,
				now,
				reservationContext.reservations[table._id]
			);

			if (now >= blockTime && now <= reservationTime + 10 * 60 * 1000) {
				return true;
			}
		}

		return false;
	};

	const [time, setTime] = useState(getDuration());
	const [isOverdue, setIsOverdue] = useState(checkIfOverdue());
	const [moveDialogOpen, setMoveDialogOpen] = useState(false);
	const [isReserved, setIsReserved] = useState(checkIfReserved());
	const [isPrinting, setIsPrinting] = useState(false)

	useEffect(() => {
		if (!order._id) {
			setIsOverdue(false);
			setIsReserved(false);
			return;
		}
		const timer = setInterval(() => {
			setTime(getDuration());
			setIsOverdue(checkIfOverdue());
			setIsReserved(checkIfReserved());
		}, 60000);

		return () => {
			clearInterval(timer);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order._id]);
	useEffect(() => {
		setIsOverdue(checkIfOverdue());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order._id]);

	const { printBill } = usePrinter()

	const handleClick = () => {
		vibrator.tap();
		if (justForDisplay) {
			callback(order, table);
		} else if (moveTableSelected) {
			if (!order._id) {
				toast.info(`Table ${table.name} is not running!`);
				return;
			}
			setMoveDialogOpen(true);
		} else {
			if (cartContext.selectedTable !== table) {
				cartContext.clearCart();
			}
			cartContext.setSelectedTable(table);
			navigate("/order-building", {
				state: {
					table: table,
					order: order,
				},
			});
		}
	};

	const isNew = order._id && order.status !== "cancelled" ? true : false;
	const isRunning = order._id && order.status !== "cancelled" ? true : false;
	const status = order.status;

	const [isDialogOpen, setIsDialogOpen] = useState(false);



	return (
		<>
			<span
				style={{
					display:
						(isRunning && !showRunning) || (!isRunning && !showEmpty)
							? "none"
							: "block",
				}}
			>
				<div
					style={{
						position: "relative",
					}}
				>
					<Badge
						badgeContent={
							order.isWaiterCalled ? (
								<RecordVoiceOverRounded
									htmlColor="white"
									sx={{
										backgroundColor: "#f44336",
										borderRadius: "50%",
										padding: "4px",
										fontSize: "15px",
									}}
								/>
							) : null
						}
						sx={{
							"& .MuiBadge-badge": {
								right: "100px",
								top: "8px",
								zIndex: 30,
							},
						}}
					>
						<GradientBorder
							gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
							width="1px"
							className={styles.table_cell_wrapper}
						>
							<div
								className={
									styles.table_cell +
									(isOverdue ? ` ${styles.overdue}` : "") +
									(isReserved ? ` ${styles.reserved}` : "") +
									(" " + styles[order.status])
								}
								onClick={handleClick}
							>
								<div className={styles.icon_row}>
									<Alarm
										htmlColor={"transparent"}
										sx={{
											fontSize: "14px",
										}}
									/>
									{/* <Typography
										variant="caption"
										component="div"
										sx={{
											fontSize: "0.6rem",
										}}
										color={order.new ? "#C2A186" : "transparent"}
									>
										New
									</Typography> */}
									<Circle
										sx={{
											fontSize: "14px",
										}}
										htmlColor="transparent"
									/>
								</div>
								<div>
									<Typography
										variant="caption"
										sx={{
											fontSize: "0.7rem",
										}}
										component="div"
										color={isRunning || isReserved ? "inherit" : "transparent"}
									>
										{isReserved ? "Reserved!" : time}
									</Typography>
									<Typography
										variant="h4"
										component="div"
										fontWeight={500}
										lineHeight={0.9}
										align="center"
									>
										{table.name}

									</Typography>
									<Typography
										variant="caption"
										sx={{
											fontSize: "0.7rem",
										}}
										component="div"
										color={isRunning ? "824500" : "transparent"}
										align="center"
									>
										₹{" "}
										{Math.floor(orderTotalWithTax)}
									</Typography>
								</div>
							</div>
						</GradientBorder>
					</Badge>
					{
						order?.isNew ?
							<div className={styles.cta2_row}>
								<div
									className={styles.cta2}
									onClick={() => {
										vibrator.tap();
										if (status === "running") {
											setBillConfirmDialogOpen(true)
										} else {
											setIsDialogOpen(true);
										}
									}}
								>
									NEW

								</div>

							</div>
							:
							<div></div>
					}

					{isRunning && (
						<div className={styles.cta_row}>
							<div
								className={styles.cta}
								onClick={() => {
									vibrator.tap();
									if (status === "running") {
										if (!orderToPrint?._id) {
											setBillConfirmDialogOpen(true)
										}
									} else {
										setIsDialogOpen(true);
									}
								}}
							>
								{status === "running" ? (
									orderToPrint?._id === order._id ? (
										<ClipLoader
											color={"white"}
											loading={true}
											size={10}
										/>
									) :
										<PrintRounded className={commonStyles.animate_btn} fontSize="small" />
								) : (
									<SaveRounded className={commonStyles.animate_btn} fontSize="small" />
								)}
							</div>
							{status === "running" && (
								<div
									className={styles.cta}
									onClick={() => {
										vibrator.tap();
										navigate(`/order-details/${order._id}`);
									}}
								>
									<VisibilityRounded fontSize="small" />
								</div>
							)}
						</div>
					)}
				</div>
			</span>
			<SettleDialog
				orderBillData={orderBillData?.[table.name]}
				open={isDialogOpen}
				setOpen={setIsDialogOpen}
				order={order}
			/>
			<MoveTableDialog
				setMoveTableSelected={setMoveTableSelected}
				open={moveDialogOpen}
				setOpen={setMoveDialogOpen}
				order={order}
			/>
			<ConfirmDialog
				open={billConfirmDialogOpen}
				heading="Are you sure proceed ?"
				text={"Are you sure Bill and Print the order"}
				closeDialog={() => flushSync(() => {
					setBillConfirmDialogOpen(false)
				})}
				callback={(isConfirmed) => {
					flushSync(() => {
						setBillConfirmDialogOpen(false)
					});
					if (isConfirmed) {
						toast.promise(printBill(order), {
							pending: "Printing Order...",
							error: "No printer found",
							success: "Added to print Queue"
						})
					}
				}}
			/>
		</>
	);
};

const AddTableCell = ({ callback }) => {
	const handleClick = () => {
		vibrator.tap();
		callback();
	};

	return (
		<div className={styles.add_table_cell}>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.table_cell_wrapper}
			>
				<div
					className={styles.table_cell}
					onClick={handleClick}
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<div
						style={{
							background:
								"linear-gradient(137.78deg, rgba(255, 139, 22, 0.7) 3.79%, rgba(139, 70, 0, 0.7) 95.3%)",
							boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
							borderRadius: "50%",
							width: "40px",
							height: "40px",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							fontSize: "36px",
							color: "#fff",
							fontWeight: 300,
						}}
					>
						+
					</div>
					<div>
						<Typography
							variant="caption"
							component="div"
							fontWeight={400}
							lineHeight={0.9}
							align="center"
							color="#C2781D"
							sx={{
								textShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
								marginTop: "10px",
							}}
						>
							New Table
						</Typography>
					</div>
				</div>
			</GradientBorder>
		</div>
	);
};

export { TableCell as default, AddTableCell };
