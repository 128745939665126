import {
	Checkbox,
	Dialog,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
} from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import styles from "./AddFAB.module.css";
import commonStyles from "../../../styles.module.css";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import vibrator from "../../../utils/vibrator";
import toast from "../../../utils/toast";
import axios from "axios";
import logger from "../../../utils/logger";
import { printer as printerCtx } from "../../../providers/PrintProvider";

const EditPrinterDialog = ({ isOpen, closeDialog, printerDetails }) => {
	const [printer, setPrinter] = useState({});
	const printerContext = useContext(printerCtx);
	const [searchQuery, setSearchQuery] = useState("");

	const handleToggle = (value) => () => {
		const currentIndex = printer.departments.indexOf(value);
		const newChecked = [...printer.departments];
		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}
		setPrinter({ ...printer, departments: newChecked });
	};

	useEffect(() => {
		if (printerDetails.departments) {
			const departmentList = printerDetails.departments.map((item) => item._id);
			setPrinter({ ...printerDetails, departments: departmentList });
		}
	}, [printerDetails]);

	const handleSubmit = async () => {
		vibrator.tap();
		try {
			if (!printer.name || !printer.type) {
				toast.error("Please fill all the fields");
				return;
			}

			if (printer.type === "network") {
				if (!printer.ip || !printer.port) {
					toast.error("Please fill all the fields");
					return;
				}
			} else {
				if (!printer.deviceName) {
					toast.error("Please fill all the fields");
					return;
				}
			}
			logger(printer);

			const res = await axios.put(
				`restaurants/${localStorage.getItem("restaurant")}/printers/${
					printer._id
				}`,
				printer,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			logger(res.data);
			printerContext.refreshPrinters(false);

			toast.success("printer Updated");
			closeDialog();
		} catch (err) {
			logger(err);
			toast.error(err.response?.data?.message ?? "Updating printer failed");
		}
	};

	return (
		<Dialog
			open={isOpen}
			onClose={() => closeDialog()}
			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.dialog_wrapper}
			>
				<div>
					<Typography
						variant="body1"
						component="div"
						color="#9E5E28"
						sx={{
							marginBottom: "12px",
						}}
					>
						Add Printer
					</Typography>
					<div className={styles.dialog_input}>
						<Typography variant="body2" component="div" color="#9E5E28">
							Name
						</Typography>
						<GradientBorder
							gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
							width="1px"
							className={styles.input_wrapper}
						>
							<input
								type="text"
								value={printer.name}
								onChange={(e) => {
									setPrinter({ ...printer, name: e.target.value });
								}}
							/>
						</GradientBorder>
					</div>
					<div className={styles.dialog_input}>
						<Typography variant="body2" component="div" color="#9E5E28">
							Type
						</Typography>
						<GradientBorder
							gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
							width="1px"
							className={styles.input_wrapper}
						>
							<select
								value={printer.type ?? ""}
								onChange={(e) => {
									setPrinter({ ...printer, type: e.target.value });
								}}
							>
								<option value="" disabled>
									{" "}
									-- Select --{" "}
								</option>
								<option value="usb">USB</option>
								<option value="network">Network</option>
							</select>
						</GradientBorder>
					</div>
					{printer.type === "usb" && (
						<div className={styles.dialog_input}>
							<Typography variant="body2" component="div" color="#9E5E28">
								Device Name
							</Typography>
							<GradientBorder
								gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
								width="1px"
								className={styles.input_wrapper}
							>
								<input
									type="text"
									value={printer.deviceName}
									onChange={(e) => {
										setPrinter({
											...printer,
											deviceName: e.target.value,
										});
									}}
								/>
							</GradientBorder>
						</div>
					)}
					{printer.type === "network" && (
						<div className={styles.dialog_input}>
							<Typography variant="body2" component="div" color="#9E5E28">
								Printer IP
							</Typography>
							<GradientBorder
								gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
								width="1px"
								className={styles.input_wrapper}
							>
								<input
									type="text"
									maxLength={15}
									value={printer.ip}
									onChange={(e) => {
										setPrinter({
											...printer,
											ip: e.target.value,
										});
									}}
								/>
							</GradientBorder>
						</div>
					)}
					{printer.type === "network" && (
						<div className={styles.dialog_input}>
							<Typography variant="body2" component="div" color="#9E5E28">
								Printer Port
							</Typography>
							<GradientBorder
								gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
								width="1px"
								className={styles.input_wrapper}
							>
								<input
									type="number"
									value={printer.port}
									onChange={(e) => {
										setPrinter({
											...printer,
											port: e.target.value,
										});
									}}
								/>
							</GradientBorder>
						</div>
					)}
					<div className={styles.dialog_input}>
						<Typography variant="body2" component="div" color="#9E5E28">
							Deparments
						</Typography>
						<GradientBorder
							gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
							width="1px"
							className={styles.input_wrapper}
						>
							<input
								type="text"
								value={searchQuery}
								placeholder={"search department"}
								onChange={(e) => setSearchQuery(e.target.value)}
							/>
						</GradientBorder>
					</div>
					<List
						sx={{
							width: "100%",
							maxWidth: 360,
							bgcolor: "background.paper",
							maxHeight: "300px",
							overflowY: "scroll",
						}}

					>
						{printerContext?.departments &&
							printerContext.departments
								// .filter((item) => item.name.match(new RegExp(searchQuery, "i")))
								.map((item) => {
									const labelId = `checkbox-list-label-${item._id}`;

									return (
										<ListItem key={item._id} disablePadding>
											<ListItemButton
												role={undefined}
												onClick={handleToggle(item._id)}
												dense
											>
												<ListItemIcon>
													<Checkbox
														sx={{ minWidth: "40px" }}
														edge="start"
														checked={
															printer?.departments?.indexOf(item._id) !== -1
														}
														tabIndex={-1}
														disableRipple
														inputProps={{ "aria-labelledby": labelId }}
													/>
												</ListItemIcon>
												<ListItemText id={labelId} primary={item.name} />
											</ListItemButton>
										</ListItem>
									);
								})}
					</List>
					<div className={styles.dialog_button_row}>
						<button
							className={commonStyles.secondary_button}
							onClick={() => {
								vibrator.tap();
								closeDialog();
							}}
						>
							Cancel
						</button>
						<button
							className={commonStyles.primary_button}
							onClick={handleSubmit}
						>
							Update
						</button>
					</div>
				</div>
			</GradientBorder>
		</Dialog>
	);
};

export default EditPrinterDialog;
