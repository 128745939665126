import React from "react";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import vibrator from "../../../utils/vibrator";
import styles from "./SubCategoryCard.module.css";

const SubCategoryCard = ({ category, onClick }) => {
	return (
		<GradientBorder
			gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
			width="1px"
			className={styles.card_wrapper}
		>
			<div
				onClick={() => {
					vibrator.tap();
					onClick();
				}}
			>
				{category.name}
			</div>
		</GradientBorder>
	);
};

export default SubCategoryCard;
