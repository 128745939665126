import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import styles from "./Dashboard.module.css";
import { restaurant } from "../../providers/RestaurantProvider";
import { orders } from "../../providers/OrderProvider";
import { settings } from "../../providers/SettingsProvider";
import { IconButton, Typography } from "@mui/material";
import axios from "axios";
import TableCell, { AddTableCell } from "./TableCell";
import { FilterListRounded } from "@mui/icons-material";
import FilterMenu from "./FilterMenu";
import vibrator from "../../utils/vibrator";
import logger from "../../utils/logger";
import commonStyles from "../../styles.module.css";
import toast from "../../utils/toast";
import AddTableDialog from "./AddTableDialog";
import { kotContext } from "../../providers/KotProvider";
import { printer } from "../../providers/PrintProvider";
import diningTableAnimation from "../../assets/lotties/dining-table.json";
import Lottie from "lottie-react";
import GradientText from "../../components/GradientText/GradientText";

const Dashboard = ({ justForDisplay = false, callback = () => { } }) => {
	const restaurantContext = useContext(restaurant);
	const ordersContext = useContext(orders);
	const settingsContext = useContext(settings);
	const [anchorEl, setAnchorEl] = useState(null);
	const [moveTableSelected, setMoveTableSelected] = useState(false);
	const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
	const [addDialogSection, setAddDialogSection] = useState("");
	const { setKots, kots } = useContext(kotContext);
	const [loading, setLoading] = useState(true)


	const fetchOrders = async () => {
		try {
			const res = await axios.get(
				`/restaurants/${localStorage.getItem("restaurant")}/orders/running`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			let kotData = {};
			res.data.kots.forEach((kot) => {
				kotData[kot._id] = kot;
			});
			console.log(kotData, "kotData")
			setKots(kotData);

			let orderTableWise = {};

			res.data.orders.forEach((order) => {
				if (!order.table) {
					return;
				}
				orderTableWise[order.table._id] = order;
			});

			ordersContext.setOrders(orderTableWise);
			setLoading(false)
			return res;
		} catch (error) {
			setLoading(false)
			logger(error);
		}
	};

	const handleAddTemporaryTable = async (tableName, sectionName) => {
		try {
			const res = await axios.post(
				`/restaurants/${localStorage.getItem("restaurant")}/tables`,
				{
					name: tableName,
					section: sectionName,
					isTemporary: true,
				},
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);
			logger(res.data);
			toast.success("Temporary Table added");
		} catch (error) {
			logger(error);
			toast.error(error.response?.data?.message ?? "Error adding table");
		} finally {
			setIsAddDialogOpen(false);
		}
	};

	useEffect(() => {
		logger("Use Effect");

		if (!restaurantContext.restaurant) {
			logger("No restaurant");
			setLoading(false)
			return;
		}
		if (!ordersContext.orders || !kots) {
			const callFunction = fetchOrders();
			toast.promise(callFunction, {
				pending: "Fetching Orders...",
				error: "Fetching Order Failed",
				success: "Order fetched Successfully"
			});
		} else {
			setLoading(false)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [restaurantContext.restaurant]);
	// useEffect(() => {
	// 	let billPrinterAvailable = false, kotPrinterAvailable = false;
	// 	(JSON.parse(localStorage.getItem('printers')) ?? []).forEach((printer) => {
	// 		if (printer.inUse) {
	// 			if (printer.shouldPrintKOT) {
	// 				kotPrinterAvailable = true
	// 			}
	// 			if (printer.shouldPrintOrder) {
	// 				billPrinterAvailable = true
	// 			}
	// 		}
	// 	})
	// 	console.log(JSON.parse(localStorage.getItem('printers')), "printer", billPrinterAvailable, kotPrinterAvailable);
	// 	if (!billPrinterAvailable) {
	// 		toast.error("Bill Printer is Not Available Please Check")
	// 	}
	// 	if (!kotPrinterAvailable) {
	// 		toast.error("KOT Printer is Not Available Please Check")
	// 	}
	// }, [])

	const buildTableGrid = () => {
		if (!ordersContext.orders) return <></>;

		return (
			<>
				{!loading && restaurantContext?.restaurant?.sections?.map((section) => {
					let sectionVisibility = (settingsContext.settings.sectionVisibility ||
						{})[section.name];
					if (sectionVisibility === undefined) {
						sectionVisibility = true;
					}

					return (
						<div
							key={section.name}
							style={{
								display: sectionVisibility ? "block" : "none",
								margin: "20px",
								marginTop: "0px",
							}}
						>
							<div className={styles.section_header}>
								<Typography
									variant="h6"
									fontWeight={300}
									component="div"
									sx={{
										color: "#824500",
									}}
								>
									{section.name}
								</Typography>
								<button
									className={commonStyles.primary_button}
									onClick={() => {
										setIsAddDialogOpen(true);
										setAddDialogSection(section.name);
									}}
								>
									Add Temporary Table
								</button>
							</div>
							<div className={styles.table_grid}>
								{section.tables.map((table) => {
									return (
										<TableCell
											key={table._id}
											// key={ctr}
											order={ordersContext.orders[table._id] || {}}
											table={table}
											justForDisplay={justForDisplay}
											moveTableSelected={moveTableSelected}
											setMoveTableSelected={setMoveTableSelected}
											callback={callback}
											onPrint={(order) => {

											}}
										/>
									);
								})}
								<AddTableCell
									callback={() => {
										setIsAddDialogOpen(true);
										setAddDialogSection(section.name);
									}}
								/>
							</div>
						</div>
					);
				})}
				{
					(loading) &&
					<div
						style={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<GradientText
							gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
							style={{ textAlign: "center", width: "100%", fontSize: "30px" }}
							text={"Loading..., Please wait!"}
						/>
						<Lottie
							animationData={diningTableAnimation}
							style={{
								width: "50%",
								minWidth: "300px",
								marginTop: "20px",
							}}
						/>
					</div>
				}
			</>
		);
	};

	return (
		<div
			style={{
				backgroundColor: "#FEFAF3",
			}}
		>
			{!justForDisplay && (
				<Header
					trailing={
						<IconButton
							aria-label="filter"
							onClick={(e) => {
								vibrator.tap();
								setAnchorEl(e.currentTarget);
							}}
						>
							<FilterListRounded htmlColor="white" />
						</IconButton>
					}
				/>
			)}
			{!justForDisplay && (
				<div className={styles.header_row}>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<Typography
							variant="h5"
							component="div"
							sx={{
								color: "#824500",
								width: "250px",
								textOverflow: "ellipsis",
								overflow: "hidden",
								whiteSpace: "nowrap",
								marginRight: "10px",
							}}
						>
							{restaurantContext.restaurant.name}
						</Typography>
						<button
							className={commonStyles.primary_button}
							onClick={() => {
								setMoveTableSelected(!moveTableSelected);
							}}
						>
							Move KOT / Items
						</button>
					</div>
					<div className={styles.legend}>
						<div className={styles.legend_item}>
							<span
								className={styles.legend_color}
								style={{
									backgroundColor: "#E8E4E1",
								}}
							/>
							<Typography
								variant="body2"
								component="div"
								sx={{
									marginLeft: "4px",
									color: "#C2A186",
								}}
							>
								Empty Table
							</Typography>
						</div>
						<div className={styles.legend_item}>
							<span
								className={styles.legend_color}
								style={{
									background:
										"linear-gradient(269.51deg, #008B59 -4.52%, #023C49 100%)",
								}}
							/>
							<Typography
								variant="body2"
								component="div"
								sx={{
									marginLeft: "4px",
									color: "#C2A186",
								}}
							>
								Running Table
							</Typography>
						</div>
						<div className={styles.legend_item}>
							<span
								className={styles.legend_color}
								style={{
									background:
										"linear-gradient(269.51deg, #00598B -4.52%, #260249 100%)",
								}}
							/>
							<Typography
								variant="body2"
								component="div"
								sx={{
									marginLeft: "4px",
									color: "#C2A186",
								}}
							>
								Printed Table
							</Typography>
						</div>
						<div className={styles.legend_item}>
							<span
								className={styles.legend_color}
								style={{
									background:
										"linear-gradient(136.53deg, #8B0000 25.67%, #D00303 79.82%)",
								}}
							/>
							<Typography
								variant="body2"
								component="div"
								sx={{
									marginLeft: "4px",
									color: "#C2A186",
								}}
							>
								Overdue Table
							</Typography>
						</div>
					</div>
				</div>
			)}
			{buildTableGrid()}
			<FilterMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />
			<div
				className={styles.backdrop}
				style={{
					display: moveTableSelected ? "block" : "none",
				}}
				onClick={() => {
					setMoveTableSelected(false);
				}}
			/>
			<Typography
				variant="h5"
				component="div"
				sx={{
					color: "#FEFAF3",
					marginTop: "150px",
					textAlign: "center",
					position: "absolute",
					top: 0,
					zIndex: 25,
					width: "100%",
					display: moveTableSelected ? "block" : "none",
					textShadow: "0px 4px 4px rgba(255, 255, 255, 0.25)",
				}}
			>
				Select a Table
			</Typography>
			<AddTableDialog
				open={isAddDialogOpen}
				setOpen={setIsAddDialogOpen}
				callback={(tableName) => {
					handleAddTemporaryTable(tableName, addDialogSection);
				}}
			/>

		</div>
	);
};

export default Dashboard;
