import axios from "axios";
import React, { useContext, useState } from "react";
import toast from "../../utils/toast";
import logger from "../../utils/logger";
import {
	CircularProgress,
	Dialog,
	FormLabel,
	Input,
	InputAdornment,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import styles from "./PastOrderSummary.module.css";
import {
	CancelRounded,
	PrintRounded,
	Search,
	VisibilityRounded,
} from "@mui/icons-material";
import commonStyles from "../../styles.module.css";
import { useLocation } from "react-router-dom";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import Header from "../../components/Header/Header";
import OrderSummaryDetails from "./OrderSummaryDetails";
import { printer } from "../../providers/PrintProvider";
import { ClipLoader } from "react-spinners";
import { restaurant } from "../../providers/RestaurantProvider";
import usePrinter from "../../hooks/usePrinter";

const PastOrderSummary = () => {
	const location = useLocation();

	if (!location.state) {
		location.state = {};
	}

	const [orders, setOrders] = useState(location.state?.orders || []);
	const [loading, setLoading] = useState(false);
	const restaurantContext = useContext(restaurant)

	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
	const [isSearching, setIsSearching] = useState(
		location.state?.isSearching || false
	);
	const [searchQuery, setSearchQuery] = useState(
		location.state?.searchQuery || ""
	);

	const [orderID, setOrderID] = useState("");

	const [from, setFrom] = useState(location.state?.from || "");
	const [to, setTo] = useState(location.state?.to || "");
	const [batchSize, setBatchSize] = useState(location.state?.batchSize || 100);

	const [selectedOrder, setSelectedOrder] = useState({});
	const { setOrderToPrint, orderToPrint, printers } =
		useContext(printer);
	const [orderDetailsDrawerOpen, setOrderDetailsDrawerOpen] = useState(false);

	let cancelReason = "";
	let password = "";

	let myTotalAmount = 0, totalContainerCharge = 0, totalDeliveryCharge = 0, totalDiscount = 0, totalCustomerPaid = 0, totalTip = 0;
	for (let order of orders ?? []) {
		if (order.status === "settled") {
			totalContainerCharge += order?.containerCharge ?? 0;
			totalDeliveryCharge += order?.deliveryCharge ?? 0;
			totalDiscount += order?.discount?.amount ?? 0;
			totalCustomerPaid += order?.payment?.customerPaid ?? 0;
			totalTip += order?.payment?.tip ?? 0;
			for (let kot of order?.kots ?? []) {
				myTotalAmount += kot.total ?? 0
			}
		}

	}

	const totalTaxValue = restaurantContext.restaurant?.taxes?.reduce((acc, tax) => acc + tax.value, 0)
	const grandTotal = (((myTotalAmount - totalDiscount) * (1 + totalTaxValue / 100)) + totalContainerCharge + totalDeliveryCharge)

	const { printBill } = usePrinter();
	const fetchOrders = async () => {
		setLoading(true);
		const todayTimestamp = new Date().setHours(0, 0, 0, 0);
		logger(todayTimestamp);

		try {
			const res = await axios.get(
				`/restaurants/${localStorage.getItem(
					"restaurant"
				)}/orders/timeRange?from=${from}&to=${to}&batchSize=${batchSize}`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);
			logger(res.data);

			setOrders(res.data);
			location.state.orders = res.data;
			setLoading(false);
			return res;
		} catch (error) {
			logger(error);
			toast.error(error.response?.data?.message ?? "Error fetching orders", {
				autoClose: 2000,
			});
			setLoading(false);
		}
	};

	const handleCancel = async () => {
		try {
			const res = await axios.put(
				`/restaurants/${localStorage.getItem("restaurant")}/orders/${orderID}`,
				{
					status: "cancelled",
					cancelReason,
				},
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			logger(res.data);
			fetchOrders();
			setConfirmDialogOpen(false);
			return res;
		} catch (error) {
			logger(error);
			toast.error(error.response?.data?.message ?? "Error cancelling order", {
				autoClose: 2000,
			});
		}
	};

	const ConfirmDialog = () => {
		return (
			<Dialog
				open={confirmDialogOpen}
				onClose={() => setConfirmDialogOpen(false)}
				sx={{
					"& .MuiDialog-paper": {
						backgroundColor: "transparent",
						boxShadow: "none",
					},

					"& .MuiModal-backdrop": {
						backdropFilter: "blur(8px)",
					},
				}}
			>
				<GradientBorder
					gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
					width="1px"
					className={styles.dialog_wrapper}
				>
					<div>
						<Typography
							variant="body1"
							component="div"
							color="#9E5E28"
							sx={{
								marginBottom: "12px",
							}}
						>
							Confirm Cancellation
						</Typography>
						<div className={styles.dialog_input}>
							<Typography variant="body2" component="div" color="#9E5E28">
								Password
							</Typography>
							<GradientBorder
								gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
								width="1px"
								className={styles.input_wrapper}
							>
								<input
									type="password"
									onChange={(e) => {
										password = e.target.value;
									}}
								/>
							</GradientBorder>
						</div>
						<div className={styles.dialog_input}>
							<Typography variant="body2" component="div" color="#9E5E28">
								Reason
							</Typography>
							<GradientBorder
								gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
								width="1px"
								className={styles.input_wrapper}
							>
								<input
									type="text"
									onChange={(e) => {
										cancelReason = e.target.value;
									}}
								/>
							</GradientBorder>
						</div>
						<div className={styles.dialog_button_row}>
							<GradientBorder
								gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
								width="1px"
								className={styles.cta}
							>
								<button
									onClick={() => {
										setConfirmDialogOpen(false);
									}}
								>
									Cancel
								</button>
							</GradientBorder>
							<GradientBorder
								gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
								width="1px"
								className={styles.cta}
							>
								<button
									onClick={() => {
										if (password === "st13521") {
											if (cancelReason === "") {
												toast.error("Please enter a reason", {
													autoClose: 2000,
												});
												return;
											}
											logger(orderID);
											const callFunction = handleCancel();
											toast.promise(callFunction, {
												pending: "Cancelling...",
												error: "Failed",
												success: "Successfully Cancelled"
											});
										} else {
											toast.error("Incorrect Password", {
												autoClose: 2000,
											});
										}
									}}
								>
									Confirm
								</button>
							</GradientBorder>
						</div>
					</div>
				</GradientBorder>
			</Dialog>
		);
	};

	return (
		<div className={styles.body}>
			<Header title="Past Orders" />
			<div className={styles.inputs}>
				<div className={styles.input}>
					<FormLabel>From: </FormLabel>
					<Input
						type="date"
						value={from}
						onChange={(e) => {
							setFrom(e.target.value);
							location.state.from = e.target.value;
						}}
					/>
				</div>
				<div className={styles.input}>
					<FormLabel>To: </FormLabel>
					<Input
						type="date"
						value={to}
						onChange={(e) => {
							setTo(e.target.value);
							location.state.to = e.target.value;
						}}
					/>
				</div>
				<div className={styles.input}>
					<FormLabel>Batch Size: </FormLabel>
					<Input
						type="number"
						value={batchSize}
						onChange={(e) => {
							setBatchSize(e.target.value);
							location.state.batchSize = e.target.value;
						}}
					/>
				</div>
				<button
					variant="contained"
					onClick={() => {
						const callFunction = fetchOrders();
						toast.promise(callFunction, {
							pending: "Fetching Orders...",
							error: "Fetching Orders Failed",
							success: "Orders Fetched Successfully"
						});
					}}
					className={commonStyles.primary_button}
				>
					Search
					<CircularProgress
						color="inherit"
						sx={{ marginLeft: "5px", display: loading ? "block" : "none" }}
						size={15}
					/>
				</button>
			</div>
			{orders.length > 0 && (
				<>
					<div className={styles.top_bar}>
						<TextField
							placeholder="Search"
							className={styles.search}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<Search />
									</InputAdornment>
								),
							}}
							sx={{
								"& .MuiOutlinedInput-root": {
									"& fieldset": {
										outline: "none",
										border: "none",
									},
									"&:hover fieldset": {
										outline: "none",
										border: "none",
									},
									"&.Mui-focused fieldset": {
										outline: "none",
										border: "none",
									},
								},
							}}
							value={searchQuery}
							onChange={(e) => {
								if (e.target.value === "") {
									setIsSearching(false);
									location.state.isSearching = false;
								} else {
									setIsSearching(true);
									location.state.isSearching = true;
								}
								setSearchQuery(e.target.value);
								location.state.searchQuery = e.target.value;
							}}
						/>
						<div className={styles.legends}>
							<div className={styles.legend}>
								<span className={styles.settled}></span>
								Settled
							</div>
							<div className={styles.legend}>
								<span className={styles.completed}></span>
								Billed
							</div>
							<div className={styles.legend}>
								<span className={styles.cancelled}></span>
								Cancelled
							</div>
							<div className={styles.legend}>
								<span className={styles.running}></span>
								Running
							</div>
							<div className={styles.legend}>
								<span className={styles.billModified}></span>
								Bill Modified
							</div>
						</div>
					</div>

					<TableContainer>
						<Table stickyHeader>
							<TableHead>
								<TableRow
									sx={{
										backgroundColor: "#FEF2E2",
										boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

										"& .MuiTableCell-root": {
											fontWeight: "bold",
											color: "#9E5E28",
											backgroundColor: "#FEF2E2",
										},
									}}
								>
									<TableCell>Order No</TableCell>
									<TableCell>Date</TableCell>
									<TableCell>Order Type</TableCell>
									<TableCell>Order Status</TableCell>
									<TableCell>Customer Name</TableCell>
									<TableCell>Customer Phone</TableCell>
									<TableCell>Payment Type</TableCell>
									<TableCell>My Amount</TableCell>
									<TableCell>Discount</TableCell>
									<TableCell>Delivery charge</TableCell>
									<TableCell>Container charge</TableCell>
									<TableCell>Tax</TableCell>
									<TableCell>Grand Total</TableCell>
									<TableCell>Customer Paid</TableCell>
									<TableCell>Tip</TableCell>
									<TableCell>Tip+Total</TableCell>
									<TableCell>Actions</TableCell>
								</TableRow>
								<TableRow
									sx={{
										backgroundColor: "#FEF2E2",
										boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

										"& .MuiTableCell-root": {
											fontWeight: "bold",
											color: "#9E5E28",
											backgroundColor: "#FEF2E2",
										},
									}}
								>
									<TableCell>--</TableCell>
									<TableCell>{new Date().toLocaleDateString()}</TableCell>
									<TableCell>--</TableCell>
									<TableCell>--</TableCell>
									<TableCell>--</TableCell>
									<TableCell>--</TableCell>
									<TableCell>--</TableCell>
									<TableCell>{
										myTotalAmount.toFixed(2)
									}</TableCell>
									<TableCell>{totalDiscount}</TableCell>
									<TableCell>{totalContainerCharge}</TableCell>
									<TableCell>{totalDeliveryCharge}</TableCell>
									<TableCell>{
										restaurantContext.restaurant.taxes?.map((tax) => {
											return <> <b>
												{tax?.name} : {(((tax?.value ?? 0) / 100) * myTotalAmount).toFixed(2)}
											</b> <br /></>
										})
									}</TableCell>
									<TableCell>{
										grandTotal
									}</TableCell>
									<TableCell>{totalCustomerPaid}</TableCell>
									<TableCell>{totalTip}</TableCell>
									<TableCell>{totalCustomerPaid}</TableCell>
									<TableCell>--</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{(isSearching
									? orders.filter((order) => {
										return (
											order?.orderNumber?.includes(searchQuery?.toLowerCase()) ||
											order?.user?.name?.toLowerCase()
												.includes(searchQuery?.toLowerCase()) ||
											order?.user?.phone?.toString()?.toLowerCase()
												.includes(searchQuery?.toLowerCase())
										);
									})
									: orders
								).map((order) => {
									const subTotal = order.kots.reduce((acc, kot) => {
										// const kot = kots[kotId]
										return acc + kot.total;
									}, 0);

									return (
										<TableRow className={(order?.billModified ? styles["billModified"] : styles[order.status])}>
											<TableCell>{order?.orderNumber}</TableCell>
											<TableCell>
												{new Date(order.createdAt).toLocaleString()}
											</TableCell>
											<TableCell>
												{order.table?.type?.toUpperCase() ?? "DINEIN"} ({order.table?.name ?? "Temporary Table"})
											</TableCell>
											<TableCell>{order.status + (order.status === "waivedOff" ? " (" + order.waivedOffReason + ")" : "")}</TableCell>
											<TableCell>{order.user?.name}</TableCell>
											<TableCell>{order.user?.phone}</TableCell>
											<TableCell>{order.payment?.method  &&(order.payment?.method + (order.payment?.transactionNo ? (" (" + order.payment?.transactionNo + ")") : "") || "Due")}</TableCell>
											<TableCell>{subTotal.toFixed(2)}</TableCell>
											<TableCell>{(order.discount?.amount ?? 0).toFixed(2)}</TableCell>
											<TableCell>{(order.deliveryCharge ?? 0)?.toFixed(2)}</TableCell>
											<TableCell>{(order.containerCharge ?? 0)?.toFixed(2)}</TableCell>
											<TableCell>{((subTotal - (order.discount?.amount ?? 0)) * totalTaxValue / 100)?.toFixed(2)}</TableCell>
											<TableCell>{((subTotal + (order.containerCharge ?? 0) + (order?.deliveryCharge ?? 0) - (order?.discount?.amount ?? 0)) * (1 + totalTaxValue / 100))?.toFixed(2)} </TableCell>
											<TableCell>{order?.payment?.customerPaid?.toFixed(2)}</TableCell>
											<TableCell>{order.payment?.tip}</TableCell>
											<TableCell>{((order.payment?.tip ?? 0) + (subTotal + (order.containerCharge ?? 0) + (order?.deliveryCharge ?? 0) - (order?.discount?.amount ?? 0)) * (1 + totalTaxValue / 100))?.toFixed(2)}</TableCell>
											<TableCell>
												<div className={styles.actions}>
													<VisibilityRounded
														onClick={() => {
															setSelectedOrder(order);
															setOrderDetailsDrawerOpen(true);
														}}
													/>
													{
														orderToPrint._id === order._id ?
															<ClipLoader
																color={"black"}
																loading={true}
																size={14}
															/> :
															<PrintRounded onClick={() => {
																toast.promise(printBill(order), {
																	pending: "Reprinting Order...",
																	error: "No printer found",
																	success: "Added to print Queue"
																})
															}
															} />
													}
													{order.status !== "cancelled" && (
														<CancelRounded
															onClick={() => {
																setOrderID(order._id);
																setConfirmDialogOpen(true);
															}}
														/>
													)}
												</div>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</>
			)}
			<ConfirmDialog />
			<OrderSummaryDetails
				open={orderDetailsDrawerOpen}
				setOpen={setOrderDetailsDrawerOpen}
				order={selectedOrder}
			/>
		</div>
	);
};

export default PastOrderSummary;
