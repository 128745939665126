import React, { useState, useContext } from "react";
import { restaurant } from "../../../providers/RestaurantProvider";
import {
	InputAdornment,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from "@mui/material";
import { Edit, Search } from "@mui/icons-material";
import styles from "./ItemListing.module.css";
import Header from "../../../components/Header/Header";
import ItemEditDialog from "./ItemEditDialog";
import logger from "../../../utils/logger";
import commonStyles from '../../../styles.module.css'

const ItemListing = () => {
	const restaurantContext = useContext(restaurant);

	const [isSearching, setIsSearching] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [selectedItem, setSelectedItem] = useState(null);
	const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

	return (
		<div>
			<Header title="Item Listing" />
			<TextField
				placeholder="Search"
				className={styles.search}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<Search />
						</InputAdornment>
					),
				}}
				sx={{
					"& .MuiOutlinedInput-root": {
						"& fieldset": {
							outline: "none",
							border: "none",
						},
						"&:hover fieldset": {
							outline: "none",
							border: "none",
						},
						"&.Mui-focused fieldset": {
							outline: "none",
							border: "none",
						},
					},
				}}
				value={searchQuery}
				onChange={(e) => {
					if (e.target.value === "") {
						setIsSearching(false);
					} else {
						setIsSearching(true);
					}
					setSearchQuery(e.target.value);
				}}
			/>
			<TableContainer>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Price</TableCell>
							<TableCell>Must&nbsp;Try</TableCell>
							<TableCell>Vegetarian</TableCell>
							<TableCell>Bar</TableCell>
							<TableCell>Properties</TableCell>
							<TableCell>Compliments</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(isSearching
							? Object.values(restaurantContext.items).filter((item) => {
									return item.name
										.toLowerCase()
										.includes(searchQuery.toLowerCase()) && !item.removed;
							  })
							: Object.values(restaurantContext.items)
						).map((item) => {
							return (
								<TableRow>
									<TableCell>{item.name}</TableCell>
									<TableCell>{item.price}</TableCell>
									<TableCell>
										<input type="checkbox" checked={item.mustTry} readOnly />
									</TableCell>
									<TableCell>
										<input type="checkbox" checked={item.isVeg} readOnly />
									</TableCell>
									<TableCell>
										<input type="checkbox" checked={item.isBar} readOnly />
									</TableCell>
									<TableCell>
										{item.properties
											?.map((p) => {
												return `${p.name} : ${p.options
													.map((opt) => opt.name)
													.join(", ")}`;
											})
											.join("\n") || "--"}
									</TableCell>
									<TableCell>{item.complements.join(", ") || "--"}</TableCell>
									<TableCell>
										<Edit
											className={commonStyles.btn}
											onClick={() => {
												logger(item);
												setSelectedItem(item);
												setIsEditDialogOpen(true);
											}}
										/>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<ItemEditDialog
				confirmDialogOpen={isEditDialogOpen}
				setConfirmDialogOpen={setIsEditDialogOpen}
				item={selectedItem ?? {}}
				isUpdate={true}
			/>
		</div>
	);
};

export default ItemListing;
