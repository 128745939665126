import { CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import KOTitemCard from "./KOTitemCard";
import styles from "./OrderDetails.module.css";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import { orders } from "../../providers/OrderProvider";
import vibrator from "../../utils/vibrator";
import { Alarm } from "@mui/icons-material";
import commonStyles from "../../styles.module.css";
import SettleDialog from "../../components/SettleDialog/SettleDialog";
import { kotContext } from "../../providers/KotProvider";
import KOTCard from "./KOTCard";
import { printer } from "../../providers/PrintProvider";

const OrderDetails = () => {
	const params = useParams();
	const { id: orderId } = params;

	const [order, setOrder] = useState({});
	const [loading, setLoading] = useState(true);
	let orderBillData = JSON.parse(localStorage.getItem("orderBillData"));


	const [isCompleting, setIsCompleting] = useState(false);
	const [isSettleDialogOpen, setIsSettleDialogOpen] = useState(false);
	const { kots } = useContext(kotContext);

	const navigate = useNavigate();
	const ordersContext = useContext(orders);

	const printerContext= useContext(printer)

	const handleComplete = async () => {
		vibrator.tap();
		setIsCompleting(true);
		if (await ordersContext.completeOrder(orderId)) {
			navigate("/dashboard", { replace: true });
		}
		setIsCompleting(false);
	};

	const handleSettle = () => {
		setIsSettleDialogOpen(true);
	};

	const fetchOrder = () => {
		if (!ordersContext.orders) {
			return;
		}

		const order = Object.values(ordersContext.orders).find(
			(order) => order._id === orderId
		);

		if (order) {
			setOrder(order);
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchOrder();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderId, ordersContext.orders]);

	let kotPrinterAvailable=printerContext.printers.find((printer)=>printer?.inUse && printer?.shouldPrintKOT)

	return (
		<>
			<Header title={"KOT(s) - Bill No "+order?.orderNumber} />
			{loading ? (
				<></>
			) : (
				<div className={styles.body}>
					<Typography
						variant="h4"
						component="div"
						color="#824500"
						fontWeight={400}
						sx={{
							paddingTop: "20px",
							marginBottom: "10px",
							marginLeft: "16px",
						}}
					>
						Table No : {order.table.name || 1}
					</Typography>

					<div className={styles.legend}>
						<div className={styles.legend_item}>
							<span
								style={{
									background: "linear-gradient(#06DCCF, #389E7A)",
								}}
							/>
							<Typography
								variant="caption"
								component="div"
								sx={{
									marginLeft: "4px",
									color: "#C2A186",
								}}
							>
								User
							</Typography>
						</div>
						<div className={styles.legend_item}>
							<span
								style={{
									background: "linear-gradient(#7E06DC, #63B4FF)",
								}}
							/>
							<Typography
								variant="caption"
								component="div"
								sx={{
									marginLeft: "4px",
									color: "#C2A186",
								}}
							>
								Captain
							</Typography>
						</div>
						<div className={styles.legend_item}>
							<span
								style={{
									background:
										"linear-gradient(270.69deg, rgba(255, 168, 168, 0.77) -6.58%, #ED0000 153.04%)",
								}}
							/>
							<Typography
								variant="caption"
								component="div"
								sx={{
									marginLeft: "4px",
									color: "#C2A186",
								}}
							>
								Overdue
							</Typography>
						</div>
					</div>

					{order.kots.map((kotId, index) => {
						const kot = kots[kotId];
						return (
							<KOTCard kot={kot} printerAvailable={kotPrinterAvailable} index={index} key={index}/>
						);
					})}
					<Typography
						variant="h5"
						component="div"
						color="#824500"
						fontWeight={400}
						sx={{
							paddingTop: "20px",
							paddingBottom: "20px",
							marginLeft: "16px",
						}}
					>
						Total :{" "}
						{order.kots.reduce((acc, kotId) => {
							const kot = kots[kotId];
							return acc + kot.total;
						}, 0)}
					</Typography>
					<div className={styles.cta_row}>
						<div
							className={commonStyles.secondary_button}
							onClick={() => {
								vibrator.tap();
								navigate("/dashboard", { replace: true });
							}}
						>
							Back
						</div>

						{/* {order.status === "running" && (
							<div
								className={commonStyles.primary_button}
								onClick={isCompleting ? null : handleComplete}
							>
								{isCompleting ? (
									<CircularProgress
										color="inherit"
										size={24}
										sx={{
											padding: "0px 22px",
										}}
									/>
								) : (
									"Complete"
								)}
							</div>
						)} */}
						{order.status !== "settled" && (
							<div
								className={commonStyles.primary_button}
								onClick={handleSettle}
							>
								Settle
							</div>
						)}
					</div>
					{/* <GradientBorder
						gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
						width="1px"
						className={styles.cta}
					>
						<div onClick={handlePrint}>Print</div>
					</GradientBorder> */}
				</div>
			)}
			<SettleDialog
				orderBillData={orderBillData?.[order?.table?.name]}
				open={isSettleDialogOpen}
				setOpen={setIsSettleDialogOpen}
				order={order}
			/>
		</>
	);
};

export default OrderDetails;
