import React, { useEffect, useState } from "react";
import styles from "./ItemEditDialog.module.css";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import { Dialog, Typography } from "@mui/material";
import { useContext } from "react";
import { restaurant } from "../../../providers/RestaurantProvider";
import commonStyles from "../../../styles.module.css";
import logger from "../../../utils/logger";
import { AddRounded, DeleteRounded } from "@mui/icons-material";
import axios from "axios";
import toast from "../../../utils/toast";

const ItemEditDialog = ({
	confirmDialogOpen,
	setConfirmDialogOpen,
	item = {},
	isUpdate = false,
}) => {
	const restaurantContext = useContext(restaurant);
	const [data, setData] = useState({});

	useEffect(() => {
		setData({
			name: item.name ?? "",
			price: item.price ?? null,
			description: item.description ?? "",
			mustTry: item.mustTry ?? null,
			isVeg: item.isVeg ?? null,
			isBar: item.isBar ?? null,
			hasComplements: item?.complements?.length > 0 ?? null,
			hasProperties: item?.properties?.length > 0 ?? null,
			available: item.available ?? null,
			maxPropertySelectionsAllowed: item.maxPropertySelectionsAllowed ?? null
		});
		setComplements(item.complements ?? []);
		setAdditionalDetails({
			taste: item.additionalDetails?.taste ?? null,
			countryOfOrigin: item.additionalDetails?.countryOfOrigin ?? null,
			calories: item.additionalDetails?.calories ?? null,
			carbs: item.additionalDetails?.carbs ?? null,
			protein: item.additionalDetails?.protein ?? null,
			fat: item.additionalDetails?.fat ?? null,
		})
		setProperties(item.properties ?? []);
	}, [item]);

	const handleChange = (e) => {
		let { name, value } = e.target;
		if (value === "yes") {
			value = true
		} else if (value === "neither") {
			value = null
		} else if (value === 'no') {
			value = false;
		}
		setData((prevState) => ({ ...prevState, [name]: value }));
	};


	const [complements, setComplements] = useState(item.complements ?? []);
	const [newComplement, setNewComplement] = useState("");

	const [properties, setProperties] = useState(item.properties ?? []);
	const [additionalDetails, setAdditionalDetails] = useState({})

	const [customisationDialogOpen, setCustomisationDialogOpen] = useState(false);

	const handleComplementChange = (e) => {
		setNewComplement(e.target.value);
	};

	const handleAddComplement = (e) => {
		e.preventDefault();
		if (newComplement.trim() !== "") {
			if (!complements.includes(newComplement)) {
				setComplements([...complements, newComplement]);
			}
			setNewComplement("");
		}
	};

	const handleRemoveComplement = (index) => {
		const updatedComplements = [...complements];
		updatedComplements.splice(index, 1);
		setComplements(updatedComplements);
	};

	const handleOptionNameChange = (event, propertyIndex, optionIndex) => {
		const updatedProperties = [...properties];
		updatedProperties[propertyIndex].options[optionIndex].name =
			event.target.value;
		setProperties(updatedProperties);
	};

	const handleOptionPriceChange = (event, propertyIndex, optionIndex) => {
		const updatedProperties = [...properties];
		updatedProperties[propertyIndex].options[optionIndex].price =
			event.target.value;
		setProperties(updatedProperties);
	};

	const handleAddProperty = (name) => {
		const updatedProperties = [
			...properties,
			{ name, options: [{ name: "", price: "" }] },
		];
		setProperties(updatedProperties);
	};

	const handleAddOption = (propertyIndex) => {
		const updatedProperties = [...properties];
		updatedProperties[propertyIndex].options.push({
			name: "",
			price: "",
		});
		setProperties(updatedProperties);
	};

	const handleRemoveProperty = (propertyIndex) => {
		const updatedProperties = [...properties];
		updatedProperties.splice(propertyIndex, 1);
		setProperties(updatedProperties);
	};

	const handleRemoveOption = (propertyIndex, optionIndex) => {
		const updatedProperties = [...properties];
		updatedProperties[propertyIndex].options.splice(optionIndex, 1);
		setProperties(updatedProperties);
	};

	const updateHandler = async (e) => {
		let finalData = { ...data };
		finalData.complements = complements;
		finalData.properties = properties;
		finalData.additionalDetails = additionalDetails
		try {
			const res = await axios.put(
				`/restaurants/${restaurantContext.restaurant._id}/items/${item._id}`,
				finalData,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			logger(res.data);

			toast.success("Item updated successfully");

			setConfirmDialogOpen(false);
			return res;
		} catch (error) {
			logger(error);
			toast.error(error.response?.data?.message ?? "Error updating item");
		}
	};

	const handleAdditionalDetailsChange = (e) => {
		setAdditionalDetails({
			...additionalDetails,
			[e.target.name]: e.target.value
		})
	}
	return (
		<>
			<Dialog
				open={confirmDialogOpen}
				onClose={() => setConfirmDialogOpen(false)}
				sx={{
					"& .MuiDialog-paper": {
						backgroundColor: "transparent",
						boxShadow: "none",
					},

					"& .MuiModal-backdrop": {
						backdropFilter: "blur(8px)",
					},
				}}
			>
				<GradientBorder
					gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
					width="1px"
					className={styles.dialog_wrapper}
				>
					<div className={styles.container}>
						<Typography
							variant="h6"
							color="#824500"
							component="div"
							sx={{ textAlign: "center", marginBottom: "10px" }}
						>
							{isUpdate ? "Update" : "Add"} Item
						</Typography>
						<div className={styles.form}>
							<div className={styles.form_group}>
								<label htmlFor="name">Item Name</label>
								<input
									type="text"
									name="name"
									id="name"
									value={data.name}
									onChange={handleChange}
								/>
							</div>
							<div className={styles.form_group}>
								<label htmlFor="price">Price</label>
								<input
									type="number"
									name="price"
									id="price"
									value={data.price}
									onChange={handleChange}
								/>
							</div>
							<div className={styles.form_group}>
								<label htmlFor="must_try">Must Try?</label>
								<select
									value={
										data.mustTry !== null ? (data.mustTry ? "yes" : "no") : ""
									}
									onChange={handleChange}
									name="mustTry"
									id="must_try"
								>
									<option value="" disabled selected>
										-- Select --
									</option>
									<option value="yes">Yes</option>
									<option value="no">No</option>
								</select>
							</div>
							<div className={styles.form_group}>
								<label htmlFor="is_veg">Is it Vegetarian?</label>
								<select
									value={data.isVeg == null ? "neither" : data.isVeg ? "yes" : "no"}
									onChange={handleChange}
									name="isVeg"
									id="is_veg"
								>
									<option value="" disabled selected>
										-- Select --
									</option>
									<option value="yes">Yes</option>
									<option value="no">No</option>
									<option value="neither">Neither</option>

								</select>
							</div>
							<div className={styles.form_group}>
								<label htmlFor="is_bar">Is it a Drink?</label>
								<select
									value={data.isBar !== null ? (data.isBar ? "yes" : "no") : ""}
									onChange={handleChange}
									name="isBar"
									id="is_bar"
								>
									<option value="" disabled selected>
										-- Select --
									</option>
									<option value="yes">Yes</option>
									<option value="no">No</option>
								</select>
							</div>
							<div className={styles.form_group}>
								<label htmlFor="is_available">Is the item available now?</label>
								<select
									value={
										data.available !== null
											? data.available
												? "yes"
												: "no"
											: ""
									}
									onChange={handleChange}
									name="available"
									id="is_available"
								>
									<option value="" disabled selected>
										-- Select --
									</option>
									<option value="yes">Yes</option>
									<option value="no">No</option>
								</select>
							</div>
							<div className={styles.form_group}>
								<label htmlFor="description">Description</label>
								<textarea
									name="description"
									id="description"
									value={data.description}
									onChange={handleChange}
								></textarea>
							</div>
							<div className={styles.form_group}>
								<label htmlFor="description">
									Does the item have complements?
								</label>
								<select
									value={
										data.hasComplements !== null
											? data.hasComplements
												? "yes"
												: "no"
											: ""
									}
									onChange={handleChange}
									name="hasComplements"
									id="has_complements"
								>
									<option value="" disabled selected>
										-- Select --
									</option>
									<option value="yes">Yes</option>
									<option value="no">No</option>
								</select>
							</div>
							{data.hasComplements && (
								<div className={styles.form_section}>
									<label htmlFor="complements">Complements</label>
									<div className={styles.complements}>
										{complements.map((complement, index) => (
											<div className={styles.complement} key={index}>
												<div>
													<span>{index + 1}. </span>
													<p>{complement}</p>
												</div>
												<DeleteRounded
													onClick={() => handleRemoveComplement(index)}
												/>
											</div>
										))}
									</div>
									<div className={styles.complement_input}>
										<input
											type="text"
											name="complement"
											id="complement"
											value={newComplement}
											onChange={handleComplementChange}
											onKeyDown={(e) => {
												if (e.key === "Enter") {
													handleAddComplement(e);
												}
											}}
										/>
										<AddRounded onClick={handleAddComplement} />
									</div>
								</div>
							)}
							<div className={styles.form_group}>
								<label htmlFor="description">
									Does the item have customisations?
								</label>
								<select
									value={
										data.hasProperties !== null
											? data.hasProperties
												? "yes"
												: "no"
											: ""
									}
									onChange={handleChange}
									name="hasProperties"
									id="has_properties"
								>
									<option value="" disabled selected>
										-- Select --
									</option>
									<option value="yes">Yes</option>
									<option value="no">No</option>
								</select>
							</div>
							{data.hasProperties && (
								<div className={styles.form_section}>
									<label htmlFor="description">Customisations</label>
									<div className={styles.customisations}>
										<div className={styles.customisations_list}>
											{properties.map((property, propertyIndex) => (
												<div
													className={styles.customisation}
													key={propertyIndex}
												>
													<div>
														<p>{property.name}</p>
														<DeleteRounded
															onClick={() =>
																handleRemoveProperty(propertyIndex)
															}
														/>
													</div>
													<div>

														<div className={styles.option_row}>
															<div>

																<input
																	type="checkbox"
																	name="isMandatory"
																	id="isMandatory"
																	checked={property.isMandatory}
																	onChange={(e) => {
																		setProperties(prev => {
																			let updatedData = [...prev]
																			updatedData[propertyIndex].isMandatory = e.target.checked
																			return [...updatedData];
																		})
																	}
																	}
																/>
																<label htmlFor="isMandatory">Is Mandatory</label>
															</div>

														</div>
														<div className={styles.option_row}>
															<div>

																<input
																	type="checkbox"
																	name="hasMultipleSelections"
																	id="isMandatory"
																	checked={property.hasMultipleSelection}
																	onChange={(e) => {
																		setProperties(prev => {
																			let updatedData = [...prev]
																			updatedData[propertyIndex].hasMultipleSelection = e.target.checked
																			return [...updatedData];
																		})
																	}
																	}
																/>
																<label htmlFor="isMandatory">Has Multiple Selections</label>
															</div>

														</div>
													</div>
													<div className={styles.form_group}>
														<label htmlFor="minSelectionsRequired">Minimum Selection Required</label>
														<input
															type="number"
															name="minSelectionsRequired"
															id="minSelectionsRequired"
															value={property.minSelectionsRequired}
															onChange={(e) => {
																setProperties(prev => {
																	let updatedData = [...prev]
																	updatedData[propertyIndex].minSelectionsRequired = e.target.value < 0 ? 1 :e.target.value
																	return [...updatedData];
																})
															}
															}
														/>
													</div>
													<div className={styles.form_group}>
														<label htmlFor="maxSelectionsAllowed">Maximum Selection Allowed</label>
														<input
															type="number"
															name="maxSelectionsAllowed"
															id="maxSelectionsAllowed"
															value={property.maxSelectionsAllowed}
															onChange={(e) => {
																setProperties(prev => {
																	let updatedData = [...prev]
																	updatedData[propertyIndex].maxSelectionsAllowed = e.target.value < 0 ? 1 :e.target.value
																	return [...updatedData];
																})
															}
															}
														/>
													</div>
													<div className={styles.customisation_options}>
														{property.options.map((option, optionIndex) => (
															<div
																className={styles.customisation_option}
																key={optionIndex}
															>
																<div className={styles.option_details}>
																	<div className={styles.option_row}>
																		<label htmlFor="option_name">Name</label>
																		<input
																			type="text"
																			name="option_name"
																			id="option_name"
																			value={option.name}
																			onChange={(e) =>
																				handleOptionNameChange(
																					e,
																					propertyIndex,
																					optionIndex
																				)
																			}
																		/>
																	</div>
																	<div className={styles.option_row}>
																		<label htmlFor="option_price">Price</label>
																		<input
																			type="number"
																			name="option_price"
																			id="option_price"
																			value={option.price}
																			onChange={(e) =>
																				handleOptionPriceChange(
																					e,
																					propertyIndex,
																					optionIndex
																				)
																			}
																		/>
																	</div>
																	<DeleteRounded
																		onClick={() =>
																			handleRemoveOption(
																				propertyIndex,
																				optionIndex
																			)
																		}
																	/>
																</div>
															</div>
														))}
														<button
															onClick={() => handleAddOption(propertyIndex)}
															className={commonStyles.secondary_button}
															style={{ cursor: "pointer" }}
														>
															Add Option
														</button>
													</div>
												</div>
											))}
										</div>
										<button
											onClick={() => setCustomisationDialogOpen(true)}
											className={commonStyles.secondary_button}
											style={{ cursor: "pointer" }}
										>
											Add Customisation
										</button>
									</div>
								</div>
							)}
							<div className={styles.form_group}>
								<label htmlFor="price">Maximum Property selections allowed</label>
								<input
									type="number"
									name="maxPropertySelectionsAllowed"
									id="maxPropertySelectionsAllowed"
									value={data.maxPropertySelectionsAllowed}
									onChange={handleChange}
								/>
							</div>
							<h4>Additional Details</h4>
							<div className={styles.form_group}>
								<label htmlFor="name">Taste</label>
								<input
									type="text"
									name="taste"
									value={additionalDetails.taste}
									onChange={handleAdditionalDetailsChange}
								/>
							</div>
							<div className={styles.form_group}>
								<label htmlFor="name">Country of origin</label>
								<input
									type="text"
									name="countryOfOrigin"
									value={additionalDetails.countryOfOrigin}
									onChange={handleAdditionalDetailsChange}
								/>
							</div>
							<div className={styles.form_group}>
								<label htmlFor="name">Calories</label>
								<input
									type="number"
									name="calories"
									value={additionalDetails.calories}
									onChange={handleAdditionalDetailsChange}
								/>
							</div>
							<div className={styles.form_group}>
								<label htmlFor="name">Carbs</label>
								<input
									type="number"
									name="carbs"
									value={additionalDetails.carbs}
									onChange={handleAdditionalDetailsChange}
								/>
							</div>
							<div className={styles.form_group}>
								<label htmlFor="name">Protein</label>
								<input
									type="number"
									name="protein"
									value={additionalDetails.protein}
									onChange={handleAdditionalDetailsChange}
								/>
							</div>
							<div className={styles.form_group}>
								<label htmlFor="name">Fat</label>
								<input
									type="number"
									name="fat"
									value={additionalDetails.fat}
									onChange={handleAdditionalDetailsChange}
								/>
							</div>
						</div>
						<button
							onClick={(e) => {
								const callFunction = updateHandler(e);
								toast.promise(callFunction, {
									pending: "Saving...",
									error: "Failed",
									success: "Successfully Updated"
								});
							}}
							className={commonStyles.primary_button}
							style={{
								cursor: "pointer",
								display: "inline-block",
								position: "relative",
								transform: "translateX(-50%)",
								left: "50%",
								animation: "none"
							}}
						>
							{isUpdate ? "Update" : "Save"}
						</button>
					</div>
				</GradientBorder>
			</Dialog>
			<CustomisationDialog
				open={customisationDialogOpen}
				setOpen={setCustomisationDialogOpen}
				onAdd={handleAddProperty}
			/>
		</>
	);
};

const CustomisationDialog = ({ open, setOpen, onAdd }) => {
	const [name, setName] = useState("");
	const [otherName, setOtherName] = useState("");

	const handleAdd = () => {
		onAdd(name === "Other" ? otherName : name);
		setName("");
		setOtherName("");
		setOpen(false);
	};

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.dialog_wrapper}
			>
				<div className={styles.container}>
					<h2>Add Customisation</h2>
					<div className={styles.form}>
						<div className={styles.form_group}>
							<label htmlFor="name">What do you want to customise?</label>
							<select
								value={name}
								onChange={(e) => setName(e.target.value)}
								name="name"
								id="name"
							>
								<option value="" disabled selected>
									-- Select --
								</option>
								<option value="Quantity">Quantity</option>
								<option value="Add-Ons">Add-Ons</option>
								<option value="Other">Other</option>
							</select>
						</div>
						{name === "Other" && (
							<div className={styles.form_group}>
								<label htmlFor="other_name">Other:</label>
								<input
									type="text"
									name="other_name"
									id="other_name"
									value={otherName}
									onChange={(e) => setOtherName(e.target.value)}
								/>
							</div>
						)}
					</div>
					<button
						onClick={handleAdd}
						className={commonStyles.primary_button}
						style={{
							cursor: "pointer",
							display: "inline-block",
							position: "relative",
							transform: "translateX(-50%)",
							left: "50%",
						}}
					>
						Add
					</button>
				</div>
			</GradientBorder>
		</Dialog>
	);
};

export default ItemEditDialog;
