import { Dialog, Typography } from "@mui/material";
import React, { useState } from "react";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import styles from "./AddTableDialog.module.css";
import vibrator from "../../utils/vibrator";
import logger from "../../utils/logger";

const AddTableDialog = ({ open, setOpen, callback }) => {
	const [tableName, setTableName] = useState("");

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.dialog_wrapper}
			>
				<div>
					<Typography
						variant="body1"
						component="div"
						color="#9E5E28"
						sx={{
							marginBottom: "12px",
						}}
					>
						Add Temporary Table
					</Typography>
					<div className={styles.dialog_input}>
						<Typography variant="body2" component="div" color="#9E5E28">
							Table Name
						</Typography>
						<GradientBorder
							gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
							width="1px"
							className={styles.input_wrapper}
						>
							<input
								type="text"
								maxLength={2}
								value={tableName}
								onChange={(e) => {
									setTableName(e.target.value.toUpperCase());
								}}
							/>
						</GradientBorder>
					</div>
					<div className={styles.dialog_button_row}>
						<GradientBorder
							gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
							width="1px"
							className={styles.cta}
						>
							<button
								onClick={() => {
									vibrator.tap();
									logger("Cancel");
									setOpen(false);
								}}
							>
								Cancel
							</button>
						</GradientBorder>
						<GradientBorder
							gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
							width="1px"
							className={styles.cta}
						>
							<button
								onClick={() => {
									vibrator.tap();
									callback(tableName.toUpperCase());
								}}
							>
								Confirm
							</button>
						</GradientBorder>
					</div>
				</div>
			</GradientBorder>
		</Dialog>
	);
};

export default AddTableDialog;
