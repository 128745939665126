import { createContext, useState } from "react";
import logger from "../utils/logger";
const notifications = createContext();

const { Provider } = notifications;

const NotificationProvider = ({ children }) => {
	const [notifications, setStateNotifications] = useState(
		JSON.parse(localStorage.getItem("notifications")) || []
	);
	const [notificationsEnabled, setStateNotificationsEnabled] = useState(
		JSON.parse(localStorage.getItem("notificationsEnabled")) || false
	);
	const [kitchenNotifications, setStateKitchenNotifications] = useState(
		JSON.parse(localStorage.getItem("kitchenNotifications")) || []
	);
	const setKitchenNotifications = (kitchenNotifications) => {
		localStorage.setItem(
			"kitchenNotifications",
			JSON.stringify(kitchenNotifications)
		);
		setStateKitchenNotifications(kitchenNotifications);
	};
	const addKitchenNotification = (notification) => {
		setStateKitchenNotifications((prev) => {
			const newNotifications = [notification, ...prev];
			localStorage.setItem(
				"kitchenNotifications",
				JSON.stringify(newNotifications)
			);
			return newNotifications;
		});
	};
	const clearKitchenNotifications = () => {
		setKitchenNotifications([]);
	};

	const setNotifications = (notification) => {
		localStorage.setItem("notifications", JSON.stringify(notification));
		setStateNotifications(notification);
	};

	const setNotificationsEnabled = (enabled) => {
		logger(
			"NotificationProvider.js",
			"setNotificationsEnabled",
			"notificationsEnabled",
			enabled
		);
		localStorage.setItem("notificationsEnabled", JSON.stringify(enabled));
		setStateNotificationsEnabled(enabled);
	};

	const clearNotifications = () => {
		setNotifications([]);
	};

	const addNotification = async (notification) => {
		setStateNotifications((prev) => {
			const newNotifications = [notification, ...prev];
			localStorage.setItem("notifications", JSON.stringify(newNotifications));
			return newNotifications;
		});
	};

	const removeNotification = (index) => {
		setStateNotifications((prev) => {
			const newNotifications = [...prev];
			newNotifications.splice(index, 1);
			localStorage.setItem("notifications", JSON.stringify(newNotifications));
			return newNotifications;
		});
	};

	return (
		<Provider
			value={{
				notifications,
				setNotifications,
				clearNotifications,
				notificationsEnabled,
				setNotificationsEnabled,
				addNotification,
				removeNotification,
				kitchenNotifications,
				setKitchenNotifications,
				addKitchenNotification,
				clearKitchenNotifications,
			}}
		>
			{children}
		</Provider>
	);
};

export { NotificationProvider, notifications };
