import React, { useContext } from "react";
import styles from "./DiscountDialog.module.css";
import {
	Dialog,
	DialogActions,
	DialogTitle,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
} from "@mui/material";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
// import GradientText from "../GradientText/GradientText";
import commonStyles from "../../../styles.module.css";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import { restaurant } from "../../../providers/RestaurantProvider";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

function getStyles(name, personName, theme) {
	return {
		fontWeight:
			personName.indexOf(name) === -1
				? theme.typography.fontWeightRegular
				: theme.typography.fontWeightMedium,
	};
}

export default function DiscountDialog({
	open,
	setOpen,
	discount,
	setDiscount,
	category,
	setCategory,
	tableNo,
}) {
	const theme = useTheme();
	const restaurantContext = useContext(restaurant);

	const handleChange = (e) => {
		let { name, value } = e.target;
		if (discount.discountRateType === "percentage") {
			value = value > 100 ? 100 : value < 0 ? 0 : value;
		}
		if (name === "discountRateType") {
			setDiscount({
				...discount,
				[name]: value,
				discountRate: 0,
			});
			let orderBillData = JSON.parse(localStorage.getItem("orderBillData"));
			// localStorage.setItem(
			// 	"orderBillData",
			// 	JSON.stringify({
			// 		...orderBillData,
			// 		[tableNo]: {
			// 			...(orderBillData?.[tableNo] ?? {}),
			// 			[name]: value,
			// 			discountRate: 0,
			// 		},
			// 	})
			// );
		} else {
			setDiscount({
				...discount,
				[name]: value,
			});
			// let orderBillData = JSON.parse(localStorage.getItem("orderBillData"));
			// localStorage.setItem(
			// 	"orderBillData",
			// 	JSON.stringify({
			// 		...orderBillData,
			// 		[tableNo]: {
			// 			...(orderBillData?.[tableNo] ?? {}),
			// 			[name]: value,
			// 		},
			// 	})
			// );
		}
	};
	const handleCategoryChange = (e) => {
		const {
			target: { value },
		} = e;
		setCategory(typeof value === "string" ? value.split(",") : value);
		// let orderBillData = JSON.parse(localStorage.getItem("orderBillData"));
		// localStorage.setItem(
		// 	"orderBillData",
		// 	JSON.stringify({
		// 		...orderBillData,
		// 		[tableNo]: {
		// 			...(orderBillData?.[tableNo] ?? {}),
		// 			category: typeof value === "string" ? value.split(",") : value,
		// 		},
		// 	})
		// );
	};

	const categories = restaurantContext.restaurant?.menu.map(
		(item) => item.name
	);

	const handleSave = (e) => {
		e.preventDefault();
		let orderBillData = JSON.parse(localStorage.getItem("orderBillData"));

		localStorage.setItem(
			"orderBillData",
			JSON.stringify({
				...orderBillData,
				[tableNo]: {
					...(orderBillData?.[tableNo] ?? {}),
					category,
					...discount
				},
			})
		);
		setOpen(false)
	}

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			sx={{
				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.dialog_wrapper}
			>
				<div>
					<DialogTitle>Discount</DialogTitle>
					<div>
						<div className={styles.inputs}>
							<div className={styles.input}>
								<label htmlFor="customer_paid">Customer Discount</label>
								<button
									onClick={()=>{
										const billData= JSON.parse(localStorage.getItem('orderBillData'))
										delete billData[tableNo]
										localStorage.setItem('orderBillData', JSON.stringify(billData));
										setDiscount({
											amount:  0,
											discountRate: 0,
											discountRateType: "fixed",
											reason: "",
										});
										setCategory([])
										setOpen(false);
									}}
									className={commonStyles.primary_button}
								>
									Clear Discount
								</button>
							</div>

							<div className={styles.input}>
								<FormControl fullWidth>
									<InputLabel id="demo-multiple-name-label">
										Category
									</InputLabel>
									<Select
										labelId="demo-multiple-name-label"
										id="demo-multiple-name"
										style={{ maxWidth: "100%" }}
										multiple
										value={category}
										onChange={handleCategoryChange}
										input={<OutlinedInput label="Name" />}
										MenuProps={MenuProps}
									>
										<MenuItem
											key={"all"}
											value={"all"}
											style={getStyles("all", category, theme)}
										>
											All
										</MenuItem>
										{categories.map((name) => (
											<MenuItem
												key={name}
												value={name}
												style={getStyles(name, category, theme)}
											>
												{name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
							<div className={styles.input}>
								<TextField
									fullWidth
									name="reason"
									value={discount.reason}
									onChange={handleChange}
									id="outlined-basic"
									label="Reason"
									variant="outlined"
								/>
							</div>
							<div className={styles.input}>
								<FormControl>
									<RadioGroup
										row
										aria-labelledby="demo-row-radio-buttons-group-label"
										name="discountRateType"
										onChange={(e) => {
											handleChange(e);
										}}
									>
										<FormControlLabel
											value="fixed"
											control={
												<Radio
													checked={discount.discountRateType === "fixed"}
												/>
											}
											label="Fixed"
										/>
										<FormControlLabel
											value="percentage"
											control={
												<Radio
													checked={discount.discountRateType === "percentage"}
												/>
											}
											label="Percentage"
										/>
									</RadioGroup>
								</FormControl>
								<TextField
									placeholder="0"
									size="small"
									value={discount.discountRate}
									onChange={handleChange}
									name="discountRate"
									sx={{ width: "100px" }}
								/>
							</div>
						</div>
					</div>
					<DialogActions>
						<button
							className={commonStyles.secondary_button}
							onClick={() => {
								setOpen(false);
							}}
						>
							Close
						</button>
						<button
							onClick={handleSave}
							className={commonStyles.primary_button}
						>
							Save
						</button>
					</DialogActions>
				</div>
			</GradientBorder>
		</Dialog>
	);
}
