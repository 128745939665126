import {
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { printer } from "../../../providers/PrintProvider";
import styles from "./PrinterList.module.css";
import Header from "../../../components/Header/Header";
import AddFAB from "./AddFAB";
import { RefreshRounded } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import logger from "../../../utils/logger";
import toast from "../../../utils/toast";
import axios from "axios";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import EditPrinterDialog from "./EditPrinterDialog";
import commonStyles from '../../../styles.module.css'
import usePrinter from "../../../hooks/usePrinter";

const PrinterList = () => {
	const printerContext = useContext(printer);
	const [testPrinter, setTestPrinter] = useState({})
	const [refresh, setRefresh] = useState(false);
	const [isDeleteConfirmDialog, setIsDeleteConfirmDialog] = useState({
		open: false,
		id: "",
	});
	const [isEditDialog, setIsEditDialog] = useState({
		open: false,
		printerDetails: "",
	});
	const reloadPage = () => setRefresh(!refresh);

	const usbPrinters = printerContext.printers.filter(
		(printer) => printer.type === "usb"
	);
	const networkPrinters = printerContext.printers.filter(
		(printer) => printer.type === "network"
	);

	const {printTestPage} = usePrinter()

	const handlePrinterDelete = async (id) => {
		try {
			const res= await axios.delete(
				`/restaurants/${localStorage.getItem("restaurant")}/printers/${id}`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);
			reloadPage();
			return res;
		} catch (error) {
			logger(error);
			toast.error(error.response?.data?.message ?? "Deletion failed", {
				autoClose: 2000,
			});
		}
	};

	useEffect(() => {
		const callFunction1 = printerContext.refreshPrinters({ showToast: false });
		const callFunction2 = printerContext.refreshDepartments({ showToast: false });
		const callFunction= Promise.all([callFunction1, callFunction2])
		toast.promise(callFunction, {
			pending: "Refershing Printers...",
			error: "Failed",
			success: "Printers Refreshed Successfully"
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refresh]);

	return (
		<div className={styles.body}>
			<Header
				title="Printer List"
				trailing={
					<RefreshRounded
						onClick={() => {
							toast.promise(printerContext.refreshPrinters({ showToast: false }), {
								pending: "Refreshing...",
								error: "Failed",
								success: "Printers Refreshed"
							});
						}}
					/>
				}
			/>
			{usbPrinters.length > 0 && (
				<Typography
					variant="h6"
					fontWeight={400}
					padding={"20px"}
					component="div"
					sx={{
						color: "#824500",
					}}
				>
					USB Printers
				</Typography>
			)}
			{usbPrinters.length > 0 && (
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow
								sx={{
									backgroundColor: "#FEF2E2",
									boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

									"& .MuiTableCell-root": {
										fontWeight: "bold",
										color: "#9E5E28",
									},
								}}
							>
								<TableCell>Printer Name</TableCell>
								<TableCell>Device Name</TableCell>
								<TableCell>In Use?</TableCell>
								<TableCell>Should Print KOT?</TableCell>
								<TableCell>Should Print Order?</TableCell>
								<TableCell>Test</TableCell>
								<TableCell>Action</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{usbPrinters.map((printer) => (
								<TableRow key={printer._id}>
									<TableCell>{printer.name}</TableCell>
									<TableCell>{printer.deviceName}</TableCell>
									<TableCell>
										<Switch
											checked={printer.inUse}
											onChange={() => printerContext.toggleInUse(printer._id)}
											inputProps={{ "aria-label": "controlled" }}
											sx={{
												"& .MuiSwitch-switchBase": {
													color: "#9E5E28",
												},
												"& .MuiSwitch-switchBase.Mui-checked": {
													color: "#9E5E28",
												},
												"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
												{
													backgroundColor: "#9E5E28",
												},
											}}
										/>
									</TableCell>
									<TableCell>
										<Switch
											checked={printer.shouldPrintKOT}
											onChange={() =>
												printerContext.toggleShouldPrintKOT(printer._id)
											}
											inputProps={{ "aria-label": "controlled" }}
											sx={{
												"& .MuiSwitch-switchBase": {
													color: "#9E5E28",
												},
												"& .MuiSwitch-switchBase.Mui-checked": {
													color: "#9E5E28",
												},
												"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
												{
													backgroundColor: "#9E5E28",
												},
											}}
										/>
									</TableCell>
									<TableCell>
										<Switch
											checked={printer.shouldPrintOrder}
											onChange={() =>
												printerContext.toggleShouldPrintOrder(printer._id)
											}
											inputProps={{ "aria-label": "controlled" }}
											sx={{
												"& .MuiSwitch-switchBase": {
													color: "#9E5E28",
												},
												"& .MuiSwitch-switchBase.Mui-checked": {
													color: "#9E5E28",
												},
												"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
												{
													backgroundColor: "#9E5E28",
												},
											}}
										/>
									</TableCell>
									<TableCell>
										<button className={commonStyles.primary_button} onClick={() => {
											printTestPage(printer)
										}}>
											Print Test Page
										</button>
									</TableCell>
									{/* <TableCell>
										<Switch
											checked={printer.shouldPrintRecipe}
											onChange={() =>
												printerContext.toggleShouldPrintRecipe(printer._id)
											}
											inputProps={{ "aria-label": "controlled" }}
											sx={{
												"& .MuiSwitch-switchBase": {
													color: "#9E5E28",
												},
												"& .MuiSwitch-switchBase.Mui-checked": {
													color: "#9E5E28",
												},
												"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
													{
														backgroundColor: "#9E5E28",
													},
											}}
										/>
									</TableCell> */}
									<TableCell>
										<EditIcon
											onClick={() =>
												setIsEditDialog({
													open: true,
													printerDetails: printer,
												})
											}
											sx={{ cursor: "pointer" }}
										/>
										<DeleteIcon
											onClick={() =>
												setIsDeleteConfirmDialog({
													open: true,
													id: printer._id,
												})
											}
											sx={{ cursor: "pointer", marginLeft: "5px" }}
										/>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{networkPrinters.length > 0 && (
				<Typography
					variant="h6"
					fontWeight={400}
					padding={"20px"}
					component="div"
					sx={{
						color: "#824500",
					}}
				>
					Network Printers
				</Typography>
			)}
			{networkPrinters.length > 0 && (
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow
								sx={{
									backgroundColor: "#FEF2E2",
									boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
									"& .MuiTableCell-root": {
										fontWeight: "bold",
										color: "#9E5E28",
									},
								}}
							>
								<TableCell>Printer Name</TableCell>
								<TableCell>IP Address</TableCell>
								<TableCell>In Use?</TableCell>
								<TableCell>Should Print KOT?</TableCell>
								<TableCell>Should Print Order?</TableCell>
								{/* <TableCell>Should Print Recipe?</TableCell> */}
								<TableCell>Port</TableCell>
								<TableCell>Test</TableCell>
								<TableCell>Action</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{networkPrinters.map((printer) => (
								<TableRow key={printer._id}>
									<TableCell>{printer.name}</TableCell>
									<TableCell>{printer.ip}</TableCell>
									<TableCell>
										<Switch
											checked={printer.inUse}
											onChange={() => printerContext.toggleInUse(printer._id)}
											inputProps={{ "aria-label": "controlled" }}
											sx={{
												"& .MuiSwitch-switchBase": {
													color: "#9E5E28",
												},
												"& .MuiSwitch-switchBase.Mui-checked": {
													color: "#9E5E28",
												},
												"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
												{
													backgroundColor: "#9E5E28",
												},
											}}
										/>
									</TableCell>
									<TableCell>
										<Switch
											checked={printer.shouldPrintKOT}
											onChange={() =>
												printerContext.toggleShouldPrintKOT(printer._id)
											}
											inputProps={{ "aria-label": "controlled" }}
											sx={{
												"& .MuiSwitch-switchBase": {
													color: "#9E5E28",
												},
												"& .MuiSwitch-switchBase.Mui-checked": {
													color: "#9E5E28",
												},
												"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
												{
													backgroundColor: "#9E5E28",
												},
											}}
										/>
									</TableCell>
									<TableCell>
										<Switch
											checked={printer.shouldPrintOrder}
											onChange={() =>
												printerContext.toggleShouldPrintOrder(printer._id)
											}
											inputProps={{ "aria-label": "controlled" }}
											sx={{
												"& .MuiSwitch-switchBase": {
													color: "#9E5E28",
												},
												"& .MuiSwitch-switchBase.Mui-checked": {
													color: "#9E5E28",
												},
												"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
												{
													backgroundColor: "#9E5E28",
												},
											}}
										/>
									</TableCell>
									{/* <TableCell>
										<Switch
											checked={printer.shouldPrintRecipe}
											onChange={() =>
												printerContext.toggleShouldPrintRecipe(printer._id)
											}
											inputProps={{ "aria-label": "controlled" }}
											sx={{
												"& .MuiSwitch-switchBase": {
													color: "#9E5E28",
												},
												"& .MuiSwitch-switchBase.Mui-checked": {
													color: "#9E5E28",
												},
												"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
													{
														backgroundColor: "#9E5E28",
													},
											}}
										/>
									</TableCell> */}
									<TableCell>{printer.port}</TableCell>
									<TableCell>
										<button className={commonStyles.primary_button} onClick={() => {
											printTestPage(printer)
										}}>
											Print Test Page
										</button>
									</TableCell>

									<TableCell>
										<EditIcon
											onClick={() =>
												setIsEditDialog({
													open: true,
													printerDetails: printer,
												})
											}
											sx={{ cursor: "pointer" }}
										/>
										<DeleteIcon
											onClick={() =>
												setIsDeleteConfirmDialog({
													open: true,
													id: printer._id,
												})
											}
											sx={{ cursor: "pointer", marginLeft: "5px" }}
										/>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			<AddFAB />

			<EditPrinterDialog
				printerDetails={isEditDialog.printerDetails}
				isOpen={isEditDialog.open}
				closeDialog={() => setIsEditDialog({ open: false, printerDetails: {} })}
			/>
			<ConfirmDialog
				open={isDeleteConfirmDialog.open}
				heading="Delete this"
				text={"Are you sure delete this Printer"}
				closeDialog={() => setIsDeleteConfirmDialog({ open: false })}
				callback={(isConfirmed) => {
					if (isConfirmed) {
						
						toast.promise(handlePrinterDelete(isDeleteConfirmDialog.id), {
							pending: "Deleting...",
							error: "Failed",
							success: "Successfully Deleted"
						});
					}
				}}
			/>
		</div>
	);
};

export default PrinterList;
