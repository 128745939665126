import { Dialog, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import styles from "./ConfirmDialog.module.css";
import vibrator from "../../utils/vibrator";
import { ClipLoader } from "react-spinners";
import commonStyles from '../../styles.module.css'

const ConfirmDialog = ({ open, closeDialog, text, heading, callback }) => {
	const [loading, setLoading] = useState(false)
	return (
		<div
			className={styles.dialogBody + " " + `${open ? styles["open"] : null}`}
		>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.dialog_wrapper}
			>
				<div>
					<Typography
						variant="body1"
						component="div"
						color="#9E5E28"
						sx={{
							marginBottom: "12px",
						}}
					>
						<b>{heading}</b>
					</Typography>
					<div className={styles.dialog_input}>
						<Typography variant="body2" component="div" color="#9E5E28">
							{text}
						</Typography>
					</div>
					<div className={styles.dialog_button_row}>
						<GradientBorder
							gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
							width="1px"
							className={styles.cta}
						>
							<button
								className={commonStyles.animate_btn}
								onClick={() => {
									closeDialog();
									vibrator.tap();
								}}
							>
								Cancel
							</button>
						</GradientBorder>
						<GradientBorder
							gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
							width="1px"
							className={styles.cta}
						>
							<button
								className={commonStyles.animate_btn}
								onClick={(e) => {
									callback(true);
									closeDialog();
									vibrator.tap();
								}}
							>
								<span style={{ marginRight: "5px" }}>Yes</span>
								{/* <ClipLoader
									color={"#825325"}
									loading={loading}
									size={10}
								/> */}
							</button>
						</GradientBorder>
					</div>
				</div>
			</GradientBorder>
		</div>
	);
};

export default ConfirmDialog;
