import React, { useContext } from "react";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { cart } from "../../../providers/CartProvider";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import styles from "./BottomAppBar.module.css";
import { ArrowForwardIosRounded, RestaurantRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import vibrator from "../../../utils/vibrator";

const BottomAppBar = ({ table, onFabClick }) => {
	const cartContext = useContext(cart);
	const navigate = useNavigate();

	return (
		<AppBar
			position="sticky"
			sx={{
				top: "auto",
				bottom: 0,
				background:
					"linear-gradient(269.51deg, rgba(139, 70, 0, 0.95) -4.52%, rgba(73, 37, 2, 0.95) 100%)",
				boxShadow: "0px -1px 15px 2px rgba(0, 0, 0, 0.31)",
				borderRadius: "12px 13px 0px 0px",
				color: "white",
			}}
		>
			<Toolbar
				sx={{
					minHeight: "66px",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Typography variant="body1" component="div">
					{cartContext.getItemCount()} Items | ₹ {cartContext.getCartTotal()}
				</Typography>
				<GradientBorder
					gradient="linear-gradient(75.9deg, #84744B -3.84%, #F0E1BA 113.74%)"
					width="4px"
					className={styles.appbar_button}
				>
					<IconButton
						onClick={() => {
							vibrator.tap();
							onFabClick();
						}}
						sx={{
							color: "#9E5E28",
							"&:hover": {
								backgroundColor: "#FEFAF3",
							},
						}}
					>
						<RestaurantRounded fontSize="large" />
					</IconButton>
				</GradientBorder>
				<Typography
					variant="body1"
					component="div"
					sx={{
						display: "flex",
						alignItems: "center",
						cursor: "pointer",
					}}
					onClick={() => {
						vibrator.tap();
						navigate("/order-view", {
							state: {
								table: table,
							},
						});
					}}
				>
					View Order <ArrowForwardIosRounded fontSize="small" />
				</Typography>
			</Toolbar>
		</AppBar>
	);
};

export default BottomAppBar;
