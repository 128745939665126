import toast from "./toast";

class Timer {
	constructor(callback, timerCalc) {
		this.callback = callback;
		this.timerCalc = timerCalc;
		this.timer = null;
		this.tries = 0;
	}

	reset() {
		this.tries = 0;
		clearTimeout(this.timer);
	}

	scheduleTimeout() {
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			this.tries = this.tries + 1;
			this.callback();
		}, this.timerCalc(this.tries + 1));
	}
}

export default class CustomSocket {
	// Constructor which takes socket URL as parameter
	constructor(url, type) {
		this.customSocket = null;
		this.type=type
		this.socketUrl = url;
		this.reconnectTimer = new Timer(() => {
			this.disconnect();
			this.connect();
		}, this.reconnectAfterMs);
	}

	// Reconnect time intervals based on tries
	reconnectAfterMs(tries) {
		return 1000;
	}

	// Create socket and define socket methods
	connect() {
		// Create new socket
		this.customSocket = new WebSocket(this.socketUrl);

		// onopen - called when connection is open and ready to send and receive data.
		this.customSocket.onopen = (event) => {
			if(this.type==="printer"){
				console.log("Printer Application Connected");
			}
			if(this.type==="onlineOrders"){
				console.log("Online Orders Socket Connected");
				toast.success("Online Orders Application Connected")
			}
		};
		
		// onclsoe - called when the connection's closes.
		this.customSocket.onclose = (event) => {
			console.log("Printer Application Disconnected");
			this.reconnectTimer.scheduleTimeout();
		};

		// onerror - called when an error occurs.
		this.customSocket.onerror = (event) => {};

		// onmessage - called when a message is received from the server.
		// this.customSocket.onmessage = (event) => {
		// 	console.log(event.data)
		// 	const data = JSON.parse(event.data);
		// 	if(this.type==='printer'){
		// 		if(!data?.success){
		// 			toast.error(data.message)
		// 		}
		// 	}
		// 	if(this.type==="onlineOrders"){
		// 		console.log("dataPrint", data)
		// 	}
		// };
	}
	
	// close socket connection
	disconnect() {
		// resetting in close method to stop timer on disconnect
		this.customSocket.onclose = function () {};
		// Closing socket
		this.customSocket.close();
	}

	// send message to server
	send(message) {
		console.log(message);
		// logger("Sending message", message);
		if (this.customSocket.readyState === WebSocket.OPEN) {
			this.customSocket.send(message);
		} else {
			console.log("Socket not connected");
			toast.error("Please Open Printer Application");
		}
	}
}
