import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import React, { useContext } from "react";
import { restaurant } from "../../providers/RestaurantProvider";
import Header from "../../components/Header/Header";

const StaffListing = () => {
	const restaurantContext = useContext(restaurant);

	return (
		<div>
			<Header title="Staff Listing" />
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow
							sx={{
								backgroundColor: "#FEF2E2",
								boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

								"& .MuiTableCell-root": {
									fontWeight: "bold",
									color: "#9E5E28",
								},
							}}
						>
							<TableCell>Staff Name</TableCell>
							<TableCell>Type</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{restaurantContext.restaurant.staff.map((staff) => (
							<TableRow key={staff._id}>
								<TableCell>{staff.name}</TableCell>
								<TableCell>{staff.role}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};

export default StaffListing;
