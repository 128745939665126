import React from "react";
import OrderBuildingMenu from "./OrderBuildingMenu/OrderBuildingMenu";
import styles from "./OrderBuilding.module.css";
import KOTSummary from "./KOTSummary/KOTSummary";
import { useLocation, useNavigate } from "react-router-dom";

const OrderBuilding = () => {
	const location = useLocation()
	if(!location?.state?.order){
		window.location.href="/dashboard"
		return null
	}
	return (
		<div className={styles.orderBuildingMain}>
			<div className={styles.body}>
				<div className={styles.menu} id="menu-body">
					<OrderBuildingMenu />
				</div>
				<div className={styles.cart}>
					<KOTSummary />
				</div>
			</div>
		</div>
	);
};

export default OrderBuilding;
