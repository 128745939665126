import React, { useContext, useState } from "react";
import { orders } from "../../providers/OrderProvider";
import { restaurant } from "../../providers/RestaurantProvider";
import Header from "../../components/Header/Header";
import styles from "./LiveView.module.css";
import { Typography } from "@mui/material";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import {
	MoreVertRounded,
	PersonRounded,
	ScheduleRounded,
} from "@mui/icons-material";
import UserListDialog from "./UserListDialog";
import logger from "../../utils/logger";
import { useNavigate } from "react-router-dom";
import { kotContext } from "../../providers/KotProvider";

const LiveView = () => {
	const ordersContext = useContext(orders);
	const restaurantContext = useContext(restaurant);
	const { kots } = useContext(kotContext)

	const [selectedOrder, setSelectedOrder] = useState({});
	const [userListDialogOpen, setUserListDialogOpen] = useState(false);

	const navigate = useNavigate();

	const getDuration = (order) => {
		const utcNow = new Date().getTime();
		const utcOrder = new Date(order.createdAt).getTime();
		const diff = utcNow - utcOrder;
		const minutes = Math.max(0, Math.floor(diff / 1000 / 60));
		const hours = Math.floor(minutes / 60);

		if (hours > 0) {
			return `${hours}h:${minutes % 60}m`;
		} else {
			return `${minutes}m`;
		}
	};

	const getCaptain = (order) => {
		if (order.captain) {
			const staff = restaurantContext.restaurant.staff.find(
				(staff) => staff._id === order.captain
			);
			return staff?.name;
		} else {
			return "";
		}
	};
	const totalAmount= (Object.values(ordersContext.orders || {})).map((order)=>{
		return order.kots.map(kotId=>kots[kotId]).reduce((acc, kot)=>acc+kot.total, 0)
	}).reduce((acc, kotTotal)=>acc+kotTotal, 0)

	return (
		<div className={styles.body}>
			<Header title="Live View" />
			<div className={styles.summary_row}>
				<Typography
					variant="h5"
					component="div"
					color="#824500"
					fontWeight={500}
					align="center"
					sx={{
						marginTop: "16px",
						marginBottom: "16px",
					}}>
					Total Orders: {Object.keys(ordersContext.orders).length} | Total
					Amount:{totalAmount}
				</Typography>
			</div>
			<div className={styles.card_grid}>
				{Object.values(ordersContext.orders).map((order) => {
					const orderTotal =
						order.kots?.reduce((acc, kotId) => {
							const kot = kots[kotId]
							return acc + kot.total
						},0) || 0;
					return (
						<GradientBorder
							gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
							width="1px"
							className={styles.card_wrapper}
						>
							<div>
								<div className={styles.card_header}>
									<Typography
										variant="body"
										component="span"
										color="#824500"
										fontWeight={400}
										sx={{
											marginLeft: "8px",
										}}
									>
										Table {order.table.name}
									</Typography>
									<div className={styles.time}>
										<ScheduleRounded />
										<Typography
											variant="body"
											component="span"
											color="#824500"
											fontWeight={400}
											sx={{
												marginLeft: "8px",
											}}
										>
											{getDuration(order)}
										</Typography>
										<MoreVertRounded
										sx={{cursor:"pointer"}}
											onClick={() => {
												navigate(`/order-details/${order._id}`);
											}}
										/>
									</div>
								</div>
								<hr />
								<div className={styles.card_body}>
									<div className={styles.order_details}>
										<div>
											<div>KOT(s) - {order.kots.length}</div>
											<div>Assigned To - {order?.captain?.name}</div>
											<div>
												Status -{" "}
												<span style={{ textTransform: "capitalize" }}>
													{order.status}
												</span>
											</div>
										</div>
										<div>₹{orderTotal}</div>
									</div>
									<div
										className={styles.person}
										onClick={() => {
											logger("Pressed");
											setSelectedOrder(order);
											setUserListDialogOpen(true);
										}}
									>
										<PersonRounded sx={{cursor:"pointer"}} />
										{order.personCount}
									</div>
								</div>
							</div>
						</GradientBorder>
					);
				})}
			</div>
			<UserListDialog
				isOpen={userListDialogOpen}
				setOpen={setUserListDialogOpen}
				order={selectedOrder}
			/>
		</div>
	);
};

export default LiveView;
