import React, { useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { restaurant } from "../../providers/RestaurantProvider";
import { orders } from "../../providers/OrderProvider";
import { reservation } from "../../providers/ReservationProvider";
import { waitlist } from "../../providers/WaitlistProvider";
import { printer } from "../../providers/PrintProvider";
import logger from "../../utils/logger";
import toast from "../../utils/toast";
import CustomSocket from "../../utils/socket";
import NewKOTPrint from "../PrintScreen/NewKOTPrint";
import OnlineKOTPrint from '../PrintScreen/OnlineKOTPrint'
import { kotContext } from "../../providers/KotProvider";
import { notifications } from "../../providers/NotificationProvider";



let socket;

const localSocket = new CustomSocket("ws://localhost:24632", 'printer');
const onlineOrdersSocket = new CustomSocket("ws://localhost:24634", 'onlineOrders');

const SocketWrapper = ({ children }) => {
	const restaurantContext = useContext(restaurant);
	const [socketReconnct, setSocketReconnect] = useState(false)
	const ordersContext = useContext(orders);
	const reservationContext = useContext(reservation);
	const { updateKot } = useContext(kotContext);
	const waitlistContext = useContext(waitlist);
	const printerContext = useContext(printer);
	const notificationsContext = useContext(notifications);
	const { setKotToPrint, setRecipeData, kotToPrint, onlineKOTToPrint, setOnlineKOTToPrint } =
		printerContext;

	useEffect(() => {
		if (!restaurantContext.restaurant?._id) {
			return;
		}

		socket = io(
			(
				process.env.NODE_ENV === "development"
					? "ws://localhost:3000"
					:
					"wss://shkspr24632.in"
			),
			{
				transports: ['websocket'],
				// transportOptions: {
				// 	polling: {
				// 		extraHeaders: {
				// 			'x-auth-token': localStorage.getItem("token"),
				// 		},
				// 	},
				// },
				autoConnect: true,
				query: { token: localStorage.getItem("token") },
				reconnection: true,
				reconnectionDelay: 1000,
				reconnectionDelayMax: 5000,
				reconnectionAttempts: 99999
			}
		);
		localSocket.connect();
		onlineOrdersSocket.connect();

		socket.on("connect", () => {
			console.log("socket connected");
			const roomName = "cashier" + restaurantContext.restaurant?._id;
			socket.emit('join room', roomName);
			socket.on('room status', (msg) => {
				toast.success("Joined Room Successfully")
			});
			socket.on(`/kot`, (data) => {
				logger("kot data-", data)
				updateKot(data);
				if (data?.isNew) {
					setKotToPrint({ ...data });
				}
			});
		});

		onlineOrdersSocket.customSocket.onmessage = (event) => {
			const data = JSON.parse(event.data);
			console.log("dataPrint", data)
			if (data.type === "kot-print") {
				setOnlineKOTToPrint(data.order)
			}

		};

		socket.on("disconnect", () => {
			console.log("socket disconnected");
			// setTimeout(()=>{
			// 	setSocketReconnect(!socketReconnct)
			// },2000)
		});



		socket.on("connect_error", (err) => {
			console.log("Socket connection error", err);
			// setTimeout(()=>{
			// 	setSocketReconnect(!socketReconnct)
			// },2000)
		});
		socket.on("connect_failed", (err) => {
			console.log("Socket connection failed", err);
		});
		socket.on("connect_timeout", (err) => {
			console.log("Socket connection timedout", err);
		});
		socket.on("reconnect", (err) => {
			console.log("Socket reconnect", err);
		});
		socket.on("reconnect_attempt", (err) => {
			console.log("Socket reconnect", err);
		});
		socket.on("reconnecting", (err) => {
			console.log("Socket reconnecting", err);
		});
		socket.on("reconnect_error", (err) => {
			console.log("Socket reconnect error", err);
		});
		socket.on("reconnect_failed", (err) => {
			console.log("Socket reconnect failed", err);
		});


		socket.on(`/kot`, (data) => {
			updateKot(data);
			if (data?.isNew) {
				setKotToPrint({ ...data });
			}
		});
		socket.on(`/printKOT`, (data) => {
			console.log("kot data - b ")
			toast.success("KOT Reprinting...");
			setKotToPrint({ ...data, rePrint: true });
		});

		socket.on('/order', (data) => {
			ordersContext.updateOrder(data);
		});

		socket.on(`/reservation`, (data) => {
			reservationContext.updateReservationItem(data);
		});
		socket.on("recipe-print", (data) => {
			setRecipeData({ ...data })
		});

		socket.on(`/waitlist`, (data) => {
			waitlistContext.updateWaitlistItem(data);
			notificationsContext.addNotification({
				table: "",
				time: Date.now(),
				title: `Waitlist ${data.name}`,
				body: `Waitlist is ${data.status}`,
			});
		});

		socket.on(`/item`, (data) => {
			restaurantContext.updateItem(data._id, data);
			printerContext.refreshPrinters({ showToast: true });
		});

		socket.on(`/waiter`, (data) => {
			toast.info(`Waiter has been called on Table ${data.table}`);
		});

		socket.on(`/table`, (data) => {
			restaurantContext.updateTable(data);
		});

		socket.on(`/table/remove`, (data) => {
			restaurantContext.removeTable(data._id);
		});

		socket.on(`/categoryUpdated`, async () => {
			await restaurantContext.refreshRestaurant()
			if (window.location.pathname === "/order-building") {
				window.location.reload()
			}
		});

		return () => {
			socket.disconnect();
			localSocket.disconnect();
			onlineOrdersSocket.disconnect();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [restaurantContext.restaurant?._id, socketReconnct]);


	return (
		<>
			{children}
			{printerContext.printers
				.filter((p) => p.inUse && p.shouldPrintKOT)
				.map((printer) => {
					const itemIDs = new Set(printer.items);
					for (let dept of printer.departments) {
						for (let item of dept.items) {
							itemIDs.add(item);
						}
					}
					return (
						<>
							<NewKOTPrint
								setKotToPrint={setKotToPrint}
								key={printer._id}
								kot={kotToPrint}
								itemIDs={itemIDs}
								printer={printer}
							/>
							<OnlineKOTPrint
								kot={onlineKOTToPrint}
								printer={printer}
								setOnlineKOTToPrint={setOnlineKOTToPrint}
							/>
						</>
					);
				})}
		</>
	);
};

export { SocketWrapper as default, localSocket, socket };
