import React, { useState, useContext } from "react";
import { AppBar, Badge, IconButton, Toolbar, Typography } from "@mui/material";
import { Menu as MenuIcon, NotificationsRounded } from "@mui/icons-material";
import Drawer from "../Drawer/Drawer";
import vibrator from "../../utils/vibrator";
import { notifications } from "../../providers/NotificationProvider";
import NotificationsModal from "./NotificationsModal";
import shakespeareLogoWhite from "../../assets/images/shakespeare_logo_white.png";
import kitchen from "../../assets/icons/kitchen.png";
import styles from "./Header.module.css";

const Header = ({
	title = "MMV Cashier",
	showDrawerIcon = true,
	leading = null,
	trailing = null,
}) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
	const notificationsContext = useContext(notifications);
	const [isKitchen, setIsKitchen] = useState(false);

	return (
		<React.Fragment>
			<AppBar
				position="sticky"
				sx={{
					background:
						"linear-gradient(269.51deg, rgba(139, 70, 0, 0.85) -4.52%, rgba(73, 37, 2, 0.85) 100%)",
				}}
			>
				<Toolbar>
					{showDrawerIcon &&
						(leading !== null ? (
							leading
						) : (
							<IconButton
								size="large"
								edge="start"
								color="inherit"
								aria-label="menu"
								onClick={() => {
									vibrator.tap();
									setIsDrawerOpen(true);
								}}
							>
								<MenuIcon />
							</IconButton>
						))}
					<img
						src={shakespeareLogoWhite}
						alt="Shakespeare Logo"
						style={{ height: "40px", marginRight: "8px" }}
					/>
					<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
						{title}
					</Typography>
					<Badge
						badgeContent={notificationsContext.notifications.length}
						color="error"
						max={1000000}
						sx={{
							marginRight: trailing != null ? "8px" : "0px",
							marginLeft: "8px",
						}}
						onClick={() => {
							vibrator.tap();
							setIsKitchen(false);
							setIsNotificationsOpen(true);
						}}
						className={styles.notifBadge}
					>
						<NotificationsRounded />
					</Badge>
					<Badge
						badgeContent={notificationsContext.kitchenNotifications.length}
						color="error"
						sx={{
							marginRight: trailing != null ? "8px" : "0px",
							marginLeft: "8px",
						}}
						onClick={() => {
							vibrator.tap();
							setIsKitchen(true);
							setIsNotificationsOpen(true);
						}}
					>
						<img
							src={kitchen}
							alt="kitchen"
							style={{
								height: "20px",
								padding: "2px",
							}}
						/>
					</Badge>
					{trailing !== null && trailing}
				</Toolbar>
			</AppBar>
			<Drawer open={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
			<NotificationsModal
				open={isNotificationsOpen}
				setOpen={setIsNotificationsOpen}
				isKitchen={isKitchen}
			/>
		</React.Fragment>
	);
};

export default Header;
