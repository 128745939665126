import { useContext } from 'react';
import html2canvas from "html2canvas";
import { renderToStaticMarkup } from "react-dom/server";
import { printer } from '../providers/PrintProvider';
import axios from 'axios';
import OrderPrint from '../components/PrintScreen/OrderPrint';
import { localSocket } from '../components/SocketWrapper/SocketWrapper';
import toast from '../utils/toast';
import { orders } from '../providers/OrderProvider';
import { kotContext } from '../providers/KotProvider';
import { restaurant } from '../providers/RestaurantProvider';
import KOTPrint from '../components/PrintScreen/KOTPrint';
import NewKOTPrint from '../components/PrintScreen/NewKOTPrint';
import { debounce } from 'lodash';
import TestPrint from '../components/PrintScreen/TestPrint';


const usePrinter = () => {
    const { printRef, printers } = useContext(printer)
    const restaurantContext = useContext(restaurant);
    const orderContext = useContext(orders)
    const { kots } = useContext(kotContext)

    const printTestPage = async (printer) => {
        const output = document.createElement("div");
        const staticElement = renderToStaticMarkup(<TestPrint printer={printer} />);
        output.innerHTML = staticElement;
        printRef.current.append(output);
        const canvas = await html2canvas(output, {
            allowTaint: true,
            useCORS: true,
        });
        console.log(canvas);
        const imageData = canvas.toDataURL("image/png");
        if (JSON.parse(localStorage.getItem('printerTest'))) {
            const a = document.createElement("a");
            a.href = imageData;
            a.download = "testprintBill.png";
            a.click();
        }
        localSocket.send(
            JSON.stringify({
                name: printer.deviceName,
                type: printer.type,
                ip: printer.ip,
                port: printer.port ?? 9100,
                raw: imageData.split(",")[1],
            })
        );
    }


    const printKOT = async (kot) => {
        printers.forEach(async printer => {
            if (printer.inUse && printer.shouldPrintKOT) {
                let departmentItems = [].concat(...(printer?.departments ?? []).map(obj => obj.items));
                const itemIDs = new Set(departmentItems);
                const items = kot.items.filter((item) => itemIDs.has(item.item._id));
                if (items.length > 0) {
                    const output = document.createElement("div");
                    const staticElement = renderToStaticMarkup(<NewKOTPrint kot={kot} items={items} printerName={printer.name} />);
                    output.innerHTML = staticElement;
                    printRef.current.append(output)
                    html2canvas(output, {
                        allowTaint: true,
                        useCORS: true,
                    }).then(canvas => {
                        printRef.current.removeChild(output)
                        const imageData = canvas.toDataURL("image/png");
                        if (JSON.parse(localStorage.getItem('printerTest'))) {
                            const a = document.createElement("a");
                            a.href = imageData;
                            a.download = (printer.name ?? "") + "kot bill.png";
                            a.click();
                        }
                        localSocket.send(
                            JSON.stringify({
                                name: printer.deviceName ?? printer.name,
                                type: printer.type,
                                ip: printer.ip,
                                port: printer.port ?? 9100,
                                raw: imageData.split(",")[1],
                            })
                        );
                    }).catch(err => {
                        toast.error("Printing Failed.")
                    })
                }
            }

        })
    }


    const printBill = async (order) => {

        const incrementPrintCount = async (order) => {
            try {
                const res = await axios.put(
                    `/restaurants/${localStorage.getItem("restaurant")}/orders/${order._id}`,
                    {
                        incrementBillPrinted: 1,
                    },
                    {
                        headers: {
                            "x-auth-token": localStorage.getItem("token"),
                        },
                    }
                );
            } catch (err) {
                console.log(err);
                toast.error(err.response?.data?.message ?? "Incrementing print count failed");
            }
        };
        return new Promise(async (resolve, reject) => {
            try {
                const output = document.createElement("div");
                const res = await axios.get(
                    `/restaurants/${localStorage.getItem('restaurant')}/orders/${order._id}`,
                    {
                        headers: {
                            "x-auth-token": localStorage.getItem("token"),
                        },
                    }
                );

                const staticElement = renderToStaticMarkup(<OrderPrint order={res.data} kots={kots} restaurant={restaurantContext.restaurant} />);
                output.innerHTML = staticElement;
                printRef.current.append(output)
                html2canvas(output, {
                    allowTaint: true,
                    useCORS: true,
                }).then(canvas => {
                    printRef.current.removeChild(output)
                    const dataURI = canvas.toDataURL("image/png");
                    const decodedData = atob(dataURI.split(',')[1]);
                    const arrayBuffer = new ArrayBuffer(decodedData.length);
                    const uint8Array = new Uint8Array(arrayBuffer);
                    for (let i = 0; i < decodedData.length; i++) {
                        uint8Array[i] = decodedData.charCodeAt(i);
                    }
                    const blob = new Blob([uint8Array], { type: 'image/png' });
                    const img = new Image();
                    img.src = URL.createObjectURL(blob);
                    img.onload = function () {
                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');
                        const slices = Math.ceil(img.height / 1500);
                        for (let i = 0; i < slices; i++) {
                            const startY = i * 1497;
                            const sliceHeight = Math.min(1500, img.height - startY);
                            canvas.width = img.width;
                            canvas.height = sliceHeight;
                            context.drawImage(img, 0, startY, img.width, sliceHeight, 0, 0, img.width, sliceHeight);
                            const slicedDataURI = canvas.toDataURL('image/png');
                            let printerFound = false;
                            printers.forEach((printer) => {
                                if (printer.inUse && printer.shouldPrintOrder) {
                                    if (JSON.parse(localStorage.getItem('printerTest'))) {
                                        const a = document.createElement("a");
                                        a.href = slicedDataURI;
                                        a.download = "chunk" + (i + 1) + ".png";
                                        a.click();
                                    }
                                    resolve(canvas)
                                    printerFound = true;
                                    localSocket.send(
                                        JSON.stringify({
                                            name: printer.deviceName ?? printer.name,
                                            type: printer.type,
                                            port: printer.port ?? 9100,
                                            ip: printer.ip,
                                            raw: slicedDataURI.split(",")[1],
                                        })
                                    );
                                }
                            })
                            if (!printerFound) {
                                reject("No printer Found")
                            } else {
                                resolve("Print Successfull")
                            }
                            incrementPrintCount(order);

                            if (order.status === "running") {
                                orderContext.completeOrder(
                                    order._id
                                );
                                toast.success("order billed")
                            }
                        }
                    };
                }).catch(err => {
                    console.log(err)
                    reject(err)
                })
            } catch (err) {
                if(!navigator.onLine){
                    toast.error("No Internet connection");
                }
                return reject(err)
            }
        })
    }

    return { printBill, printKOT: debounce(printKOT, 500), printTestPage }
};

export default usePrinter;
