import React, { useEffect, useRef } from "react";
import styles from "./PrintScreen.module.css";
import html2canvas from "html2canvas";
import { localSocket } from "../SocketWrapper/SocketWrapper";


const OnlineKOTPrint = ({ kot, printer, setOnlineKOTToPrint }) => {
	console.log(kot)
	const printerRef = useRef(null);

	const handlePrint = async () => {
		console.log("inside")
		const canvas = await html2canvas(printerRef.current, {
			allowTaint: true,
			useCORS: true,
		});
		const imageData = canvas.toDataURL("image/png");
		if (JSON.parse(localStorage.getItem('printerTest'))) {
			const a = document.createElement("a");
			a.href = imageData;
			a.download = (printer.name ?? "") + "kot bill.png";
			a.click();
		}

		localSocket.send(
			JSON.stringify({
				name: printer.deviceName,
				type: printer.type,
				ip: printer.ip,
				port: printer.port ?? 9100,
				raw: imageData.split(",")[1],
			})
		);
		setOnlineKOTToPrint({});
	};

	useEffect(() => {
		if (!kot?.orderId) {
			return;
		}
		if (kot?.items?.length === 0) {
			return;
		}
		handlePrint();
	}, [kot?.orderId]);

	return (
		<>
			<div className={styles.print}>
				<article
					style={{
						paddingRight: "10px",
						paddingLeft: "10px",
						fontSize: "26px",
					}}
					ref={printerRef}
				>
					<table
						className={styles.info_table}
						style={{
							width: "100%",
						}}
					>
						<center style={{ fontWeight: "800" }}>
							{kot.orderType + " - "+kot.orderId}
						</center>
						<center style={{ fontWeight: "800" }}>
							Order Status- {kot.status}
						</center>
						<center style={{ fontWeight: "800" }}>
							{new Date(Date.now()).toLocaleDateString()}{" "}
							{new Date().toLocaleTimeString("en-US", { hour12: false })}
						</center>
					</table>
					<hr />
					<table className={styles.item_table}>
						<thead>
							<tr>
								<th>
									<span style={{ fontWeight: "bolder" }}>Qty.</span>
								</th>
								<th>
									<span style={{ fontWeight: "bolder" }}>Items</span>
								</th>
							</tr>
						</thead>
						{/* <hr />    */}
						<tbody>
							{(kot.items || []).map((item) => {
								return (
									<tr>
										<td>
											<span style={{ fontWeight: "500" }}>{item.quantity}</span>
										</td>
										<td>
											<span style={{ fontWeight: "500" }}>
												{item.name}
											</span>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					<hr />
				</article>
			</div>
		</>
	);
};

export default OnlineKOTPrint;
