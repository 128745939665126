import { Dialog, Switch, Typography } from "@mui/material";
import React, { useState, useContext } from "react";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import styles from "./ItemCard.module.css";
import PropertyBottomSheet from "./PropertyBottomSheet";
import { cart } from "../../../providers/CartProvider";
import NoteDialog from "./NoteDialog";
import vibrator from "../../../utils/vibrator";
import { restaurant } from "../../../providers/RestaurantProvider";
import logger from "../../../utils/logger";
import toast from "../../../utils/toast";
import vegIcon from "../../../assets/icons/veg.png";
import nonVegIcon from "../../../assets/icons/nonveg.png";

const ItemCard = ({ item }) => {
	const cartContext = useContext(cart);
	const restaurantContext = useContext(restaurant);
	const [isPropertySheetOpen, setIsPropertySheetOpen] = useState(false);
	const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false);

	const [availabilityDialogOpen, setAvailabilityDialogOpen] = useState(false);
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
	let password = "";
	let unavailableReason = "";

	const quantity = cartContext.getQuantity(item._id);


	const handleContextMenu = (e) => {
		e.preventDefault();
		setAvailabilityDialogOpen(true);
	};

	const AvailabilityDialog = () => {
		return (
			<Dialog
				open={availabilityDialogOpen}
				onClose={() => setAvailabilityDialogOpen(false)}
				sx={{
					"& .MuiDialog-paper": {
						backgroundColor: "transparent",
						boxShadow: "none",
					},

					"& .MuiModal-backdrop": {
						backdropFilter: "blur(8px)",
					},
				}}
			>
				<GradientBorder
					gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
					width="1px"
					className={styles.dialog_wrapper}
				>
					<div>
						<Typography
							variant="body1"
							component="div"
							color="#9E5E28"
							sx={{
								marginBottom: "12px",
							}}
						>
							{item.name}
						</Typography>
						<div className={styles.dialog_row}>
							<Typography variant="body2" component="div" color="#9E5E28">
								Availability
							</Typography>
							<Switch
								checked={item.available}
								onChange={() => {
									vibrator.tap();
									if (!item.available) {
										restaurantContext.updateAvailability(
											item._id,
											!item.available
										);
									} else {
										setConfirmDialogOpen(true);
										setAvailabilityDialogOpen(false);
									}
								}}
								sx={{
									"& .MuiSwitch-switchBase": {
										color: "#9E5E28",
									},
									"& .MuiSwitch-switchBase.Mui-checked": {
										color: "#9E5E28",
									},
									"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
										backgroundColor: "#9E5E28",
									},
								}}
							/>
						</div>
					</div>
				</GradientBorder>
			</Dialog>
		);
	};

	const ConfirmDialog = () => {
		return (
			<Dialog
				open={confirmDialogOpen}
				onClose={() => setConfirmDialogOpen(false)}
				sx={{
					"& .MuiDialog-paper": {
						backgroundColor: "transparent",
						boxShadow: "none",
					},

					"& .MuiModal-backdrop": {
						backdropFilter: "blur(8px)",
					},
				}}
			>
				<GradientBorder
					gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
					width="1px"
					className={styles.dialog_wrapper}
				>
					<div>
						<Typography
							variant="body1"
							component="div"
							color="#9E5E28"
							sx={{
								marginBottom: "12px",
							}}
						>
							Confirm Unavailability
						</Typography>
						<div className={styles.dialog_input}>
							<Typography variant="body2" component="div" color="#9E5E28">
								Password
							</Typography>
							<GradientBorder
								gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
								width="1px"
								className={styles.input_wrapper}
							>
								<input
									type="password"
									onChange={(e) => {
										password = e.target.value;
									}}
								/>
							</GradientBorder>
						</div>
						<div className={styles.dialog_input}>
							<Typography variant="body2" component="div" color="#9E5E28">
								Reason
							</Typography>
							<GradientBorder
								gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
								width="1px"
								className={styles.input_wrapper}
							>
								<input
									type="text"
									onChange={(e) => {
										unavailableReason = e.target.value;
									}}
								/>
							</GradientBorder>
						</div>
						<div className={styles.dialog_button_row}>
							<GradientBorder
								gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
								width="1px"
								className={styles.cta}
							>
								<button
									onClick={() => {
										vibrator.tap();
										setConfirmDialogOpen(false);
									}}
								>
									Cancel
								</button>
							</GradientBorder>
							<GradientBorder
								gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
								width="1px"
								className={styles.cta}
							>
								<button
									onClick={() => {
										vibrator.tap();
										if (password === "st13521") {
											if (unavailableReason === "") {
												toast.error("Please enter a reason", {
													autoClose: 2000,
												});
												return;
											}
											restaurantContext.updateAvailability(
												item._id,
												!item.available,
												unavailableReason
											);
										} else {
											toast.error("Incorrect Password", {
												autoClose: 2000,
											});
										}
									}}
								>
									Confirm
								</button>
							</GradientBorder>
						</div>
					</div>
				</GradientBorder>
			</Dialog>
		);
	};

	const handleClick = () => {
		vibrator.tap();
		if (!item.available) {
			toast.error("Item not available : " + item.unavailableReason, {
				autoClose: 2000,
			});
			return;
		}
		if (item.properties.length === 0) {
			cartContext.addItem(item);
		} else {
			setIsPropertySheetOpen(true);
		}
	};

	return (
		<>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={
					styles.card_wrapper + (item.available ? "" : ` ${styles.unavailable}`)
				}
			>
				<div onContextMenu={handleContextMenu}>
					<div className={styles.icon_row} onClick={handleClick}>
						<img src={item.isVeg===false ? nonVegIcon : vegIcon} alt="item" />
						<Typography variant="body2" component="div" color="#9E5E28">
							{item.price}/-
						</Typography>
					</div>
					<Typography
						variant="body1"
						component="div"
						color="#9E5E28"
						onClick={handleClick}
					>
						{item.name}
					</Typography>
					{!item.available ? (
						<Typography
							variant="body2"
							component="div"
							color="#9E5E28"
							onClick={handleClick}
						>
							Unavailable
						</Typography>
					) : item.properties.length === 0 ? (
						<div className={styles.icon_row}>
							<Typography
								variant="body2"
								component="div"
								color="#9E5E28"
								onClick={() => {
									vibrator.tap();
									setIsNoteDialogOpen(true);
								}}
							>
								Note
							</Typography>
							<div className={styles.counter}>
								<div
									className={styles.counter_button}
									onClick={
										quantity === 0
											? undefined
											: () => {
													vibrator.tap();
													cartContext.subtractItem(item);
											  }
									}
								>
									-
								</div>
								<div className={styles.counter_value}>{quantity}</div>
								<div className={styles.counter_button} onClick={handleClick}>
									+
								</div>
							</div>
						</div>
					) : (
						<Typography
							variant="body2"
							component="div"
							color="#9E5E28"
							onClick={handleClick}
						>
							Customise 
						</Typography>
					)}
				</div>
			</GradientBorder>
			<PropertyBottomSheet
				isOpen={isPropertySheetOpen}
				setIsOpen={setIsPropertySheetOpen}
				item={item}
			/>
			<NoteDialog
				isOpen={isNoteDialogOpen}
				setIsOpen={setIsNoteDialogOpen}
				item={item}
			/>
			<AvailabilityDialog />
			<ConfirmDialog />
		</>
	);
};

export default ItemCard;
