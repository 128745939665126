import React, { useContext } from "react";
import { cart } from "../../../providers/CartProvider";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import { Typography } from "@mui/material";
import styles from "./KOTItemCard.module.css";
import vibrator from "../../../utils/vibrator";

const KOTItemCard = ({ item }) => {
	const cartContext = useContext(cart);

	const quantity = cartContext.getQuantity(item.item._id, item.properties);

	let itemPrice = item.item.price;

	if (item.properties) {
		for (let property of item.properties) {
			const prop = item.item.properties.find((p) => p.name === property.name);
			let optPrice = 0;
			if (property?.options?.[0]) {
				prop.options?.forEach((option) => {
					if (property.options.includes(option.name)) {
						optPrice += option?.price ?? 0
					}
				})
			} else {
				const opt = prop?.options.find((opt) => opt.name === property.option);
				optPrice = opt?.price ?? 0
			}
			itemPrice += optPrice;
		}
	}

	return (
		<GradientBorder
			gradient="linear-gradient(270.69deg, rgba(81, 145, 0, 0.77) -6.58%, rgb(0, 237, 66) 153.04%)"
			width="1.25px"
			className={styles.item_card_wrapper}
		>
			<div>
				<div className={styles.item}>
					<div className={styles.item_details}>
						<Typography
							variant="body2"
							component="div"
							color="#824500"
							fontWeight={600}
						>
							{item.item.name}
						</Typography>
						<ul className={styles.property_list}>
							{(item.properties || []).map((prop) => {
								return (
									<Typography
										variant="caption"
										component="div"
										color="#824500"
										fontWeight={400}
									>
										<li key={prop.name}>
											{prop.name} : {prop.option ? prop.option : prop.options?.join(", ") ?? ""}
										</li>
									</Typography>
								);
							})}
						</ul>
					</div>
					<div className={styles.counter}>
						<div
							className={styles.counter_button}
							onClick={
								quantity === 0
									? undefined
									: () => {
										vibrator.tap();
										cartContext.subtractItem(item.item, item.properties);
									}
							}
						>
							-
						</div>
						<div className={styles.counter_value}>{quantity}</div>
						<div
							className={styles.counter_button}
							onClick={() => {
								vibrator.tap();
								cartContext.addItem(item.item, item.properties);
							}}
						>
							+
						</div>
					</div>
					<div className={styles.price}>
						<Typography
							variant="body2"
							component="div"
							color="#824500"
							fontWeight={600}
							align="center"
						>
							{itemPrice}
						</Typography>
					</div>
				</div>
				{item.note && (
					<Typography
						variant="body2"
						component="div"
						color="#824500"
						fontWeight={400}
						textAlign="left"
					>
						<strong>Note: </strong> {item.note}
					</Typography>
				)}
			</div>
		</GradientBorder>
	);
};

export default KOTItemCard;
