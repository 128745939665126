import { Dialog, Typography } from '@mui/material';
import GradientBorder from '../../../components/GradientBorder/GradientBorder';
import styles from './KOTitemCard.module.css'
import commonStyles from '../../../styles.module.css';
import React, { useState } from 'react'
import vibrator from '../../../utils/vibrator';
import toast from '../../../utils/toast';


export default function ConfirmDialog({open, setOpen, handleSave}) {
    const [modificationReason, setModificationReason] = useState("")
    const [password, setPassword] = useState("")
  return (
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                sx={{
                    "& .MuiDialog-paper": {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                    },

                    "& .MuiModal-backdrop": {
                        backdropFilter: "blur(8px)",
                    },
                }}
            >
                <GradientBorder
                    gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
                    width="1px"
                    className={styles.dialog_wrapper}
                >
                    <div>
                        <Typography
                            variant="body1"
                            component="div"
                            color="#9E5E28"
                            sx={{
                                marginBottom: "12px",
                            }}
                        >
                            Confirm Modification
                        </Typography>
                        <div className={styles.dialog_input}>
                            <Typography variant="body2" component="div" color="#9E5E28">
                                Password
                            </Typography>
                            <GradientBorder
                                gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
                                width="1px"
                                className={styles.input_wrapper}
                            >
                                <input
                                    type="password"
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                />
                            </GradientBorder>
                        </div>
                        <div className={styles.dialog_input}>
                            <Typography variant="body2" component="div" color="#9E5E28">
                                Reason
                            </Typography>
                            <GradientBorder
                                gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
                                width="1px"
                                className={styles.input_wrapper}
                            >
                                <input
                                    type="text"
                                    value={modificationReason}
                                    onChange={(e) => {
                                        setModificationReason(e.target.value);
                                    }}
                                />
                            </GradientBorder>
                        </div>
                        <div className={styles.dialog_button_row}>
                            <button
                                className={commonStyles.secondary_button}
                                onClick={() => {
                                    vibrator.tap();
                                    setOpen(false);
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className={commonStyles.primary_button}
                                onClick={() => {
                                    vibrator.tap();
                                    if (password === "st13521") {
                                        if (modificationReason === "") {
                                            toast.error("Please enter a reason", {
                                                autoClose: 2000,
                                            });
                                            return;
                                        }
                                        setOpen(false)
                                        const callFunction=handleSave(modificationReason);
                                        toast.promise(callFunction, {
                                            pending: "Updating...",
                                            error: "Failed",
                                            success: "Successfully Updated"
                                        });
                                    } else {
                                        toast.error("Incorrect Password", {
                                            autoClose: 2000,
                                        });
                                    }
                                }}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </GradientBorder>
            </Dialog>
  )
}
