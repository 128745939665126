import axios from "axios";
import React, { useState } from "react";
import toast from "../../utils/toast";
import logger from "../../utils/logger";
import {
	FormLabel,
	Input,
	InputAdornment,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from "@mui/material";
import styles from "./CustomerListing.module.css";
import { EditRounded, Search } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import Header from "../../components/Header/Header";
import commonStyles from "../../styles.module.css";
import ComingSoon from "./ComingSoon";

const CustomerListing = () => {
	const location = useLocation();

	if (!location.state) {
		location.state = {};
	}
	const [showFulfillDialog, setShowFulfillDialog] = useState(false);
	const [users, setUsers] = useState({});

	const [isSearching, setIsSearching] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");

	const [from, setFrom] = useState(location.state?.from || "");
	const [to, setTo] = useState(location.state?.to || "");
	const [batchSize, setBatchSize] = useState(location.state?.batchSize || 100);
	let local = [];
	const fetchOrders = async () => {
		const todayTimestamp = new Date().setHours(0, 0, 0, 0);
		logger(todayTimestamp);

		try {
			const res = await axios.get(
				`/restaurants/${localStorage.getItem(
					"restaurant"
				)}/orders/timeRange?from=${from}&to=${to}&batchSize=${batchSize}`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			logger(res.data);

			let data = {};

			for (let order of res.data) {
				for (let kot of order.kots || []) {
					if (
						!kot ||
						!kot.userData ||
						(!kot.userData.name && !kot.userData.phone)
					) {
						continue;
					}
					const key = `${kot.userData.name}-${kot.userData.phone}`;

					if (!data[key]) {
						data[key] = {
							name: kot.userData.name,
							phone: kot.userData.phone,
							orders: new Set([order._id]),
							kots: [kot],
						};
					} else {
						data[key].orders.add(order._id);
						data[key].kots.push(kot);
					}
				}
			}
			logger("user data", data);
			setUsers({ ...data });
			// logger("user");

			localStorage.setItem("CustomerID", JSON.stringify(local));
			return res;
		} catch (error) {
			logger(error);
			toast.error(error.response?.data?.message ?? "Error fetching orders", {
				autoClose: 2000,
			});
		}
	};

	logger("users", users);

	return (
		<div className={styles.body}>
			<Header title="Customer Listing" />
			<div className={styles.inputs}>
				<div className={styles.input}>
					<FormLabel>From: </FormLabel>
					<Input
						type="date"
						value={from}
						onChange={(e) => {
							setFrom(e.target.value);
							location.state.from = e.target.value;
						}}
					/>
				</div>
				<div className={styles.input}>
					<FormLabel>To: </FormLabel>
					<Input
						type="date"
						value={to}
						onChange={(e) => {
							setTo(e.target.value);
							location.state.to = e.target.value;
						}}
					/>
				</div>
				<div className={styles.input}>
					<FormLabel>Batch Size: </FormLabel>
					<Input
						type="number"
						value={batchSize}
						onChange={(e) => {
							setBatchSize(e.target.value);
							location.state.batchSize = e.target.value;
						}}
					/>
				</div>
				<button
					className={commonStyles.primary_button}
					onClick={async () => {
						const callFunction = fetchOrders();
						toast.promise(callFunction, {
							pending: "Fetching Customers...",
							error: "Fetching Customers Failed",
							success: "Customers Fetched Successfully"
						});
					}}
				>
					Search
				</button>
			</div>
			{Object.values(users).length > 0 && (
				<>
					<div className={styles.top_bar}>
						<TextField
							placeholder="Search"
							className={styles.search}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<Search />
									</InputAdornment>
								),
							}}
							sx={{
								"& .MuiOutlinedInput-root": {
									"& fieldset": {
										outline: "none",
										border: "none",
									},
									"&:hover fieldset": {
										outline: "none",
										border: "none",
									},
									"&.Mui-focused fieldset": {
										outline: "none",
										border: "none",
									},
								},
							}}
							value={searchQuery}
							onChange={(e) => {
								if (e.target.value === "") {
									setIsSearching(false);
									location.state.isSearching = false;
								} else {
									setIsSearching(true);
									location.state.isSearching = true;
								}
								setSearchQuery(e.target.value);
								location.state.searchQuery = e.target.value;
							}}
						/>
					</div>

					<TableContainer>
						<Table stickyHeader>
							<TableHead>
								<TableRow
									sx={{
										"& .MuiTableCell-root": {
											backgroundColor: "#cccccc",
											fontWeight: "bold",
										},
									}}
								>
									<TableCell>Customer ID</TableCell>
									<TableCell>Customer Name</TableCell>
									<TableCell>Customer Phone</TableCell>
									<TableCell>Visits</TableCell>
									<TableCell>LTV</TableCell>
									<TableCell>Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{(isSearching
									? Object.values(users).filter((user) => {
										return (
											user.name
												?.toLowerCase()
												?.includes(searchQuery?.toLowerCase()) ||
											user.phone
												?.toLowerCase()
												?.includes(searchQuery?.toLowerCase())
										);
									})
									: Object.values(users)
								).map((user) => {
									logger("search", isSearching);
									local.push(`${user.name} ${user.phone}`);
									let nam = user.name;
									return (
										<TableRow>
											<TableCell>
												{nam} {user.phone}
											</TableCell>
											<TableCell>{user.name}</TableCell>
											<TableCell>{user.phone}</TableCell>
											<TableCell>{user.orders?.size || 1}</TableCell>
											<TableCell>
												{user.kots.reduce((acc, kot) => {
													// logger("acc2", acc)
													return acc + kot.total;
												}, 0)}
											</TableCell>
											<TableCell>
												<div className={styles.actions}>
													<EditRounded
														onClick={() => setShowFulfillDialog(true)}
													/>
												</div>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</>
			)}
			<ComingSoon open={showFulfillDialog} setOpen={setShowFulfillDialog} />
		</div>
	);
};

export default CustomerListing;
