import axios from "axios";
import { createContext, useState, useContext } from "react";
import toast from "../utils/toast";
import vibrator from "../utils/vibrator";
import logger from "../utils/logger";
import { notifications } from "./NotificationProvider";

const orders = createContext();

const { Provider } = orders;

const OrderProvider = ({ children }) => {
  const notificationsContext = useContext(notifications);
  const [orders, setStateOrders] = useState(
    JSON.parse(sessionStorage.getItem("orders")) || null
  );

  const setOrders = (orders) => {
    sessionStorage.setItem("orders", JSON.stringify(orders));
    setStateOrders(orders);
  };

  const addOrder = (order) => {
    setStateOrders((prev) => {
      let newOrders = { ...prev };
      newOrders[order.table._id] = order;

      sessionStorage.setItem("orders", JSON.stringify(newOrders));

      return newOrders;
    });
  };

  const refreshOrders = async () => {
    try {
      const res = await axios.get(
        `/restaurants/${localStorage.getItem("restaurant")}/orders/running`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      const tableMapped = {};
      res.data.orders.forEach((order) => {
        if (!order.table) {
          return;
        }
        if (
          !orders[order.table._id] ||
          orders[order.table._id].updatedAt < order.updatedAt
        ) {
          tableMapped[order.table._id] = {
            ...order,
            new: true,
          };
        } else {
          tableMapped[order.table._id] = order;
        }
      });

      setOrders(tableMapped);

      return res;
    } catch (err) {
      logger(err);
      toast.error(err.response?.data?.message ?? "Network connection failed");
    }
  };

  const moveOrder = (from, to) => {
    const newOrders = { ...orders };
    const order = newOrders[from];
    delete newOrders[from];
    newOrders[to] = order;

    setOrders(newOrders);
  };

  const completeOrder = async (orderId) => {
    try {
      const res = await axios.put(
        `/restaurants/${localStorage.getItem("restaurant")}/orders/${orderId}`,
        {
          status: "completed",
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      logger(res.data);

      return true;
    } catch (err) {
      toast.error(err.response?.data?.message ?? "Network conection failed");
      return false;
    }
  };

  const settleOrder = async (orderId) => {
    try {
      const res = await axios.put(
        `/restaurants/${localStorage.getItem("restaurant")}/orders/${orderId}`,
        {
          status: "settled",
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      logger(res.data);

      toast.success("Order Settled!");
      return true;
    } catch (err) {
      logger(err);
      toast.error(err.response?.data?.message ?? "Network Connection Failed");
      return false;
    }
  };

  const updateOrder = async (order) => {
    logger("newOrder", order);
    if (!order.table) {
      return;
    }
    if(order.status==="settled"){
      const orderBillData=  JSON.parse(localStorage.getItem('orderBillData'))
      if(orderBillData[order.table.name]){
        delete orderBillData[order.table.name];
        localStorage.setItem('orderBillData', JSON.stringify(orderBillData))
      }
    }

    setStateOrders((prev) => {
      let newOrders = { ...prev };
      let found = false;
      let shouldVibrate = true;

      for (let [table, ord] of Object.entries(prev)) {
        if (ord._id === order._id) {
          logger("found", table, order.table);
          found = true;
          if (
            order.status === "settled" ||
            order.status === "cancelled" ||
            order.status === "waivedOff"
          ) {
            delete newOrders[table];
            notificationsContext.addNotification({
              table: order.table,
              time: Date.now(),
              title: `Table ${order.table.name}`,
              body: `Order was ${order.status}`,
            });
          } else if (
            order.status === "completed" &&
            ord.status !== "completed"
          ) {
            order.isNew = false;
            notificationsContext.addNotification({
              table: order.table,
              time: Date.now(),
              title: `Table ${order.table.name}`,
              body: `Order was billed`,
            });
            newOrders[table] = order;
          } else if (
            order.status === "completed" &&
            ord.status === "completed"
          ) {
            return prev;
          } else if (table !== order.table._id) {
            delete newOrders[table];
            order.isNew = true;
            newOrders[order.table._id] = order;
            notificationsContext.addNotification({
              table: order.table,
              time: Date.now(),
              title: `Table ${order.table._id}`,
              body: `Order was moved from table ${ord.table.name} to ${order.table.name}`,
            });
          } else if (!ord.isWaiterCalled && order.isWaiterCalled) {
            newOrders[table] = order;
            notificationsContext.addNotification({
              table: order.table,
              time: Date.now(),
              title: `Table ${order.table.name}`,
              body: `Waiter called on table`,
            });
          } else if (ord.isWaiterCalled && !order.isWaiterCalled) {
            newOrders[table] = order;
            notificationsContext.addNotification({
              table: order.table,
              time: Date.now(),
              title: `Table ${order.table.name}`,
              body: `Waiter responded on table`,
            });
          } else if (order.status === "running") {
            order.isNew = true;
            newOrders[table] = order;
            if ((order.kots?.length || 0) === 1) {
              notificationsContext.addNotification({
                table: order.table,
                time: Date.now(),
                title: `Table ${order.table.name}`,
                kot: order.kots[0],
                body: `New order was created from ${
                  order.user ? "Dine In Application" : "Captain / Cashier"
                }`,
              });
            } else {
              order.isNew = true;
              notificationsContext.addNotification({
                table: order.table,
                time: Date.now(),
                kot: order.kots.at(-1),
                title: `Table ${order.table.name}`,
                body: `A KOT was added to the order`,
              });
            }
          }
        }
      }

      if (!found && order.status!=="settled") {
        order.isNew = true;
        newOrders[order.table._id] = order;
        notificationsContext.addNotification({
          table: order.table,
          time: Date.now(),
          kot: order.kots[0],
          title: `Table ${order.table.name}`,
          body: `New order was created from ${
            order.user ? "Dine In Application" : "Captain / Cashier"
          }`,
        });
      }

      if (shouldVibrate) {
        vibrator.longTap();
      }
      if (
        order.status !== "settled" &&
        order.status !== "cancelled" &&
        order.status !== "waivedOff"
      ) {
        if (order.user) {
          newOrders[order.table._id].user = order.user ?? {};
        }
        if (order.captain) {
          newOrders[order.table._id].captain = order.captain ?? {};
        }
      }

      sessionStorage.setItem("orders", JSON.stringify(newOrders));
      return newOrders;
    });
  };

  const clearOrders = () => {
    setOrders(null);
  };

  return (
    <Provider
      value={{
        orders,
        setOrders,
        addOrder,
        refreshOrders,
        moveOrder,
        clearOrders,
        completeOrder,
        updateOrder,
        settleOrder,
      }}
    >
      {children}
    </Provider>
  );
};

export { OrderProvider, orders };
