import {
	Box,
	Checkbox,
	CircularProgress,
	Dialog,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import styles from "./EditDepartmentDialog.module.css";
import vibrator from "../../utils/vibrator";
import logger from "../../utils/logger";
import { restaurant } from "../../providers/RestaurantProvider";
import axios from "axios";
import toast from '../../utils/toast'
import { printer } from "../../providers/PrintProvider";

const EditDepartmentDialog = ({
	open,
	departmentDetails,
	reloadPage,
	closeDialog,
}) => {
	const [department, setDepartment] = useState();
	const printerContext = useContext(printer);
	const [items, setItems] = useState([]);
	const [tab, setTab] = useState("1");
	const [loading, setLoading] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const { items: menuItems } = useContext(restaurant);

	const handleToggle = (value) => () => {
		const currentIndex = items.indexOf(value);
		const newChecked = [...items];
		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}
		setItems(newChecked);
	};
	useEffect(() => {
		if (departmentDetails.items) {
			setItems(departmentDetails.items);
			setDepartment(departmentDetails.name);
		}
	}, [departmentDetails]);

	const handleUpdateDepartment = async () => {
		setLoading(true);
		try {
			const res = await axios.put(
				`/restaurants/${localStorage.getItem("restaurant")}/departments/${
					departmentDetails._id
				}`,
				{
					name: department,
					items,
				},
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);
			logger(res.data);
			reloadPage();
			printerContext.refreshPrinters({ showToast: false });
			
			return res;
		} catch (error) {
			logger(error);
			toast.error(error.response?.data?.message ?? "Error Updating Data", {
				autoClose: 2000,
			});
		} finally{
			setLoading(false);
			closeDialog();
		}
	};
	

	return (
		<Dialog
			open={open}
			onClose={() => closeDialog()}
			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.dialog_wrapper}
			>
				<div className={styles.dialog_box}>
					<Typography
						variant="body1"
						component="div"
						color="#9E5E28"
						sx={{
							marginBottom: "12px",
						}}
					></Typography>
					<div className={styles.dialog_input}>
						<Typography variant="body2" component="div" color="#9E5E28">
							Department Name
						</Typography>
						<GradientBorder
							gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
							width="1px"
							className={styles.input_wrapper}
						>
							<input
								type="text"
								value={department}
								onChange={(e) => {
									setDepartment(e.target.value);
								}}
							/>
						</GradientBorder>
						<Box sx={{ width: "100%", typography: "body1" }}>
							<TabContext textColor="secondary" value={tab}>
								<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
									<TabList
										onChange={(e, value) => {
											setTab(value);
										}}
										aria-label="lab API tabs example"
									>
										<Tab sx={{ width: "50%" }} label="Menu Items" value="1" />
										{/* <Tab sx={{ width: "50%" }} label="Staffs" value="2" /> */}
									</TabList>
								</Box>
								<GradientBorder
									gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
									width="1px"
									className={styles.input_wrapper}
								>
									<input
										type="text"
										value={searchQuery}
										placeholder="Search Items"
										onChange={(e) => {
											setSearchQuery(e.target.value);
										}}
									/>
								</GradientBorder>
								<TabPanel
									sx={{
										padding: "5px 0px",
										maxHeight: "400px",
										overflowY: "scroll",
									}}
									value="1"
								>
									<List
										sx={{
											width: "100%",
											maxWidth: 360,
											bgcolor: "background.paper",
										}}
									>
										{Object.values(menuItems)
											.filter((item) =>
												item.name.match(new RegExp(searchQuery, "i")) && !item.removed
											)
											.map((item) => {
												const labelId = `checkbox-list-label-${item._id}`;

												return (
													<ListItem key={item._id} disablePadding>
														<ListItemButton
															role={undefined}
															onClick={handleToggle(item._id)}
															dense
														>
															<ListItemIcon>
																<Checkbox
																	sx={{ minWidth: "40px" }}
																	edge="start"
																	checked={items.indexOf(item._id) !== -1}
																	tabIndex={-1}
																	disableRipple
																	inputProps={{ "aria-labelledby": labelId }}
																/>
															</ListItemIcon>
															<ListItemText id={labelId} primary={item.name} />
														</ListItemButton>
													</ListItem>
												);
											})}
									</List>
								</TabPanel>
								{/* <TabPanel value="2">Item Two</TabPanel> */}
							</TabContext>
						</Box>
					</div>
					<div className={styles.dialog_button_row}>
						<GradientBorder
							gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
							width="1px"
							className={styles.cta}
						>
							<button
								onClick={() => {
									vibrator.tap();
									logger("Cancel");
									closeDialog();
								}}
							>
								Cancel
							</button>
						</GradientBorder>
						<GradientBorder
							gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
							width="1px"
							className={styles.cta}
						>
							<button
								disabled={department?.trim() === "" || loading}
								onClick={() => {
									vibrator.tap();
									const callFunction=handleUpdateDepartment();
									toast.promise(callFunction, {
										pending: "Updating...",
										error: "Failed",
										success: "Department Updated Successfully"
									});
								}}
							>
								Update
								{loading && (
									<CircularProgress
										sx={{ marginLeft: "5px", color: "black" }}
										size={10}
									/>
								)}
							</button>
						</GradientBorder>
					</div>
				</div>
			</GradientBorder>
		</Dialog>
	);
};

export default EditDepartmentDialog;
