import React from "react";
import styles from "./PrintScreen.module.css";


const TestPrint = ({ printer }) => {

	return (
		<>
			<div className={""}>
				<article
					style={{
						paddingRight: "10px",
						paddingLeft: "10px",
						fontSize: "26px",
					}}
				>
					<table
						className={styles.info_table}
						style={{
							width: "100%",
						}}
					>
						<tr>
							<th style={{ fontWeight: "800" }}>Testing</th>
						</tr>	
						<tr style={{ fontWeight: "800" }}>
							<th style={{ fontWeight: "bolder" }}>It is just a testing Print</th>
						</tr>
					</table>
					<hr />
					{/* <p>Captain: {captain || "--"}</p> */}
					<table className={styles.info_table}>
						<tr>
                            <td>Printer Name</td>
                            <td></td>
                            <td>{printer?.name}</td>
                        </tr>
						<tr>
                            <td>Device Name</td>
                            <td></td>
                            <td>{printer?.deviceName}</td>
                        </tr>
						<tr>
                            <td>Printer Type</td>
                            <td></td>
                            <td>{printer?.type}</td>
                        </tr>
						<tr>
                            <td>Printer IP</td>
                            <td></td>
                            <td>{printer?.ip ?? "--"}</td>
                        </tr>
						<tr>
                            <td>Printer IP</td>
                            <td></td>
                            <td>{printer?.port ?? "--"}</td>
                        </tr>
					</table>
					<hr />
				</article>
			</div>
		</>
	);
};

export default TestPrint;
