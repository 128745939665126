import React, { useContext, useEffect, useState } from 'react'
import styles from './OrderDetails.module.css'
import GradientBorder from '../../components/GradientBorder/GradientBorder';
import { Dialog, Typography } from '@mui/material';
import KOTitemCard from './KOTitemCard';
import { Alarm, PrintRounded } from '@mui/icons-material';
import axios from 'axios';
import isObjectsEqual from '../../utils/isObjectEquals';
import commonStyles from '../../styles.module.css';
import { useNavigate } from 'react-router-dom';
import toast from '../../utils/toast';
import vibrator from '../../utils/vibrator';
import { printer } from '../../providers/PrintProvider';
import ConfirmDialog from './ConfirmDialog';

export default function KOTCard({ kot, index }) {
    const now = Date.now();
    const navigate = useNavigate()
    const [items, setItems] = useState([])
    const kotTime = Date.parse(kot.createdAt);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const printerContext = useContext(printer);
    const { setKotToPrint } = printerContext;


    const diff = now - kotTime;

    const minutes = Math.max(0, Math.floor(diff / 1000 / 60));

    const isUser = kot.userId !== undefined;

    const overdue = minutes >= 25;

    useEffect(() => {
        const kotItems = JSON.stringify([...(kot?.items ?? [])])
        setItems([...(JSON.parse(kotItems))])
    }, [kot]);

    const handleUpdate = async (deletionReason) => {
        try {
            const newItems = items.map((i) => {
                return {
                    ...i,
                    item: i.item._id,
                };
            });
            const res = await axios.put(
                `/restaurants/${localStorage.getItem('restaurant')}/kots/${kot._id}`,
                {
                    items: newItems,
                    deletion: {
                        reason: deletionReason,
                    },
                },
                {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                    },
                }
            );
            console.log(res.data)
            navigate("/dashboard")
            return res;
            // setIsOpen(false)
        } catch (err) {
            console.log(err)
            toast.error(err?.response?.data?.message ?? "kot Updation Failed")
        }
    }

    const handleDelete = async (index) => {
        const newItems = items.filter((i, ind) => ind != index)
        setItems([...newItems])
    };
    const handleInc = async (index) => {
        const newItems = [...items]
        newItems[index].quantity++;
        setItems([...newItems])
    };
    const handleDec = async (index) => {
        const newItems = [...items]
        newItems[index].quantity = newItems[index].quantity <= 1 ? 1 : (newItems[index].quantity - 1);
        setItems([...newItems])
    };
    console.log(kot);
    return (
        <>
            <GradientBorder
                className={styles.kot_wrapper}
                width="2px"
                gradient={
                    overdue
                        ? "linear-gradient(270.69deg, rgba(255, 168, 168, 0.77) -6.58%, #ED0000 153.04%)"
                        : isUser
                            ? "linear-gradient(#06DCCF, #389E7A)"
                            : "linear-gradient(#7E06DC, #63B4FF)"
                }
            >
                <div>
                    <div className={styles.kot_heading}>

                        <Typography
                            variant="h5"
                            component="span"
                            color="#824500"
                            fontWeight={400}
                            sx={{
                                marginLeft: "8px",
                            }}
                        >
                            KOT #{index + 1}
                        </Typography>
                        <Typography
                            variant="body"
                            component="span"
                            color="#824500"
                            fontWeight={400}
                            sx={{
                                marginLeft: "8px",
                            }}
                        >
                            [{minutes} mins]
                        </Typography>
                        {overdue && (
                            <Alarm
                                htmlColor="#824500"
                                sx={{
                                    marginLeft: "12px",
                                }}
                            />
                        )}
                        <PrintRounded sx={{ marginLeft: "20px", cursor: "pointer" }} className={commonStyles.animate_btn} htmlColor="#824500" onClick={() => {
                            setKotToPrint({ ...kot, rePrint: true });
                            toast.info("Added to print...")
                        }} />
                    </div>
                    <div className={styles.kot_item_list}>
                        {(items ?? []).map((item, index) => {
                            return <KOTitemCard item={item} kot={kot} handleDec={() => handleDec(index)} handleInc={() => handleInc(index)} handleDelete={() => handleDelete(index)} />;
                        })}
                    </div>
                    <div className={styles.updateBtn}>{
                        !isObjectsEqual(kot.items, items) ?
                            <button className={commonStyles.primary_button} onClick={() => setConfirmDialogOpen(true)}>Update KOT</button> : null
                    }
                    </div>
                </div>
            </GradientBorder>
            <ConfirmDialog
                open={confirmDialogOpen}
                setOpen={setConfirmDialogOpen}
                handleSave={handleUpdate}
            />
        </>
    )
}
