import React, { useEffect, useRef } from "react";
import styles from "./PrintScreen.module.css";
import html2canvas from "html2canvas";
import { localSocket } from "../SocketWrapper/SocketWrapper";


const NewKOTPrint = ({ kot, itemIDs, printer, setKotToPrint }) => {
	console.log(kot)
	const kotCopy = JSON.parse(JSON.stringify(kot));

	if (kot._id) {
		kotCopy.items = kotCopy.items.filter((item) => itemIDs.has(item.item._id));
	}

	const printerRef = useRef(null);

	const handlePrint = async () => {
		console.log("inside")
		const canvas = await html2canvas(printerRef.current, {
			allowTaint: true,
			useCORS: true,
		});
		const imageData = canvas.toDataURL("image/png");
		console.log(imageData)
		if (JSON.parse(localStorage.getItem('printerTest'))) {
			const a = document.createElement("a");
			a.href = imageData;
			a.download = (printer.name ?? "") + "kot bill.png";
			a.click();
		}

		localSocket.send(
			JSON.stringify({
				name: printer.deviceName,
				type: printer.type,
				ip: printer.ip,
				port: printer.port ?? 9100,
				raw: imageData.split(",")[1],
			})
		);
		setKotToPrint({});
	};

	useEffect(() => {
		if (!kot._id) {
			return;
		}
		if (kotCopy.items.length === 0) {
			return;
		}
		handlePrint();
	}, [kot._id]);
	console.log("jjjjjjjjjjjjjjjjjj")
	return (
		<>
			<div className={styles.print}>
				<article
					style={{
						paddingRight: "10px",
						paddingLeft: "10px",
						fontSize: "26px",
					}}
					ref={printerRef}
				>
					<table
						className={styles.info_table}
						style={{
							width: "100%",
						}}
					>
						<center style={{ fontWeight: "800" }}>
							KOT NO - {kot.kotNumber} {kot?.rePrint && " - (Reprint)"}
						</center>
						{kot?.kotModifiedBy ?
							<tr>
								<th style={{ fontWeight: "800" }}>(Updated KOT)</th>
							</tr>
							: ""}
						<center style={{ fontWeight: "800" }}>
							{new Date(Date.now()).toLocaleDateString()}{" "}
							{new Date().toLocaleTimeString("en-US", { hour12: false })}
						</center>
						<tr style={{ fontWeight: "800" }}>
							<th style={{ fontWeight: "bolder" }}>{kotCopy?.order?.table?.type?.toUpperCase() ?? "DINEIN"}</th>
						</tr>
						<tr style={{ fontWeight: "800" }}>
							<th style={{ fontWeight: "bolder" }}>Printer: {printer.name ?? "USB"}</th>
						</tr>
						<tr>
							<th style={{ fontWeight: "800" }}>
								Table No: {kot?.order?.table?.name}
							</th>
						</tr>
						<tr>
							<th style={{ fontWeight: "800" }}>
								Customer Name: {kot.userData?.name || "--"}
							</th>
						</tr>
					</table>
					<hr />
					<table>
						<tr>
							<td >Ordered By: {kot.orderedBy?.name || "User"}</td>
						</tr>
						{
							kot?.movedFromTable ?
								<tr>
									<td >Moved From Table: {kot.movedFromTable || "--"}</td>
								</tr> : null
						}
					</table>
					<table className={styles.item_table}>
						<thead>
							<tr>
								<th>
									<span style={{ fontWeight: "bolder" }}>Qty.</span>
								</th>
								<th>
									<span style={{ fontWeight: "bolder" }}>Items</span>
								</th>
								<th>
									<span style={{ fontWeight: "bolder" }}>Special Note</span>
								</th>
							</tr>
						</thead>
						{/* <hr />    */}
						<tbody>
							{(kotCopy.items || []).map((item) => {
								return (
									<tr>
										<td>
											<span style={{ fontWeight: "500" }}>{item.quantity}</span>
										</td>
										<td>
											<span style={{ fontWeight: "500" }}>
												{item.item.name}
												{(item.properties || []).map((property) => {
													return (
														<div
															style={{
																marginLeft: "10px",
																fontStyle: "italic",
																fontWeight: "500",
															}}
														>
															{property.name} : {property.option ? property.option : property.options?.join(", ") ?? ""}
														</div>
													);
												})}
											</span>
										</td>
										<td>
											<pre style={{ fontWeight: "500" }}>
												{item.note ? item.note : "--"}
											</pre>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					<hr />
				</article>
			</div>
		</>
	);
};

export default NewKOTPrint;
