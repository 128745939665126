import React, { useContext } from "react";
import {
	Drawer,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import styles from "./OrderSummaryDetails.module.css";
import Header from "../../components/Header/Header";
import { ChevronLeftRounded } from "@mui/icons-material";
import { restaurant } from "../../providers/RestaurantProvider";

const OrderSummaryDetails = ({ order = {}, open, setOpen }) => {
	const subTotal =
		(order.kots?.reduce((acc, kot) => {
			return acc + kot.total;
		}, 0) || 0);
		console.log(subTotal)
	const restaurantContext = useContext(restaurant)
	const totalTaxValue = restaurantContext.restaurant?.taxes?.reduce((acc, tax) => acc + tax.value, 0);
	const grandTotal = (((subTotal - order?.discount?.amount) * (1 + totalTaxValue / 100)) + (order?.containerCharge ?? 0) + (order?.deliveryCharge ?? 0))

	return (
		<Drawer
			anchor="right"
			open={open}
			onClose={() => setOpen(false)}
			sx={{
				"& .MuiDrawer-paper": {
					width: "85%",
					maxWidth: "100%",
				},
			}}
		>
			<div className={styles.body}>
				<Header
					title="Order Details"
					leading={
						<ChevronLeftRounded
							onClick={() => setOpen(false)}
							fontSize="large"
							htmlColor="white"
						/>
					}
				/>
				<TableContainer>
					<Table stickyHeader>
						<TableHead>
							<TableRow
								sx={{
									backgroundColor: "#FEF2E2",
									boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

									"& .MuiTableCell-root": {
										fontWeight: "bold",
										color: "#9E5E28",
										backgroundColor: "#FEF2E2",
									},
								}}
								>
								<TableCell>Order NO</TableCell>
								<TableCell>Date</TableCell>
								<TableCell>Order Type</TableCell>
								<TableCell>Order Status</TableCell>
								<TableCell>Customer Name</TableCell>
								<TableCell>Customer Phone</TableCell>
								<TableCell>Bill Settled By</TableCell>
								<TableCell>Bill Modified By</TableCell>
								<TableCell>Payment Modified By</TableCell>
								<TableCell>No. of Persons</TableCell>
								<TableCell>Payment Type</TableCell>
								<TableCell>My Amount</TableCell>
								<TableCell>Tax</TableCell>
								<TableCell>Discount</TableCell>
								<TableCell>Grand Total</TableCell>
								<TableCell>Tip</TableCell>
								<TableCell>Customer Paid</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow className={styles[order.status]}>
								<TableCell>{order.orderNumber}</TableCell>
								<TableCell>
									{new Date(order.createdAt).toLocaleString()}
								</TableCell>
								<TableCell>{(order.table?.type ?? "")?.toUpperCase() ?? "DINEIN"} ({order.table?.name ?? "Temporary Table"})</TableCell>
								<TableCell>{order.status + (order.status==="waivedOff" ? " ("+order.waivedOffReason+")" : "")}</TableCell>
								<TableCell>{order.user?.name}</TableCell>
								<TableCell>{order.user?.phone}</TableCell>
								<TableCell>{order.billSettledBy?.name ?? "--"}</TableCell>
								<TableCell>{order.billModifiedBy?.name ?? "--"}</TableCell>
								<TableCell>{order.paymentModifiedBy?.name ?? "--"}</TableCell>
								<TableCell>{order.personCount}</TableCell>
								<TableCell>{(order.payment?.method +" - "+ (order?.payment?.otherMethod ?? "") )|| "Due"}</TableCell>
								<TableCell>{(subTotal ?? 0).toFixed(2)}</TableCell>
								<TableCell>{((subTotal * (totalTaxValue / 100)) ?? 0).toFixed(2)}</TableCell>
								<TableCell>{order?.discount?.amount ?? 0}</TableCell>
								<TableCell>{(grandTotal ?? 0).toFixed(2)}</TableCell>
								<TableCell>{(order?.tip ?? 0).toFixed(2)}</TableCell>
								<TableCell>{(order?.payment?.customerPaid ?? 0).toFixed(2)}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
				<Typography
					variant="h6"
					sx={{
						marginTop: "20px",
						marginBottom: "20px",
						marginLeft: "10px",
					}}
					color="#814500"
				>
					Order Items
				</Typography>
				<TableContainer>
					<Table stickyHeader>
						<TableHead>
							<TableRow
								sx={{
									backgroundColor: "#FEF2E2",
									boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

									"& .MuiTableCell-root": {
										fontWeight: "bold",
										color: "#9E5E28",
										backgroundColor: "#FEF2E2",
									},
								}}
							>
								<TableCell>Item Name</TableCell>
								<TableCell>Special Note</TableCell>
								<TableCell>Quantity</TableCell>
								<TableCell>Price</TableCell>
								<TableCell>Total</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{order.kots?.map((kot) => {
								return kot.items?.map((item) => (
									<TableRow>
										<TableCell>
											<div>
												<span>{item.item.name}</span>
												{item.properties?.map((property) => (
													<div
														style={{
															marginLeft: "10px",
														}}
													>
														{property.name} : {property.option ? property.option : property.options?.join(", ") ?? ""}
													</div>
												))}
											</div>
										</TableCell>
										<TableCell>{item.note || "--"}</TableCell>
										<TableCell>{item.quantity}</TableCell>
										<TableCell>{item.item.price.toFixed(2)}</TableCell>
										<TableCell>
											{(item.item.price * item.quantity).toFixed(2)}
										</TableCell>
									</TableRow>
								));
							})}
							<TableRow>
								<TableCell colSpan={4} align="left">
									Sub Total
								</TableCell>
								<TableCell>{subTotal.toFixed(2)}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell colSpan={4} align="left">
									Discount
								</TableCell>
								<TableCell>-{order?.discount?.amount?.toFixed(2)}</TableCell>
							</TableRow>
							
							{
								restaurantContext?.restaurant?.taxes?.map((tax) => {
									return <TableRow>
										<TableCell colSpan={3} align="left">
											{tax.name?.toUpperCase()}
										</TableCell>
										<TableCell>{tax?.value}</TableCell>
										<TableCell>{(subTotal * (tax.value/100)).toFixed(2)}</TableCell>
									</TableRow>

								})
							}
							<TableRow>
								<TableCell colSpan={4} align="left">
									Container Charge
								</TableCell>
								<TableCell>{order?.containerCharge?.toFixed(2)}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell colSpan={4} align="left">
									Delivery Charge
								</TableCell>
								<TableCell>{order?.deliveryCharge?.toFixed(2)}</TableCell>
							</TableRow>
							<TableRow
								sx={{
									backgroundColor: "#FEF2E2",
									boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

									"& .MuiTableCell-root": {
										fontWeight: "bold",
										color: "#9E5E28",
										backgroundColor: "#FEF2E2",
									},
								}}
							>
								<TableCell colSpan={4} align="left">
									Grand Total
								</TableCell>
								<TableCell>{grandTotal.toFixed(2)}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</Drawer>
	);
};

export default OrderSummaryDetails;
